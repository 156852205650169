export const styleSheet = () => ({
  pageRoot: {
    marginTop: "100px",
  },
  docsTitle: {
    display: "flex !important",
    alignItems: "center  !important",
    fontSize: "20px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext  !important",

    color: "rgba(0, 0, 0, 0.87)",
    "& svg": {
      marginRight: "10px",
    },
  },
  newfolderButton: {
    display: "flex !important",
    alignItems: "center !important",
    fontWeight: "400 !important",
    fontSize: "13px !important",
    textTransform: "uppercase !important",
    backgroundColor: "#0B0909  !important",
    color: "white",
  },
  docsAppBar: {
    justifyContent: "flex-end",
    alignItems: "center",
    height: "53px",
    // marginBottom: "30px",
    backgroundColor: "#fff !important",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  },
  docsAppBarArea: {
    display: "flex",
    height: "53px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  docsAppBarRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  customAppbarButton: {
    marginLeft: "15px !important",
  },
  customAppbarButtonText: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: "-5px",
  },
});
