import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  InputLabel,
  Autocomplete,
  TextField,
  Chip,
  Typography,
} from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { getData } from "../../../../utils/getData";
function UsersSelection(props) {
  const {
    classes,
    value,
    errors,
    setOpenUpdate,
    setUpdateDetail,
    preOptions,
    disabled,
    handleSelect,
    label,
    type,
    labelType,
    multiple,
  } = props;
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTimeout = useRef(null);

  const handleSearch = async (value) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/leads/people?search=${value}&limit=50&offset=0&sort=name`;
      const result = await getData(url);
      if (result?.people?.length) {
        let newOptions = [];
        result?.people?.map((item, index) => {
          newOptions.push({
            first_name: item?.name?.split(" ")?.[0],
            last_name: item?.name?.split(" ")?.[1],
            legal_entity_name: item?.legal_entity_name,
            lead_id: item.id?.toString(),
            email: item?.emails?.length
              ? item?.emails?.find((item) => item.isPrimary)?.value
              : undefined,
            contact_number: item?.phones?.length
              ? item?.phones[0]?.value
              : undefined,
            street: item?.street,
            city: item.city,
            state: item.state,
            zip_code: item.zip_code,
          });
        });
        setOptions(newOptions);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Error fetching data", { theme: "colored" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (searchTerm) {
        handleSearch(searchTerm);
      }
    }, 1000);

    return () => clearTimeout(debounceTimeout.current);
  }, [searchTerm]);
  return (
    <Box>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <Autocomplete
        multiple
        limitTags={2}
        loading={loading}
        id={`multiple-${type}-tags`}
        options={[...preOptions, ...options]}
        disabled={disabled}
        getOptionLabel={(option) =>
          option?.first_name + " " + option?.last_name
        }
        onInputChange={(event, value, reason) => {
          if (searchTerm !== value && reason === "input") {
            setSearchTerm(value);
          }
        }}
        value={value}
        onChange={(event, value) => {
          if (multiple) {
            handleSelect(value);
          } else if (!multiple && value?.length < 2) {
            handleSelect(value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`Select ${type}`}
            size="small"
            fullWidth
            error={errors?.[type]}
            helperText={errors?.[type]}
          />
        )}
        noOptionsText={
          searchTerm ? (
            <Typography
              sx={{ color: "black", cursor: "pointer" }}
              onClick={() => {
                handleSelect([
                  ...value,
                  {
                    first_name: searchTerm?.split(" ")?.[0] || "",
                    last_name: searchTerm?.split(" ")?.[1] || "",
                  },
                ]);
                setOptions((preOptions) => [
                  ...preOptions,
                  {
                    first_name: searchTerm?.split(" ")?.[0] || "",
                    last_name: searchTerm?.split(" ")?.[1] || "",
                  },
                ]);
              }}
            >
              <span style={{ color: "#2193f6" }}>Add {labelType}:</span>{" "}
              {searchTerm}
            </Typography>
          ) : (
            "No options"
          )
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.first_name}
              label={option?.first_name + " " + option?.last_name}
              size="small" // ✅
              onClick={() => {
                setOpenUpdate(true);
                setUpdateDetail({
                  type: type,
                  data: value,
                });
              }}
              deleteIcon={
                <ClearRoundedIcon fontSize="small" sx={{ color: "black" }} />
              }
              sx={{
                borderRadius: "3px",
              }}
            />
          ))
        }
      />
    </Box>
  );
}

export default UsersSelection;
