import axios from "../../axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
const initialState = {
  loading: false,
  formsList: [],
  errMsg: null,
};

export const getAllForms = createAsyncThunk(
  "docs/getAllForms",
  async (data, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/docs/forms`;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        method: "get",
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const formsList = createSlice({
  name: "formsList",
  initialState,
  extraReducers: {
    [getAllForms.pending]: (state) => {
      state.loading = true;
    },
    [getAllForms.fulfilled]: (state, action) => {
      state.loading = false;
      state.formsList = action.payload?.data;
    },
    [getAllForms.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});

export default formsList.reducer;
