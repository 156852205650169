import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  TextField,
  InputLabel,
  Grid,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addAgentAccountPasswords,
  updateAccountPassword,
} from "../../../../redux/agents/agentRosterDetail/password";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
function AddAccountModal(props) {
  let { open, setOpen, classes, accountData } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  const [errMsg, setErrMsg] = useState({});
  const dispatch = useDispatch();
  const [accountDetail, setAccountDetail] = useState({
    userId: id,
    website_title: "",
    website_link: "",
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(true);

  const addPassword = useSelector(
    (state) => state.agentRoster.AgentPasswords.addPassword
  );
  const updatePassword = useSelector(
    (state) => state.agentRoster.AgentPasswords.updatePassword
  );

  useEffect(() => {
    if (open === "update" && accountData.id) {
      setAccountDetail({
        ...accountDetail,
        ...accountData,
      });
    }
  }, [open]);

  useEffect(() => {
    if (updatePassword.success && !updatePassword.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully updated the account password",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!updatePassword.isLoading && updatePassword.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(updatePassword.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updatePassword.isLoading]);

  useEffect(() => {
    if (addPassword.success && !addPassword.isLoading && open) {
      setErrorAlert({
        errorMsg: "You have successfully added the account password",
        errorType: "success",
        isOpen: true,
      });
      handleClose();
    } else if (!addPassword.isLoading && addPassword.errMsg && open) {
      setErrorAlert({
        errorMsg: JSON.stringify(addPassword.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addPassword.isLoading]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClose = () => {
    setAccountDetail({
      userId: id,
      website_title: "",
      website_link: "",
      username: "",
      password: "",
    });
    setOpen(false);
  };
  const handleUpdateDetail = (field, value) => {
    setAccountDetail({ ...accountDetail, [field]: value });
    setErrMsg({});
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!accountDetail.website_title) {
      isFormValid = false;
      errorMsg["website_title"] = "Please enter the website title";
      setErrorAlert({
        errorMsg: "Please enter the website title",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!accountDetail.website_link) {
      isFormValid = false;
      errorMsg["website_link"] = "Please enter the website link";
      setErrorAlert({
        errorMsg: "Please enter the website link",
        errorType: "warning",
        isOpen: true,
      });
    } else if (
      accountDetail.website_link &&
      !new RegExp(/^(ftp|http|https):\/\/[^ "]+$/).test(
        accountDetail.website_link
      )
    ) {
      isFormValid = false;
      errorMsg["website_link"] = "Please enter the valid website link";
      setErrorAlert({
        errorMsg: "Please enter the valid website link",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!accountDetail.username && !accountDetail.password) {
      isFormValid = false;
      errorMsg["username"] = "Please enter the user name or password";
      errorMsg["password"] = "Please enter the password or user name";
      setErrorAlert({
        errorMsg: "Please enter the user name or password",
        errorType: "warning",
        isOpen: true,
      });
    }
    setErrMsg(errorMsg);
    return isFormValid;
  };
  const handleSaveAccount = () => {
    if (handleValidate()) {
      dispatch(addAgentAccountPasswords(accountDetail));
    }
  };

  const handleUpdateAccount = () => {
    if (handleValidate()) {
      dispatch(updateAccountPassword(accountDetail));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle
          className={classes.appointmentModalHeading}
          sx={{ mt: "10px", mb: "15px" }}
          id="alert-dialog-title"
        >
          {open === "update" ? "Update Account" : "Add Account"}
        </DialogTitle>
        <DialogContent sx={{ width: "550px", overflow: "visible" }}>
          <Grid
            container
            direction="row"
            spacing={3}
            sx={{ overflow: "visible" }}
          >
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Website Title
              </InputLabel>
              <TextField
                value={accountDetail.website_title}
                onChange={(e) =>
                  handleUpdateDetail("website_title", e.target.value)
                }
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Enter title"}
                variant="outlined"
                size="small"
                error={errMsg.website_title}
                helperText={errMsg.website_title}
              />
            </Grid>

            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Website Link
              </InputLabel>
              <TextField
                value={accountDetail.website_link}
                onChange={(e) =>
                  handleUpdateDetail("website_link", e.target.value)
                }
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Enter website link"}
                variant="outlined"
                size="small"
                error={errMsg.website_link}
                helperText={errMsg.website_link}
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                User Name
              </InputLabel>
              <TextField
                value={accountDetail.username}
                onChange={(e) => handleUpdateDetail("username", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Enter Username"}
                variant="outlined"
                size="small"
                error={errMsg.username}
                helperText={errMsg.username}
              />
            </Grid>
            <Grid item lg={6} xs={12} sx={{ zIndex: "1" }}>
              <InputLabel
                sx={{
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                }}
              >
                Password
              </InputLabel>
              <OutlinedInput
                value={accountDetail.password}
                onChange={(e) => handleUpdateDetail("password", e.target.value)}
                fullWidth
                classes={{ root: classes.detailInput }}
                placeholder={"Enter password"}
                variant="outlined"
                size="small"
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                }}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                name="password"
                autoComplete={false}
                id="user-password"
                error={errMsg.password}
                helperText={errMsg.password}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => handleClose()}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          {open === "update" ? (
            <LoadingButton
              onClick={handleUpdateAccount}
              classes={{ root: classes.commisionSelectionButTon }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={updatePassword.isLoading}
            >
              Update
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={handleSaveAccount}
              classes={{ root: classes.commisionSelectionButTon }}
              color="inherit"
              autoFocus
              loadingPosition="start"
              loading={addPassword.isLoading}
            >
              Save
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddAccountModalStyle" })(
  AddAccountModal
);
