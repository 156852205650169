import React from "react";
import CardComponent from "../../../../components/cardComponent";
import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { MoreVert } from "@mui/icons-material";
import Avatar from "../../../../components/uiElements/avatar/index";
const BuyerSeller = ({ classes, tagName, bsName, bsEmail, title }) => {
  return (
    <CardComponent>
      <Grid
        container
        spacing={2}
        sx={{
          opacity:
            (!bsName || !bsEmail || bsName === "NA" || bsEmail === "NA") &&
            "0.3",
        }}
      >
        <Grid
          item
          xl={2}
          lg={2}
          sm={2}
          xs={2}
          md={2}
          className={classes.buyerSellerCardAlignment}
        >
          <Avatar title={title} sx={{ fontSize: "14px" }}>
            {tagName}
          </Avatar>
        </Grid>
        <Grid
          item
          xl={8}
          lg={8}
          sm={8}
          xs={8}
          md={8}
          className={classes.buyerSellerCardAlignment}
        >
          <Typography variant="h5">
            {bsName && bsName.trim()
              ? bsName.trim()
              : tagName === "L"
              ? "Add Lender Name"
              : ["B1", "B2"].includes(tagName)
              ? "Add Buyer Name"
              : "Add Seller Name"}
          </Typography>
          <Typography>
            {bsEmail && bsEmail.trim()
              ? bsEmail.trim()
              : tagName === "CA"
              ? "Lender Company Inc"
              : ["B1", "B2"].includes(tagName)
              ? "buyer@email.com"
              : "seller@email.com"}
          </Typography>
        </Grid>
        <Grid
          item
          xl={2}
          lg={2}
          sm={2}
          xs={2}
          md={2}
          className={classes.buyerSellerCardAlignment}
        >
          <MoreVert />
        </Grid>
      </Grid>
    </CardComponent>
  );
};

export default withStyles(styleSheet, { name: "buyerSellerStyle" })(
  BuyerSeller
);
