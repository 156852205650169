import React, { useState, Fragment } from "react";
import { Box, Typography, Button, Skeleton, Tabs, Tab } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import DrawOutlinedIcon from "@mui/icons-material/DrawOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate } from "react-router-dom";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import SendForSigning from "./sendForSigning";
function SubHeader(props) {
  const { classes, docDetail, tab, setTab } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Fragment>
      {" "}
      <Box className={classes.subHeaderSec}>
        <Box className={classes.subHeaderLeft}>
          <Box
            onClick={() => {
              navigate(-1);
            }}
            className={classes.subHeaderBack}
          >
            <ArrowBackRoundedIcon />
          </Box>
          {docDetail?.isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "180px" }}
            />
          ) : (
            <Typography>
              {docDetail?.data?.address}, {docDetail?.data?.city},{" "}
              {docDetail?.data?.state} {docDetail?.data?.zip_code}
            </Typography>
          )}
        </Box>
        <Box className={classes.subHeaderCenter}>
          {" "}
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="icon position tabs example"
            sx={{
              minHeight: 49,
            }}
          >
            <Tab
              icon={<DescriptionOutlinedIcon />}
              iconPosition="start"
              label="Document"
              sx={{
                minWidth: 46,
                padding: "4px 16px",
                minHeight: 49,
                textTransform: "capitalize",
              }}
            />
            <Tab
              icon={<PersonOutlineOutlinedIcon />}
              iconPosition="start"
              label="People"
              sx={{
                minWidth: 46,
                padding: "4px 16px",
                minHeight: 49,
                textTransform: "capitalize",
              }}
            />
            <Tab
              icon={<ReceiptLongOutlinedIcon />}
              iconPosition="start"
              label="Fields"
              sx={{
                minWidth: 46,
                padding: "4px 16px",
                minHeight: 49,
                textTransform: "capitalize",
              }}
            />
            <Tab
              icon={<HistoryRoundedIcon />}
              iconPosition="start"
              label="History"
              sx={{
                minWidth: 46,
                padding: "4px 16px",
                minHeight: 49,
                textTransform: "capitalize",
              }}
            />
          </Tabs>{" "}
        </Box>
        <Box className={classes.subHeaderRight}>
          <Button
            className={classes.headerButton}
            size="small"
            variant="contained"
            endIcon={<SendOutlinedIcon sx={{ ml: "12px" }} />}
            onClick={() => setOpen(true)}
          >
            Send
          </Button>
          <Button
            className={classes.headerButton}
            size="small"
            variant="contained"
            endIcon={<DrawOutlinedIcon sx={{ ml: "12px" }} />}
          >
            Sign
          </Button>
        </Box>
      </Box>
      <SendForSigning open={open} setOpen={setOpen} {...props} />
    </Fragment>
  );
}

export default SubHeader;
