import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Typography,
  InputLabel,
  DialogTitle,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { dateFormat } from "../../../utils/dateFormat";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectData } from "../../../redux/projects/projects";
import moment from "moment";
export default function UpdateDueDateProject(props) {
  const { setOpen, open, project } = props;
  const [dueDate, setDueDate] = useState(project?.due_date || null);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();

  const updateProject = useSelector((state) => state.project.updateProject);

  useEffect(() => {
    if (open && project?.due_date) {
      setDueDate(project?.due_date);
    }
  }, [open]);

  const onClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully update the due date",
      errorType: "success",
      isOpen: true,
    });
    onClose();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };
  const handleUpdate = () => {
    if (dueDate) {
      dispatch(
        updateProjectData({
          id: project?.id,
          schema: { due_date: moment(dueDate).format() },
          handleSuccess,
          handleError,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        onClose={onClose}
        open={open}
        disablePortal
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          {" "}
          <Typography
            sx={{
              color: "black",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            Update Due Date
          </Typography>
        </DialogTitle>
        <DialogContent>
          <InputLabel
            sx={{
              fontWeight: "500",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "14px",
              marginBottom: "8px",
            }}
          >
            Due Date & time
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              minutesStep={15}
              fullWidth
              value={dateFormat(dueDate)}
              onChange={(newValue) => setDueDate(newValue)}
              renderInput={(params) => (
                <TextField size="small" fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
              marginTop: "30px",
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              sx={{ color: "black", borderColor: "black !important" }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="info"
              sx={{ background: "black", color: "white" }}
              loading={updateProject.isLoading}
              loadingPosition="start"
              onClick={handleUpdate}
            >
              Update
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>{" "}
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}
