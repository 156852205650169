import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  Typography,
  Skeleton,
  Grid,
  Paper,
  MenuItem,
  Portal,
  TextField,
  Button,
  FormControl,
  Select,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NumberFormat } from "../../../../../utils/numberFormat";
import { useSelector, useDispatch } from "react-redux";
import { DataGridPro, GridRow } from "@mui/x-data-grid-pro";
import moment from "moment";
import ResponseAlert from "../../../../../components/responseAlert";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import TableSkeleton from "../../../../../components/loader/tableLoader.jsx";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import OutsideClickHandler from "react-outside-click-handler";
import ConfirmModal from "../../../../../components/globalModal/ConfirmModal";
import {
  deletePayment,
  getPayments,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import ApplyPayment from "./applyPayment.jsx";
import ViewInvoice from "./viewInvoice.jsx";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getInvoices } from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";

function PaymentsView(props) {
  const { classes, open, setOpen, id } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [openUpdate, setOpenUpdate] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, right: 0 });
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [openApplyPayment, setOpenApplyPayment] = useState(false);
  const [totalCount, setTotalCount] = useState(22); // Hardcode to 22 for now
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [allPayments, setAllPayments] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [postedAmount, setPostedAmount] = useState(0);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const store = useSelector((state) => state);
  const paymentsData = useSelector((state) => {
    return (
      state.agentRoster?.AgentTabs?.payments || { data: [], isLoading: false }
    );
  });
  const delPayment = useSelector(
    (state) => state.agentRoster.AgentTabs.delPayment
  );
  const handleToggleExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const columns = [
    {
      field: "payment_date",
      headerName: "DATE",
      renderHeader: () => <div style={{ paddingLeft: "8px" }}>DATE</div>,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.payment_date).format("L")}
          </Typography>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      renderHeader: () => <div style={{ paddingLeft: "8px" }}>STATUS</div>,
      renderCell: ({ row }) => {
        const status = row.status?.toLowerCase() || "pending";
        return (
          <Box
            sx={{
              background:
                status === "pending"
                  ? "#dedede"
                  : status === "posted"
                  ? "#499256"
                  : status === "deleted"
                  ? "#000000"
                  : "#dedede",
              color:
                status === "posted" || status === "deleted"
                  ? "#ffffff"
                  : "#000000",
              padding: "4px 12px",
              borderRadius: "4px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "capitalize",
            }}
            className={classes.statusChip}
          >
            <span style={{ position: "relative", top: "1px" }}>
              {row.status}
            </span>
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "type",
      headerName: "TYPE",
      renderHeader: () => <div style={{ paddingLeft: "8px" }}>TYPE</div>,
      renderCell: ({ row }) => {
        // Determine display text
        let displayText = row.type;
        if (row.type === "Marketing Budget") {
          displayText = "MBudget";
        } else if (row.type?.startsWith("E-Payment: ")) {
          displayText = row.type?.replace("E-Payment: ", "");
        }

        // Determine background color
        let backgroundColor = "#f5f5f5"; // default
        if (row.type === "Marketing Budget" || displayText === "MBudget") {
          backgroundColor = "#D1F2EC"; // Much lighter teal for Marketing Budget
        } else if (
          row.type?.replace("E-Payment: ", "") === "Zelle" ||
          displayText === "Zelle"
        ) {
          backgroundColor = "#E0D6F2"; // Purple for Zelle
        } else if (
          row.type?.replace("E-Payment: ", "") === "Venmo" ||
          displayText === "Venmo"
        ) {
          backgroundColor = "#D6EBFF"; // Light blue for Venmo
        } else if (
          row.type?.replace("E-Payment: ", "") === "PayPal" ||
          displayText === "PayPal"
        ) {
          backgroundColor = "#FFF5D6"; // Light yellow for PayPal
        } else if (
          row.type?.replace("E-Payment: ", "") === "Cash App" ||
          displayText === "Cash App"
        ) {
          backgroundColor = "#E6F5E6"; // Light green for Cash App
        } else if (row.type === "Commission") {
          backgroundColor = "#FFE6D6"; // Light orange for Commission
        } else if (row.type === "Check") {
          backgroundColor = "#DCF7FF"; // Updated light blue for checks
        } else if (row.type === "Bank Transfer") {
          backgroundColor = "#FDD9ED"; // Pink for Bank Transfer
        } else if (row.type === "Cash") {
          backgroundColor = "#BFE6C0"; // Light green for Cash
        }

        // Determine text color
        let textColor = "#1A1A1A"; // default
        if (
          row.type?.replace("E-Payment: ", "") === "Zelle" ||
          displayText === "Zelle"
        ) {
          textColor = "#4B0082"; // Indigo for Zelle
        } else if (
          row.type?.replace("E-Payment: ", "") === "Venmo" ||
          displayText === "Venmo"
        ) {
          textColor = "#0047AB"; // Blue for Venmo
        } else if (
          row.type?.replace("E-Payment: ", "") === "PayPal" ||
          displayText === "PayPal"
        ) {
          textColor = "#908033"; // Dark yellow for PayPal
        } else if (
          row.type?.replace("E-Payment: ", "") === "Cash App" ||
          displayText === "Cash App"
        ) {
          textColor = "#006400"; // Dark green for Cash App
        } else if (row.type === "Commission") {
          textColor = "#8B4500"; // Brown for Commission
        } else if (row.type === "Check") {
          textColor = "#206594"; // Updated blue for Check
        } else if (
          row.type === "Marketing Budget" ||
          displayText === "MBudget"
        ) {
          textColor = "#2A7A70"; // Darker teal for Marketing Budget
        } else if (row.type === "Bank Transfer") {
          textColor = "#9D4564"; // Specified color for Bank Transfer
        } else if (row.type === "Cash") {
          textColor = "#0C5C00"; // Dark green for Cash
        }

        return (
          <Box
            sx={{
              background: backgroundColor,
              color: textColor,
            }}
            className={classes.statusChip}
          >
            <span style={{ position: "relative", top: "1px" }}>
              {displayText}
            </span>
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      renderHeader: () => <div style={{ paddingLeft: "8px" }}>Description</div>,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>{row?.description}</Typography>
        );
      },
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "Amount",
      headerName: "Amount",
      renderHeader: () => <div style={{ paddingLeft: "8px" }}>Amount</div>,
      renderCell: ({ row }) => {
        // First check if it's a split payment
        const isSplitPayment = row.payment_invoices?.length > 1;

        // For split payments, show the individual amount_paid from payment_invoices
        if (isSplitPayment) {
          const totalAmount = row.payment_invoices.reduce(
            (sum, pi) => sum + Number(pi.amount_paid || 0),
            0
          );
          return (
            <Box align="center">
              <Typography
                sx={{
                  fontSize: "14px",
                  color:
                    !row.status || row.status.toLowerCase() === "pending"
                      ? "#9e9e9e" // Light grey for pending
                      : row.status.toLowerCase() === "deleted"
                      ? "#9e9e9e" // Light grey for deleted
                      : "#4CAF50", // Green for posted/completed
                  textDecoration:
                    row.status?.toLowerCase() === "deleted"
                      ? "line-through"
                      : "none",
                }}
              >
                $
                {Number(totalAmount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>
          );
        }

        // For regular payments, show the full amount
        return (
          <Box align="center">
            <Typography
              sx={{
                fontSize: "14px",
                color:
                  !row.status || row.status.toLowerCase() === "pending"
                    ? "#9e9e9e" // Light grey for pending
                    : row.status.toLowerCase() === "deleted"
                    ? "#9e9e9e" // Light grey for deleted
                    : "#4CAF50", // Green for posted/completed
                textDecoration:
                  row.status?.toLowerCase() === "deleted"
                    ? "line-through"
                    : "none",
              }}
            >
              $
              {Number(row?.amount || 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Box>
        );
      },
      alignItems: "center",
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 150,
      sortable: false,
      align: "center",
      renderHeader: () => null,
      renderCell: ({ row }) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0px",
            position: "relative",
          }}
        >
          {expandedRows[row.id] ? (
            <IconButton
              onClick={() => handleToggleExpand(row.id)}
              size="small"
              sx={{ transform: "translateY(4px)" }}
            >
              <ExpandLessRoundedIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => handleToggleExpand(row.id)}
              size="small"
              sx={{ transform: "translateY(4px)" }}
            >
              <ExpandMoreRoundedIcon />
            </IconButton>
          )}
          <Box sx={{ display: "flex", position: "relative" }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                const rect = e.currentTarget.getBoundingClientRect();
                setMenuPosition({
                  top: rect.top,
                  right: window.innerWidth - rect.right,
                });
                setOpenMenu(row.id);
                setPaymentDetail({
                  ...row,
                  payment_invoices: row.payment_invoices || [],
                  payment_attachments: row.payment_attachments || [],
                });
              }}
              size="small"
              disableRipple
              disabled={row?.status?.toLowerCase() === "posted"}
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                padding: "4px",
                position: "relative",
                color:
                  row?.status?.toLowerCase() === "posted"
                    ? "#d8d8d8"
                    : "inherit",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -16%)",
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  zIndex: -1,
                },
                "&.Mui-disabled": {
                  pointerEvents: "none",
                },
              }}
            >
              <MoreVertRoundedIcon sx={{ transform: "translateY(2px)" }} />
            </IconButton>
          </Box>
        </Box>
      ),
    },
  ];

  const handleSuccess = () => {
    setOpenDelete(false);
    setPaymentDetail({});
    setErrorAlert({
      errorMsg: "You have successfully deleted the payment",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const invoicesTableRef = useRef();

  const handleConfirm = () => {
    dispatch(
      deletePayment({
        payment_id: paymentDetail.id,
        handleError: (error) => {
          setErrorAlert({
            errorMsg: error,
            errorType: "error",
            isOpen: true,
          });
          setOpenDelete(false);
          setPaymentDetail({});
        },
        handleSuccess: () => {
          // Close delete modal
          setOpenDelete(false);
          setPaymentDetail({});

          // First refresh payments with a complete data fetch
          setAllPayments([]);
          setIsLoadingMore(true);

          // Function to fetch all payments recursively
          const fetchAllPayments = async (pageNum = 1, allItems = []) => {
            try {
              const action = await dispatch(
                getPayments({
                  user_id: id,
                  pageNumber: pageNum,
                  pageSize: 100,
                  append: false,
                })
              );

              const response = action.payload;
              if (!response || !response.data) {
                setIsLoadingMore(false);
                return allItems;
              }

              const newItems = response.data.filter(
                (payment) => payment.type !== "Marketing Budget"
              );
              const combinedItems = [...allItems, ...newItems];

              if (response.data.length === 100) {
                return fetchAllPayments(pageNum + 1, combinedItems);
              } else {
                setAllPayments(combinedItems);
                setTotalCount(combinedItems.length);
                setIsLoadingMore(false);
                return combinedItems;
              }
            } catch (error) {
              console.error("Error fetching payments:", error);
              setIsLoadingMore(false);
              return allItems;
            }
          };

          // Execute the refresh sequence
          fetchAllPayments()
            .then(() => {
              // Then refresh invoices and force a recalculation of totals
              return dispatch(
                getInvoices({
                  user_id: id,
                  forceRefresh: true,
                })
              );
            })
            .then(() => {
              // Force refresh of the invoices table payments total
              if (invoicesTableRef.current) {
                invoicesTableRef.current.refreshPaymentsTotal();
              }

              // Show success message after all updates complete
              setErrorAlert({
                errorMsg: "You have successfully deleted the payment",
                errorType: "success",
                isOpen: true,
              });
            })
            .catch((error) => {
              console.error("Error refreshing data:", error);
            });
        },
      })
    );
  };

  // Force the value to be a number
  const amount = React.useMemo(() => {
    const value = Number(paymentsData.paymentAmount || 0);
    return value;
  }, [paymentsData.paymentAmount]);

  const refreshPayments = () => {
    dispatch(getPayments({ user_id: id }));
  };

  // Update the useEffect to fetch all payments for calculation
  useEffect(() => {
    if (id && open) {
      setIsLoadingMore(true);

      // Function to fetch all payments recursively
      const fetchAllPayments = async (pageNum = 1, allItems = []) => {
        try {
          // First, dispatch the action
          const action = await dispatch(
            getPayments({
              user_id: id,
              pageNumber: pageNum,
              pageSize: 100, // Request larger pages to reduce number of requests
              append: false, // Don't append in Redux store
            })
          );

          // Check if we have a valid response
          const response = action.payload;
          if (!response || !response.data) {
            console.error("Invalid response format:", response);
            setIsLoadingMore(false);
            return allItems;
          }

          console.log("Response data length:", response.data.length);
          const newItems = response.data || [];
          const combinedItems = [...allItems, ...newItems];

          // If we got a full page, there might be more
          if (newItems.length === 100) {
            // Recursively fetch the next page
            return fetchAllPayments(pageNum + 1, combinedItems);
          } else {
            setAllPayments(combinedItems);
            setTotalCount(combinedItems.length);
            setIsLoadingMore(false);
            return combinedItems;
          }
        } catch (error) {
          console.error("Error fetching payments:", error);
          setIsLoadingMore(false);
          return allItems; // Return what we have so far
        }
      };

      // Start fetching all payments
      fetchAllPayments();
    }
  }, [id, open, dispatch]);

  // First, let's create a memoized filtered payments array
  const filteredPayments = useMemo(() => {
    return allPayments.filter((payment) => payment.type !== "Marketing Budget");
  }, [allPayments]);

  // Update the currentPageData calculation
  const currentPageData = useMemo(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredPayments.slice(startIndex, endIndex);
  }, [filteredPayments, page, pageSize]);

  // First, let's modify how we determine if data is loading
  const isDataLoading = paymentsData.isLoading && !paymentsData.data?.length;

  // Create a function to load more payments
  const loadMorePayments = () => {
    if (paymentsData.isLoading || !hasMore) return;

    dispatch(
      getPayments({
        user_id: id,
        pageNumber: page + 1,
        pageSize: 25,
        append: true, // Add this flag to indicate we're appending data
      })
    );

    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (paymentsData?.data?.length && !allPayments.length) {
      setAllPayments(paymentsData.data);
      setTotalCount(paymentsData.data.length);
    }
  }, [paymentsData.data, allPayments.length]);

  // Clean up fetchAllPayments
  const fetchAllPayments = async (pageNum = 1, allItems = []) => {
    try {
      const action = await dispatch(
        getPayments({
          user_id: id,
          pageNumber: pageNum,
          pageSize: 100,
          append: false,
        })
      );

      const response = action.payload;
      if (!response || !response.data) {
        setIsLoadingMore(false);
        return allItems;
      }

      const combinedItems = [...allItems, ...response.data];

      if (response.data.length === 100) {
        return fetchAllPayments(pageNum + 1, combinedItems);
      } else {
        setAllPayments(combinedItems);
        setTotalCount(combinedItems.length);
        setIsLoadingMore(false);
        return combinedItems;
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
      setIsLoadingMore(false);
      return allItems;
    }
  };

  // Update the useEffect that calculates the amounts
  useEffect(() => {
    if (allPayments.length > 0) {
      // Calculate pending count and amount
      let tempPendingCount = 0;
      let tempPendingAmount = 0;

      allPayments.forEach((payment) => {
        const status = payment.status?.toLowerCase();
        const amount = parseFloat(payment.amount || 0);

        if (isNaN(amount)) {
          console.warn("Invalid payment amount:", payment);
          return;
        }

        if (!status || status === "pending") {
          tempPendingCount++;
          tempPendingAmount += amount;
        }
      });

      setPendingCount(tempPendingCount);
      setPendingAmount(tempPendingAmount);

      // For posted amount, exclude Marketing Budget payments
      if (!isFiltered) {
        let tempPostedAmount = 0;
        allPayments.forEach((payment) => {
          const status = payment.status?.toLowerCase();
          const type = payment.type?.toLowerCase();
          const amount = parseFloat(payment.amount || 0);

          if (
            !isNaN(amount) &&
            status === "posted" &&
            type !== "marketing budget"
          ) {
            tempPostedAmount += amount;
          }
        });
        setPostedAmount(tempPostedAmount);
      } else {
        let tempPostedAmount = 0;
        paymentsData.data.forEach((payment) => {
          const status = payment.status?.toLowerCase();
          const type = payment.type?.toLowerCase();
          const amount = parseFloat(payment.amount || 0);

          if (
            !isNaN(amount) &&
            status === "posted" &&
            type !== "marketing budget"
          ) {
            tempPostedAmount += amount;
          }
        });
        setPostedAmount(tempPostedAmount);
      }
    }
  }, [allPayments, isFiltered, paymentsData.data]);

  // Update the handlePendingFilterToggle function to reset Redux state
  const handlePendingFilterToggle = () => {
    const newFilterState = !isFiltered;

    if (newFilterState) {
      // Apply filter - show only pending payments
      setIsLoadingMore(true);

      setTimeout(() => {
        // First filter out Marketing Budget, then filter for pending
        const filteredPayments = allPayments
          .filter(
            (payment) =>
              payment.type?.toLowerCase() !== "marketing budget" &&
              payment.type?.toLowerCase() !== "mbudget"
          )
          .filter(
            (payment) =>
              !payment.status || payment.status.toLowerCase() === "pending"
          );
        setAllPayments(filteredPayments);
        setTotalCount(filteredPayments.length);
        setIsFiltered(true);
        setIsLoadingMore(false);
      }, 50);
    } else {
      // Remove filter - reset Redux state
      dispatch({ type: "RESET_PAYMENTS_STATE" });
      setIsFiltered(false);
      setIsLoadingMore(true);

      // Fetch fresh data with a slight delay
      setTimeout(() => {
        dispatch(
          getPayments({
            user_id: id,
            pageNumber: 1,
            pageSize: 100,
            append: false,
          })
        ).then((action) => {
          if (action.payload?.data) {
            const filteredPayments = action.payload.data.filter(
              (payment) =>
                payment.type?.toLowerCase() !== "marketing budget" &&
                payment.type?.toLowerCase() !== "mbudget"
            );
            setAllPayments(filteredPayments);
            setTotalCount(filteredPayments.length);
          }
          setIsLoadingMore(false);
        });
      }, 100);
    }
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiDialog-paper": {
            maxHeight: "calc(100vh - 64px)", // Leave room for the dialog header
            display: "flex",
            flexDirection: "column",
          },
        }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            padding: "8px 24px",
            minHeight: "40px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
            <Typography variant="h6">{/* Original title content */}</Typography>
            <Typography variant="h6" sx={{ color: "text.secondary", ml: -0.5 }}>
              Payments
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "24px",
            paddingBottom: "0",
            overflow: "hidden",
            "&.MuiDialogContent-root": {
              paddingTop: "24px !important",
              paddingBottom: "0 !important",
            },
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              margin: "0 -24px",
              padding: "0 24px",
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflowY: "auto",
                overflowX: "visible",
                paddingBottom: "90px",
                position: "relative",
                margin: "0 -24px", // Create space for shadows
                padding: "0 24px", // Maintain visual alignment
                "& .MuiDataGrid-root": {
                  overflow: "visible !important",
                },
                "& .MuiDataGrid-row": {
                  overflow: "visible !important",
                },
                "&::after": {
                  content: '""',
                  display: "block",
                  height: "90px",
                  position: "sticky",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: "white",
                  marginBottom: "-90px",
                },
              }}
            >
              <Grid container spacing={2} sx={{ mt: "-30px" }}>
                <Grid item xs={3.2}>
                  <Card
                    sx={{
                      background: "#f5f5f5",
                      borderRadius: "20px",
                      padding: "16px 24px",
                      border: "none",
                      boxShadow: "none",
                      position: "relative",
                      zIndex: 1,
                      overflow: "visible",
                      mt: 2,
                      maxWidth: "100%",
                      cursor: "pointer",
                      transition: "background-color 0.2s",
                      "&:hover": {
                        background: "#ebebeb",
                      },
                    }}
                    onClick={handlePendingFilterToggle}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", color: "text.secondary" }}
                      >
                        Total Pending Amount
                      </Typography>
                      <FilterAltIcon
                        sx={{
                          color: isFiltered ? "#1976d2" : "#999",
                          fontSize: "1.2rem",
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "block", marginTop: "4px" }}>
                      {paymentsData.isLoading ? (
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                      ) : (
                        <Box
                          component="p"
                          sx={{ fontSize: "2rem", margin: 0, padding: 0 }}
                        >
                          {NumberFormat({
                            number: pendingAmount,
                            maximumFractionDigits: 2,
                            currency: "USD",
                          })}
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={3.2}>
                  <Card
                    sx={{
                      background: "#f5f5f5",
                      borderRadius: "20px",
                      padding: "16px 24px",
                      border: "none",
                      boxShadow: "none",
                      position: "relative",
                      zIndex: 1,
                      overflow: "visible",
                      mt: 2,
                      maxWidth: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography
                        sx={{ fontSize: "16px", color: "text.secondary" }}
                      >
                        Total Posted Amount
                      </Typography>
                      <Tooltip
                        title="Excludes Marketing Budget Payments"
                        placement="top"
                      >
                        <HelpOutlineIcon
                          sx={{
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.38)",
                            cursor: "help",
                            mt: "-1px",
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Box sx={{ display: "block", marginTop: "4px" }}>
                      {paymentsData.isLoading ? (
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                      ) : (
                        <Box
                          component="p"
                          sx={{ fontSize: "2rem", margin: 0, padding: 0 }}
                        >
                          {NumberFormat({
                            number: postedAmount,
                            maximumFractionDigits: 2,
                            currency: "USD",
                          })}
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={3.2}>
                  <Card
                    onClick={() => {
                      // TODO: Implement modal open logic
                    }}
                    sx={{
                      background: "#f5f5f5",
                      borderRadius: "20px",
                      padding: "16px 24px",
                      border: "none",
                      boxShadow: "none",
                      position: "relative",
                      zIndex: 1,
                      overflow: "visible",
                      mt: 2,
                      maxWidth: "100%",
                      cursor: "pointer",
                      transition: "background-color 0.2s",
                      "&:hover": {
                        background: "#ebebeb",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", color: "text.secondary" }}
                      >
                        Total Credit Amount
                      </Typography>
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "-5px", // Added negative margin to shift right
                          "& svg": {
                            fontSize: "1.4rem",
                            color: "#999999",
                          },
                        }}
                      >
                        <CropFreeRoundedIcon />
                      </Box>
                    </Box>
                    <Box sx={{ display: "block", marginTop: "4px" }}>
                      <Box
                        component="p"
                        sx={{ fontSize: "2rem", margin: 0, padding: 0 }}
                      >
                        $0.00
                      </Box>
                    </Box>
                  </Card>
                </Grid>

                <Grid
                  item
                  xs={2.4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setOpenApplyPayment(true)}
                    sx={{
                      backgroundColor: "#000000",
                      borderRadius: "8px",
                      textTransform: "none",
                      fontWeight: 500,
                      padding: "10px 24px",
                      "&:hover": {
                        backgroundColor: "#333333",
                      },
                    }}
                  >
                    Apply Payment
                  </Button>
                </Grid>
              </Grid>
              <Box
                className={classes.PaymentViewSection}
                sx={{
                  borderRadius: "18px",
                  overflow: "visible",
                  position: "relative",
                  padding: "1px",
                }}
              >
                <Box
                  className={classes.paymentTableRoot}
                  sx={{
                    position: "relative",
                    marginTop: "0px !important",
                    overflow: "visible !important",
                    zIndex: 2,
                    "& .MuiDataGrid-root": {
                      border: "none !important",
                      padding: "0 !important",
                      overflow: "visible !important",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      overflow: "visible !important",
                    },
                    "& .MuiDataGrid-virtualScrollerContent": {
                      overflow: "visible !important",
                    },
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                      overflow: "visible !important",
                    },
                    "& .MuiDataGrid-row:first-of-type": {
                      marginTop: "10px !important",
                    },
                  }}
                >
                  <DataGridPro
                    rows={currentPageData}
                    loading={isLoadingMore}
                    columns={columns}
                    disableSelectionOnClick
                    hideFooter
                    autoHeight
                    rowHeight={52}
                    getRowId={(row) => row.id}
                    sx={{
                      border: "none !important",
                      overflow: "visible !important",
                      "& .MuiDataGrid-root": {
                        overflow: "visible !important",
                      },
                      "& .MuiDataGrid-row": {
                        minHeight: "52px !important",
                        height: "52px !important",
                        maxHeight: "none !important",
                        zIndex: 1,
                      },
                      "& .MuiDataGrid-cell": {
                        height: "48px !important",
                        padding: "0 !important",
                        "& .MuiBox-root, & .MuiTypography-root": {
                          marginTop: "-22px !important",
                        },
                      },
                      "& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row":
                        {
                          height: "48px !important",
                          minHeight: "48px !important",
                          maxHeight: "48px !important",
                        },
                      "& .MuiDataGrid-columnHeaders": {
                        minHeight: "60px !important",
                        padding: "8px 0 16px 0 !important",
                        marginBottom: "0 !important",
                      },
                      "& .MuiDataGrid-virtualScrollerContent": {
                        marginTop: "0 !important",
                        paddingTop: "0 !important",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        marginTop: "0 !important",
                        "& .MuiDataGrid-row:first-of-type": {
                          marginTop: "8px !important",
                        },
                      },
                      "& .MuiDataGrid-cell": {
                        height: "48px !important",
                        display: "flex !important",
                        alignItems: "center !important",
                        justifyContent: "flex-start !important",
                        padding: "0 !important",
                        "& .MuiBox-root, & .MuiTypography-root": {
                          marginTop: "-22px !important",
                        },
                      },
                      '& .MuiDataGrid-cell[data-field="Amount"]': {
                        display: "flex !important",
                        alignItems: "center !important",
                        "& .MuiBox-root": {
                          height: "100%",
                          display: "flex !important",
                          alignItems: "center !important",
                          marginTop: "-9px !important",
                        },
                      },
                      "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textCenter":
                        {
                          height: "100% !important",
                          "& .MuiBox-root": {
                            height: "100% !important",
                            alignItems: "center !important",
                            marginTop: "-30px !important",
                            '& .MuiButtonBase-root .MuiSvgIcon-root[data-testid="MoreVertRoundedIcon"]':
                              {
                                marginTop: "18px !important",
                              },
                          },
                        },
                      "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textLeft":
                        {
                          paddingLeft: "20px !important",
                        },
                      "& .MuiDataGrid-main": {
                        overflow: "visible !important",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        overflow: "visible !important",
                      },
                      "& .MuiDataGrid-row": {
                        overflow: "visible !important",
                      },
                    }}
                    components={{
                      LoadingOverlay: TableSkeleton,
                      Row: (props) => (
                        <Box
                          className={`css-1ix50fd`}
                          sx={{
                            position: "relative",
                            overflow: "visible",
                            width: "100%",
                            margin: "5px 0 !important",
                            marginBottom: "5px !important",
                            background: "white !important",
                            borderRadius: "20px !important",
                            boxShadow: expandedRows[props.row.id]
                              ? "none !important"
                              : "0px 10px 27px 0px rgba(0, 0, 0, 0.08) !important",
                            zIndex: 0,
                            "& .MuiDataGrid-row": {
                              background: "white !important",
                              boxShadow: "none !important",
                              position: "relative",
                              zIndex: 1,
                              top: expandedRows[props.rowId]
                                ? "0 !important"
                                : "auto !important",
                              marginTop: expandedRows[props.rowId]
                                ? "0 !important"
                                : "auto !important",
                              minHeight: "60px !important",
                              "& .MuiDataGrid-cell": {
                                minHeight: "60px !important",
                                "& .MuiBox-root, & .MuiTypography-root, & .MuiButtonBase-root":
                                  {
                                    transform: "translateY(4px)",
                                  },
                              },
                              '& .MuiDataGrid-cell[data-field="Amount"]': {
                                "& .MuiBox-root": {
                                  height: "100%",
                                  display: "flex !important",
                                  alignItems: "center !important",
                                },
                              },
                              '& .MuiDataGrid-cell[data-field="actions"]': {
                                "& .MuiBox-root": {
                                  height: "100%",
                                  display: "flex !important",
                                  alignItems: "center !important",
                                  justifyContent: "right !important",
                                  marginRight: "10px",
                                },
                              },
                            },
                          }}
                        >
                          <Box
                            sx={{ position: "relative", overflow: "visible" }}
                          >
                            <GridRow {...props} />
                            {openMenu === props.rowId && (
                              <Portal>
                                <OutsideClickHandler
                                  onOutsideClick={() => {
                                    setOpenMenu(false);
                                    setMenuPosition({ top: 0, right: 0 });
                                  }}
                                  style={{
                                    position: "fixed",
                                    zIndex: 999999999,
                                    top: "0",
                                    left: "0",
                                    right: "0",
                                    bottom: "0",
                                    pointerEvents: "none",
                                  }}
                                >
                                  <Paper
                                    sx={{
                                      position: "fixed",
                                      top: menuPosition.top + 55,
                                      right: menuPosition.right - 5,
                                      height: "auto",
                                      border: "0.1px solid lightgrey",
                                      background: "white",
                                      width: "180px",
                                      zIndex: 999999999,
                                      pointerEvents: "auto",
                                      boxShadow:
                                        "0px 5px 15px rgba(0, 0, 0, 0.2)",
                                      "&:before": {
                                        content: '""',
                                        position: "absolute",
                                        top: "-8px",
                                        right: "13px",
                                        borderLeft: "8px solid transparent",
                                        borderRight: "8px solid transparent",
                                        borderBottom: "8px solid lightgrey",
                                        zIndex: 999999999,
                                      },
                                      "&:after": {
                                        content: '""',
                                        position: "absolute",
                                        top: "-7px",
                                        right: "14px",
                                        borderLeft: "7px solid transparent",
                                        borderRight: "7px solid transparent",
                                        borderBottom: "7px solid white",
                                        zIndex: 999999999,
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      sx={{
                                        zIndex: 99999,
                                        padding: "8px 16px",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.04)",
                                        },
                                      }}
                                      onClick={() => {
                                        setOpenMenu(false);
                                        setOpenUpdate(true);
                                        setPaymentDetail(props?.row);
                                      }}
                                    >
                                      Edit Payment
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        padding: "8px 16px",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.04)",
                                        },
                                      }}
                                      onClick={() => {
                                        setOpenMenu(false);
                                        setPaymentDetail(props?.row);
                                        setOpenDelete(true);
                                      }}
                                    >
                                      Delete Payment
                                    </MenuItem>
                                  </Paper>
                                </OutsideClickHandler>
                              </Portal>
                            )}
                            {expandedRows[props.rowId] && (
                              <Box
                                className="expanded-content"
                                sx={{
                                  padding: "20px",
                                  paddingTop: "35px !important",
                                  paddingBottom: "20px !important",
                                  background: "#FFFFFF !important",
                                  marginTop: "-20px !important",
                                  position: "relative",
                                  zIndex: 0,
                                  borderRadius: "20px",
                                  boxShadow:
                                    "0px 10px 50px 0px rgba(0, 0, 0, 0.12)",
                                  "&::before": {
                                    content: '""',
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: "40px",
                                    background: "white",
                                  },
                                  "&::after": {
                                    content: '""',
                                    position: "absolute",
                                    top: "20px",
                                    left: "20px",
                                    right: "20px",
                                    height: "1px",
                                    background: "rgba(224, 224, 224, 1)",
                                    zIndex: 2,
                                  },
                                  "& .MuiDataGrid-root": {
                                    border: "none",
                                    "& .MuiDataGrid-virtualScroller": {
                                      marginTop: "8px",
                                    },
                                    "& .MuiDataGrid-row": {
                                      cursor: "pointer",
                                      backgroundColor: "#fff",
                                      margin: "4px 0",
                                      borderRadius: "8px",
                                      border:
                                        "1px solid rgba(224, 224, 224, 0.8) !important",
                                      "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                      },
                                      "&:hover": {
                                        backgroundColor: "#f5f5f5",
                                      },
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                      borderBottom: "none",
                                      backgroundColor: "transparent",
                                      "& .MuiDataGrid-columnHeader": {
                                        paddingLeft: "16px !important",
                                      },
                                      marginBottom: "-8px !important",
                                    },
                                  },
                                }}
                              >
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.subHeading01}
                                    >
                                      Invoices Paid
                                    </Typography>
                                    <Box className={classes.subTable01}>
                                      <DataGridPro
                                        rows={
                                          props?.row?.payment_invoices?.length
                                            ? props?.row?.payment_invoices
                                            : []
                                        }
                                        autoHeight
                                        hideFooter
                                        rowHeight={52}
                                        sx={{
                                          border: "none",
                                          "& .MuiDataGrid-virtualScroller": {
                                            marginTop: "8px",
                                          },
                                          "& .MuiDataGrid-row": {
                                            cursor: "pointer",
                                            backgroundColor: "#fff",
                                            margin: "4px 0",
                                            borderRadius: "8px",
                                            border:
                                              "1px solid rgba(224, 224, 224, 0.8) !important",
                                            "& .MuiDataGrid-cell": {
                                              borderBottom: "none",
                                            },
                                            "&:hover": {
                                              backgroundColor: "#f5f5f5",
                                            },
                                          },
                                          "& .MuiDataGrid-columnHeaders": {
                                            borderBottom: "none",
                                            backgroundColor: "transparent",
                                            "& .MuiDataGrid-columnHeader": {
                                              paddingLeft: "16px !important",
                                            },
                                            marginBottom: "-8px !important",
                                          },
                                        }}
                                        columns={[
                                          {
                                            field: "invoice_date",
                                            headerName: "INV DATE",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Typography
                                                  sx={{ fontSize: "14px" }}
                                                >
                                                  {moment(
                                                    row?.user_tab_invoice
                                                      ?.invoice_date ||
                                                      row?.invoice_date
                                                  ).format("L")}
                                                </Typography>
                                              );
                                            },
                                            width: 120,
                                            editable: false,
                                            sortable: true,
                                          },
                                          {
                                            field: "invoice_number",
                                            headerName: "INVOICE #",
                                            renderCell: ({ row }) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    width: "100%",
                                                    position: "relative",
                                                    top: "-8px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      lineHeight: "normal",
                                                    }}
                                                  >
                                                    {row?.user_tab_invoice
                                                      ?.invoice_number ||
                                                      row?.invoice_number}
                                                  </span>
                                                  <DescriptionRoundedIcon
                                                    fontSize="small"
                                                    style={{
                                                      cursor: "pointer",
                                                      verticalAlign: "middle",
                                                      fontSize: "20px",
                                                      opacity: 0.7,
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setSelectedInvoice({
                                                        invoice_number:
                                                          row?.user_tab_invoice
                                                            ?.invoice_number ||
                                                          row?.invoice_number,
                                                        invoice_file_path:
                                                          row?.user_tab_invoice
                                                            ?.pdf_path ||
                                                          row?.pdf_path,
                                                        isInvoice: true,
                                                      });
                                                      setOpenInvoiceModal(true);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            },
                                            width: 150,
                                            editable: false,
                                            sortable: true,
                                          },
                                          {
                                            field: "Category",
                                            headerName: "Category",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Typography
                                                  sx={{ fontSize: "14px" }}
                                                >
                                                  {row?.user_tab_invoice
                                                    ?.category || row?.category}
                                                </Typography>
                                              );
                                            },
                                            width: 180,
                                            editable: false,
                                            sortable: true,
                                          },
                                          {
                                            field: "Description",
                                            headerName: "Description",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Typography
                                                  sx={{ fontSize: "14px" }}
                                                >
                                                  {row?.user_tab_invoice
                                                    ?.description ||
                                                    row?.description}
                                                </Typography>
                                              );
                                            },
                                            minWidth: 200,
                                            flex: 1,
                                            editable: false,
                                            sortable: true,
                                          },
                                          {
                                            field: "Amount",
                                            headerName: "Amount",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Box align="center">
                                                  <Typography
                                                    sx={{
                                                      fontSize: "14px",
                                                      color: "#000000",
                                                    }}
                                                  >
                                                    $
                                                    {Number(
                                                      row?.amount_paid || 0
                                                    ).toLocaleString("en-US", {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    })}
                                                  </Typography>
                                                </Box>
                                              );
                                            },
                                            alignItems: "center",
                                            width: 150,
                                            editable: false,
                                            sortable: true,
                                          },
                                        ]}
                                      />
                                    </Box>
                                  </Grid>
                                  {props?.row?.payment_attachments?.length >
                                  0 ? (
                                    <Grid item xs={12}>
                                      <Typography
                                        className={classes.subHeading01}
                                      >
                                        Attachments
                                      </Typography>
                                      <Box className={classes.attachments}>
                                        {props?.row?.payment_attachments?.map(
                                          (attachment, index) => (
                                            <Box
                                              key={index}
                                              className={
                                                classes.attachmentsItem
                                              }
                                              onClick={() => {
                                                setSelectedInvoice({
                                                  invoice_number:
                                                    attachment.file,
                                                  invoice_file_path:
                                                    attachment.file_path,
                                                  isInvoice: false,
                                                });
                                                setOpenInvoiceModal(true);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <DescriptionRoundedIcon
                                                sx={{
                                                  color: "#696969",
                                                  fontSize: "1.2rem",
                                                }}
                                              />
                                              <Typography
                                                sx={{
                                                  color: "#0082F4DE !important",
                                                  fontWeight: "500",
                                                  fontSize: "0.875rem",
                                                }}
                                              >
                                                {attachment.file}
                                              </Typography>
                                            </Box>
                                          )
                                        )}
                                      </Box>
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            mt: 2,
            mb: 0.75,
            pr: 2,
            pb: 0.5,
          }}
        >
          <Typography variant="body2" sx={{ mr: 2, fontSize: "0.875rem" }}>
            Rows per page:
          </Typography>
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(e.target.value);
              setPage(1); // Reset to first page when changing page size
            }}
            size="small"
            sx={{
              mr: 2,
              minWidth: "80px",
              height: "28px",
              ".MuiOutlinedInput-input": {
                padding: "4px 14px 4px 10px",
                fontSize: "0.875rem",
              },
              ".MuiSelect-icon": {
                top: "calc(50% - 0.5em)",
              },
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>

          <Typography variant="body2" sx={{ mr: 2, fontSize: "0.875rem" }}>
            {filteredPayments.length > 0
              ? `${(page - 1) * pageSize + 1}–${Math.min(
                  page * pageSize,
                  filteredPayments.length
                )} of ${filteredPayments.length}`
              : "0-0 of 0"}
          </Typography>

          <IconButton
            disabled={isLoadingMore || page === 1 || !filteredPayments.length}
            onClick={() => setPage((prev) => Math.max(1, prev - 1))}
            size="small"
            sx={{ mr: 1, padding: "4px" }}
          >
            {isLoadingMore ? (
              <CircularProgress size={16} />
            ) : (
              <ChevronLeftIcon fontSize="small" />
            )}
          </IconButton>

          <IconButton
            disabled={
              isLoadingMore || page * pageSize >= filteredPayments.length
            }
            onClick={() => setPage((prev) => prev + 1)}
            size="small"
            sx={{ padding: "4px" }}
          >
            {isLoadingMore ? (
              <CircularProgress size={16} />
            ) : (
              <ChevronRightIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
      </Dialog>

      <ApplyPayment
        open={openUpdate}
        setOpen={setOpenUpdate}
        id={id}
        isUpdate={true}
        prePaymentDetail={paymentDetail}
        handleSuccessCall={refreshPayments}
        showCharges={true}
        showAttachments={true}
        charges={paymentDetail?.payment_invoices || []}
        attachments={paymentDetail?.payment_attachments || []}
      />

      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Payment"
        description="Are you sure you want to delete this payment?"
        handleConfirm={handleConfirm}
      />

      <ResponseAlert
        open={errorAlert.isOpen}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
        setOpen={() => setErrorAlert((prev) => ({ ...prev, isOpen: false }))}
      />

      <ViewInvoice
        open={openInvoiceModal}
        setOpen={setOpenInvoiceModal}
        pdfUrl={selectedInvoice?.invoice_file_path}
        invoiceNumber={selectedInvoice?.invoice_number}
        showInvoicePrefix={selectedInvoice?.isInvoice}
      />

      {openApplyPayment && (
        <ApplyPayment
          open={openApplyPayment}
          setOpen={setOpenApplyPayment}
          id={id}
          refreshPaymentsData={fetchAllPayments}
        />
      )}
    </Fragment>
  );
}

export default PaymentsView;
