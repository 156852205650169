import { NameCard, PhoneCell, StageCell } from "./TableCellsAgentRecruits";
import { date_or_price, include, phone, price } from "./filterOperatorSchemas";
import moment from "moment";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import NumbersIcon from "@mui/icons-material/Numbers";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Tooltip } from "@mui/material";

const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};
export const getColumns = (
  order,
  tagsList,
  stagesList,
  citiesListGAMLS,
  citiesListFMLS,
  zipCodesListFMLS,
  zipCodesListGAMLS,
  zipCodesListStellar,
  citiesListStellar,
  usersList,
  usersObj,
  officesList,
  citiesListMiamire,
  zipCodesListMiamire,
  zipCodesListCarolina,
  citiesListCAROLINA
) => {
  let defaultCols = {
    MemberFullName: {
      field: "MemberFullName",
      headerName: "Name",
      minWidth: 200,
      renderCell: ({ row }) => {
        if (row.MemberFullName) {
          return (
            <Tooltip
              placement="top"
              title={row.MemberFullName}
              arrow
              followCursor
              disableHoverListener={true}
            >
              <NameCard market={row.Market} name={row.MemberFullName} />
            </Tooltip>
          );
        } else {
          return <p>--</p>;
        }
      },
      props: {
        canSort: true,
        icon: TextFieldsIcon,
        type: "Text",
      },
      type: "text",
      sortable: false,
      targetIndex: 4,
      hidden: true,
    },

    assignedTo: {
      field: "assignedTo",
      headerName: "Assigned To",
      minWidth: 40,
      props: {
        data: usersList.map((usr) => {
          return {
            ...usr,
            name: usr.full_name + "--" + usr.id,
          };
        }),
        icon: AirplaneTicketIcon,
        type: "users",
      },
      renderCell: ({ row }) => {
        return (
          <p className="font-12 font-md">
            {usersObj[row.assignedTo]
              ? usersObj[row.assignedTo].full_name
              : "--"}
          </p>
        );
      },
      type: "include/exclude/empty",
      sortable: false,
      filterOperators: include,
    },
    Market: {
      field: "Market",
      headerName: "Market",
      renderCell: ({ row }) => {
        return <p className="font-12 font-md">{row.Market || "--"}</p>;
      },
      props: {
        data: stagesList,
        canSort: true,
        icon: TextFieldsIcon,
        type: "Text",
      },
      type: "text",
      sortable: false,
      minWidth: 40,
    },
    MemberEmail: {
      field: "MemberEmail ",
      headerName: "Email",
      renderCell: ({ row }) => {
        return <p className="font-12 font-md">{row.MemberEmail || "--"}</p>;
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "email",
      },
      valueGetter: ({ row }) => row.MemberEmail,
      type: "email",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
    MemberStateLicense: {
      field: "MemberStateLicense",
      headerName: "License",
      renderCell: ({ row }) => {
        return (
          <p className="font-12 font-md">{row.MemberStateLicense || "--"}</p>
        );
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
    MemberPreferredPhone: {
      field: "MemberPreferredPhone",
      headerName: "Phone",
      minWidth: 130,
      renderCell: ({ row }) => {
        if (row.lead_phones.length) {
          return <PhoneCell number={row?.lead_phones[0].value} />;
        } else {
          return "--";
        }
      },
      props: {
        canSort: true,
        icon: LocalPhoneIcon,
        type: "Phone",
      },
      filterOperators: phone,
      type: "phone",
      sortable: false,
    },
    tags: {
      field: "tags",
      headerName: "Tags",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.realtor_tags?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.realtor_tags.map((item, index) => item.tag).join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      props: {
        data: tagsList,
        icon: AirplaneTicketIcon,
        type: "Tags",
      },
      type: "include/exclude/empty",
      sortable: false,
      filterOperators: include,
    },
    Office: {
      field: "Office",
      headerName: "Office",
      minWidth: 40,
      renderCell: ({ row }) => {
        console.log(row);
        return (
          <p className="font-12 font-md">
            {row?.agent_gamls_data?.realtor_agent_office?.OfficeName ||
              row?.agent_fmls_data?.realtor_agent_office?.OfficeName ||
              row?.agent_stellar_data?.realtor_agent_office?.OfficeName ||
              row?.agent_miamire_data?.realtor_agent_office?.OfficeName ||
              row?.MemberOfficeName ||
              "--"}
          </p>
        );
      },
      props: {
        data: officesList,
        icon: AirplaneTicketIcon,
        type: "Offices",
      },
      type: "include/exclude",
      sortable: false,
      filterOperators: include,
    },
    lastCommunicationTimestamp: {
      field: "lastCommunicationTimestamp",
      headerName: "Last Communication",
      minWidth: 40,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.lastCommunicationTimestamp
              ? moment(row.lastCommunicationTimestamp).fromNow()
              : "--"}
          </p>
        );
      },
      props: {
        canSort: true,
        icon: DateRangeIcon,
        type: "Created",
      },

      sortable: false,
      filterOperators: date_or_price,
    },
    stageName: {
      field: "stageName",
      headerName: "Stage",
      minWidth: 130,
      renderCell: ({ row }) => {
        return (
          <p> {row.stageName ? <StageCell name={row.stageName} /> : "--"}</p>
        );
      },
      props: {
        data: stagesList,
        canSort: true,
        icon: MenuIcon,
        type: "Stage",
      },
      type: "include/exclude",
      sortable: false,

      filterOperators: include,
    },
    totalVolume12Months_STELLAR: {
      field: "totalVolume12Months_STELLAR",
      headerName: "Volume 12 Months (Stellar)",
      minWidth: 40,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.totalVolume12Months_STELLAR
              ? parseInt(row.totalVolume12Months_STELLAR)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .slice(0, -3)
              : "--"}
          </p>
        );
      },
      props: {
        canSort: true,
        icon: PriceChangeIcon,
        type: "Price",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
    },
    totalVolume12Months_CAROLINA: {
      field: "totalVolume12Months_CAROLINA",
      headerName: "Volume 12 Months (Charlotte)",
      minWidth: 40,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.totalVolume12Months_CAROLINA
              ? parseInt(row.totalVolume12Months_CAROLINA)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .slice(0, -3)
              : "--"}
          </p>
        );
      },
      props: {
        canSort: true,
        icon: PriceChangeIcon,
        type: "Price",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
    },
    totalVolume12Months_MIAMIRE: {
      field: "totalVolume12Months_MIAMIRE",
      headerName: "Volume 12 Months (MIAMIRE)",
      minWidth: 40,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.totalVolume12Months_MIAMIRE
              ? parseInt(row.totalVolume12Months_MIAMIRE)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .slice(0, -3)
              : "--"}
          </p>
        );
      },
      props: {
        canSort: true,
        icon: PriceChangeIcon,
        type: "Price",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
    },
    totalVolume12Months_FMLS: {
      field: "totalVolume12Months_FMLS",
      headerName: "Volume 12 Months (FMLS)",
      minWidth: 40,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.totalVolume12Months_FMLS
              ? parseInt(row.totalVolume12Months_FMLS)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .slice(0, -3)
              : "--"}
          </p>
        );
      },
      props: {
        canSort: true,
        icon: PriceChangeIcon,
        type: "Price",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
    },

    totalVolume12Months_GAMLS: {
      field: "totalVolume12Months_GAMLS",
      headerName: "Volume 12 Months (GAMLS)",
      minWidth: 40,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.totalVolume12Months_GAMLS
              ? parseInt(row.totalVolume12Months_GAMLS)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .slice(0, -3)
              : "--"}
          </p>
        );
      },
      props: {
        canSort: true,
        icon: PriceChangeIcon,
        type: "Price",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
    },
    totalVolume12Months_GALMLS: {
      field: "totalVolume12Months_GALMLS",
      headerName: "Volume 12 Months (GALMLS)",
      minWidth: 40,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.totalVolume12Months_GALMLS
              ? parseInt(row.totalVolume12Months_GALMLS)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .slice(0, -3)
              : "--"}
          </p>
        );
      },
      props: {
        canSort: true,
        icon: PriceChangeIcon,
        type: "Price",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
    },
    totalSales_FMLS: {
      field: "totalSales_FMLS",
      headerName: "Total Sales (FMLS)",
      renderCell: ({ row }) => {
        return <p className="font-12 font-md">{row.totalSales_FMLS || "--"}</p>;
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
    totalSales_MIAMIRE: {
      field: "totalSales_MIAMIRE",
      headerName: "Total Sales (MIAMIRE)",
      renderCell: ({ row }) => {
        return (
          <p className="font-12 font-md">{row.totalSales_MIAMIRE || "--"}</p>
        );
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
    totalSales_CAROLINA: {
      field: "totalSales_CAROLINA",
      headerName: "Total Sales (Charlotte)",
      renderCell: ({ row }) => {
        return (
          <p className="font-12 font-md">{row.totalSales_CAROLINA || "--"}</p>
        );
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
    totalSales_GAMLS: {
      field: "totalSales_GAMLS",
      headerName: "Total Sales (GAMLS)",
      renderCell: ({ row }) => {
        return (
          <p className="font-12 font-md">{row.totalSales_GAMLS || "--"}</p>
        );
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
    totalSales_GALMLS: {
      field: "totalSales_GALMLS",
      headerName: "Total Sales (GALMLS)",
      renderCell: ({ row }) => {
        return (
          <p className="font-12 font-md">{row.totalSales_GALMLS || "--"}</p>
        );
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
    activeZips_Stellar: {
      field: "activeZips_Stellar",
      headerName: "Active Zips (Stellar)",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeZips_Stellar?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeZips_Stellar
                  .map((item, index) => item.postal_code)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      type: "include/exclude/empty",
      props: {
        data: zipCodesListStellar,
        icon: AirplaneTicketIcon,
        type: "Active Zips (Stellar)",
      },
      sortable: false,
      filterOperators: include,
    },
    activeZips_FMLS: {
      field: "activeZips_FMLS",
      headerName: "Active Zips FMLS",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeZips_FMLS?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeZips_FMLS
                  .map((item, index) => item.postal_code)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeZips_FMLS.map((item, index) => item.postal_code).join(","),
      type: "include/exclude/empty",
      props: {
        data: zipCodesListFMLS,
        icon: AirplaneTicketIcon,
        type: "Active Zips FMLS",
      },
      sortable: false,
      filterOperators: include,
    },
    activeZips_GAMLS: {
      field: "activeZips_GAMLS",
      headerName: "Active Zips GAMLS",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeZips_GAMLS?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeZips_GAMLS
                  .map((item, index) => item.postal_code)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeZips_GAMLS.map((item, index) => item.postal_code).join(","),
      type: "include/exclude/empty",
      props: {
        data: zipCodesListGAMLS,
        icon: AirplaneTicketIcon,
        type: "Active Zips GAMLS",
      },
      sortable: false,
      filterOperators: include,
    },
    activeZips_MIAMIRE: {
      field: "activeZips_MIAMIRE",
      headerName: "Active Zips Miami",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeZips_MIAMIRE?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeZips_MIAMIRE
                  .map((item, index) => item.postal_code)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeZips_MIAMIRE.map((item, index) => item.postal_code).join(","),
      type: "include/exclude/empty",
      props: {
        data: zipCodesListMiamire,
        icon: AirplaneTicketIcon,
        type: "Active Zips Miami",
      },
      sortable: false,
      filterOperators: include,
    },
    activeZips_CAROLINA: {
      field: "activeZips_CAROLINA",
      headerName: "Active Zips Carolina",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeZips_CAROLINA?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeZips_CAROLINA
                  .map((item, index) => item.postal_code)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeZips_CAROLINA
          .map((item, index) => item.postal_code)
          .join(","),
      type: "include/exclude/empty",
      props: {
        data: zipCodesListCarolina,
        icon: AirplaneTicketIcon,
        type: "Active Zips Carolina",
      },
      sortable: false,
      filterOperators: include,
    },
    activeCities_MIAMIRE: {
      field: "activeCities_MIAMIRE",
      headerName: "Active Cities (Miami)",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeCities_MIAMIRE?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeCities_MIAMIRE
                  .map((item, index) => item.city)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeCities_MIAMIRE.map((item, index) => item.city).join(","),
      type: "include/exclude/empty",
      props: {
        data: citiesListMiamire,
        icon: AirplaneTicketIcon,
        type: "Active Cities (Miami)",
      },
      sortable: false,
      filterOperators: include,
    },
    activeCities_Stellar: {
      field: "activeCities_Stellar",
      headerName: "Active Cities (Stellar)",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeCities_Stellar?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeCities_Stellar
                  .map((item, index) => item.city)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeCities_Stellar.map((item, index) => item.city).join(","),
      type: "include/exclude/empty",
      props: {
        data: citiesListStellar,
        icon: AirplaneTicketIcon,
        type: "Active Cities (Stellar)",
      },
      sortable: false,
      filterOperators: include,
    },
    activeCities_FMLS: {
      field: "activeCities_FMLS",
      headerName: "Active Cities (FMLS)",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeCities_FMLS?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeCities_FMLS.map((item, index) => item.city).join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeCities_FMLS.map((item, index) => item.city).join(","),
      props: {
        data: citiesListFMLS,
        icon: AirplaneTicketIcon,
        type: "Active Cities (FMLS)",
      },
      type: "include/exclude/empty",
      sortable: false,
      filterOperators: include,
    },
    activeCities_CAROLINA: {
      field: "activeCities_CAROLINA",
      headerName: "Active Cities (Carolina)",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeCities_CAROLINA?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeCities_CAROLINA
                  .map((item, index) => item.city)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeCities_CAROLINA.map((item, index) => item.city).join(","),
      props: {
        data: citiesListCAROLINA,
        icon: AirplaneTicketIcon,
        type: "Active Cities (Carolina)",
      },
      type: "include/exclude/empty",
      sortable: false,
      filterOperators: include,
    },
    activeCities_GAMLS: {
      field: "activeCities_GAMLS",
      headerName: "Active Cities (GAMLS)",
      minWidth: 40,
      renderCell: ({ row }) => {
        if (row.activeCities_GAMLS?.length) {
          return (
            <p className="font-12 font-md">
              {truncate(
                row.activeCities_GAMLS
                  .map((item, index) => item.city)
                  .join(","),
                15
              )}
            </p>
          );
        } else {
          return "--";
        }
      },
      valueGetter: ({ row }) =>
        row.activeCities_GAMLS.map((item, index) => item.city).join(","),
      props: {
        data: citiesListGAMLS,
        icon: AirplaneTicketIcon,
        type: "Active Cities (GAMLS)",
      },
      type: "include/exclude/empty",
      sortable: false,
      filterOperators: include,
    },
    totalSales_STELLAR: {
      field: "totalSales_STELLAR",
      headerName: "Total Sales (Stellar)",
      renderCell: ({ row }) => {
        return (
          <p className="font-12 font-md">{row.totalSales_STELLAR || "--"}</p>
        );
      },
      props: {
        canSort: true,
        icon: NumbersIcon,
        type: "Number",
      },
      type: "price",
      filterOperators: date_or_price,
      sortable: false,
      minWidth: 40,
    },
  };
  let defaultColsArr = Object.keys(defaultCols),
    notExist = [];
  if (defaultColsArr.length != order.length) {
    notExist = defaultColsArr.filter(function (item) {
      return !order.includes(item.split(".")[0]);
    });
  }
  let cols = order.map((col) => defaultCols[col]);
  if (notExist.length) {
    cols = cols.concat(notExist.map((col) => defaultCols[col]));
  }
  return cols;
};
