import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Checkbox,
  Menu,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Avatar,
  Grid,
  Card,
  Skeleton,
} from "@mui/material";
import TableSkeleton from "../../components/loader/tableLoader.jsx";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import moment from "moment";
import { NumberFormat } from "../../utils/numberFormat";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../components/responseAlert";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { deletePayment } from "../../redux/payments/deletePayment.js";
import ConfirmModal from "../../components/globalModal/ConfirmModal";
import { updatePaymentStatus } from "../../redux/payments/updatePaymentStatus.js";
import ApplyPayment from "../agentRoster/agentDetails/tabsContent/tabs/applyPayment.jsx";
import { getPayments, getPaymentDetail } from "../../redux/payments";
import axios from "axios";
import { toast } from "react-toastify";

function PaymentsTable(props) {
  const { classes, selectedPayments, setSelectedPayments, filteredPayments } =
    props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.Payments.payments);
  const delPayment = useSelector((state) => state.Payments.delPayment);
  const updatePayment = useSelector((state) => state.Payments.updatePayment);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [allPayments, setAllPayments] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [postedAmount, setPostedAmount] = useState(0);
  const [isLoadingTotals, setIsLoadingTotals] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);
  const forceUpdate = () => setUpdateCounter((prev) => prev + 1);

  const columns = [
    {
      field: "payment_date",
      headerName: "DATE",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.payment_date).format("L")}
          </Typography>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              background:
                row.status === "Pending"
                  ? "#F0F0F0"
                  : row.status === "Posted"
                  ? "#499256"
                  : "#f5f5f5",
              color:
                row.status === "Pending"
                  ? "#1A1A1A"
                  : row.status === "Posted"
                  ? "white"
                  : "#1A1A1A",
            }}
            className={classes.statusChip}
          >
            <span style={{ position: "relative", top: "1px" }}>
              {row.status}
            </span>
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "type",
      headerName: "TYPE",
      renderCell: ({ row }) => {
        let displayText = row.type;
        if (row.type === "Marketing Budget") {
          displayText = "MBudget";
        } else if (row.type?.startsWith("E-Payment: ")) {
          displayText = row.type?.replace("E-Payment: ", "");
        }

        return (
          <Box
            sx={{
              background:
                row.type === "Marketing Budget" ||
                row.type?.replace("E-Payment: ", "") === "MBudget"
                  ? "#D1F2EC"
                  : row.type?.replace("E-Payment: ", "") === "Zelle"
                  ? "#E0D6F2"
                  : row.type?.replace("E-Payment: ", "") === "Venmo"
                  ? "#D6EBFF"
                  : row.type?.replace("E-Payment: ", "") === "PayPal"
                  ? "#FFF5D6"
                  : row.type?.replace("E-Payment: ", "") === "Cash App"
                  ? "#E6F5E6"
                  : row.type === "Commission"
                  ? "#FFE6D6"
                  : row.type === "Check"
                  ? "#DCF7FF"
                  : row.type === "Bank Transfer"
                  ? "#FDD9ED"
                  : row.type === "Cash"
                  ? "#BFE6C0"
                  : "#f5f5f5",
              color:
                row.type?.replace("E-Payment: ", "") === "Zelle"
                  ? "#4B0082"
                  : row.type?.replace("E-Payment: ", "") === "Venmo"
                  ? "#0047AB"
                  : row.type?.replace("E-Payment: ", "") === "PayPal"
                  ? "#8B7500"
                  : row.type?.replace("E-Payment: ", "") === "Cash App"
                  ? "#006400"
                  : row.type === "Commission"
                  ? "#8B4500"
                  : row.type === "Check"
                  ? "#2F729E"
                  : row.type === "Marketing Budget" ||
                    row.type?.replace("E-Payment: ", "") === "MBudget"
                  ? "#2A7A70"
                  : row.type === "Bank Transfer"
                  ? "#9D4564"
                  : row.type === "Cash"
                  ? "#0C5C00"
                  : "#1A1A1A",
            }}
            className={classes.statusChip}
          >
            <span style={{ position: "relative", top: "1px" }}>
              {displayText}
            </span>
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "agent",
      headerName: "Agent",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: "#696969",
            }}
          >
            {row?.users_v2?.profile_images?.profile_img_thumbnail && (
              <Avatar
                src={row.users_v2.profile_images.profile_img_thumbnail}
                alt={`${row?.users_v2?.first_name} ${row?.users_v2?.last_name}`}
                sx={{ width: 24, height: 24 }}
              />
            )}
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              {`${row?.users_v2?.first_name || ""} ${
                row?.users_v2?.last_name || ""
              }`}
            </Typography>
          </Box>
        );
      },
      width: 180,
      editable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>{row?.description}</Typography>
        );
      },
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "amount",
      headerName: "Payment Amt",
      renderCell: ({ row }) => {
        return (
          <Box align="center">
            {row.amount
              ? NumberFormat({
                  number: Number(row?.amount),
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              : "$0.00"}
          </Box>
        );
      },
      alignItems: "center",
      width: 135,
      editable: false,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      sortable: false,
      align: "center",
      renderCell: ({ row }) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
            position: "relative",
            display: "fex",
          }}
        >
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedPayments((preSe) => [...preSe, row.id]);
              } else {
                setSelectedPayments((preSe) =>
                  [...preSe]?.filter((preId) => preId !== row.id)
                );
              }
            }}
            checked={selectedPayments?.includes(row.id)}
            disabled={row.status === "Posted"}
            sx={{
              color:
                row.status === "Posted" ? "rgba(0, 0, 0, 0.15)" : undefined,
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.15)",
              },
            }}
          />
          <Box sx={{ display: row?.status === "paid" ? "none" : "flex" }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setPaymentDetail(row);
                setOpenMenu(e.currentTarget);
              }}
              size="small"
            >
              <MoreVertRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      ),
    },
  ];

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the payment",
      errorType: "success",
      isOpen: true,
    });
    setOpenDelete(false);
    setPaymentDetail({});
  };

  const handleConfirm = () => {
    dispatch(
      deletePayment({
        schema: {
          payment_id: paymentDetail.id,
          amount: paymentDetail?.amount,
        },
        handleError,
        handleSuccess,
      })
    );
  };

  const handleSuccessConfirm = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the payment status",
      errorType: "success",
      isOpen: true,
    });
    setOpenConfirm(false);
    setPaymentDetail({});
  };

  const handleRevertConfirm = () => {
    dispatch(
      updatePaymentStatus({
        schema: {
          status: "Pending",
          paymentIds: [paymentDetail.id],
        },
        handleError,
        handleSuccess: handleSuccessConfirm,
      })
    );
  };

  const handleEditClick = (row) => {
    console.log("Starting edit with row:", row);
    setOpenMenu(false);

    // Create a properly structured payment detail object with what we have
    const paymentDetailData = {
      ...row,
      id: row.id,
      payment_date: row.payment_date,
      amount: row.amount,
      type: row.type,
      description: row.description,
      email_alert: row.email_alert || false,
      status: row.status,
      users_v2: row.users_v2,
    };

    console.log("Setting payment detail:", paymentDetailData);

    // Fetch the payment details using the correct endpoint
    const userId = row.users_v2?.id;

    if (userId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}api/users/v2/tabs/payment/${userId}?pageNumber=1&pageSize=25&sortBy=&sortOrder=desc`
        )
        .then((response) => {
          console.log("Payment list API response:", response.data);

          // Find the specific payment in the response
          const paymentData = response.data.data.find(
            (payment) => payment.id === row.id
          );

          if (paymentData) {
            console.log("Found payment data:", paymentData);

            // Update the payment detail with the fetched data
            setPaymentDetail((prev) => ({
              ...prev,
              ...paymentData,
            }));
            console.log("Updated payment detail with fetched data");
          } else {
            console.log("Payment not found in the response");
          }

          setOpenUpdate(true);
        })
        .catch((error) => {
          console.error("Error fetching payment details:", error);
          // If the API call fails, still open the modal with the basic data
          setOpenUpdate(true);
        });
    } else {
      console.error("User ID not available");
      setOpenUpdate(true);
    }
  };

  const calculateTotals = useCallback((payments) => {
    let tempPendingCount = 0;
    let tempPendingAmount = 0;
    let tempPostedAmount = 0;

    if (!payments || !payments.length) {
      console.log("No payments to calculate totals from");
      setPendingCount(0);
      setPendingAmount(0);
      setPostedAmount(0);
      return;
    }

    console.log(`Calculating totals from ${payments.length} payments`);

    payments.forEach((payment) => {
      const status = payment.status?.toLowerCase();
      const amount = parseFloat(payment.amount || 0);

      // Ensure we're working with valid numbers
      if (isNaN(amount)) {
        console.warn("Invalid payment amount:", payment);
        return; // Skip this payment
      }

      if (!status || status === "pending") {
        tempPendingCount++;
        tempPendingAmount += amount;
      } else if (status === "posted") {
        tempPostedAmount += amount;
      }
    });

    console.log("Payment calculations:", {
      pendingCount: tempPendingCount,
      pendingAmount: tempPendingAmount,
      postedAmount: tempPostedAmount,
      totalPayments: payments.length,
    });

    // Update all three state variables
    setPendingCount(tempPendingCount);
    setPendingAmount(tempPendingAmount);
    setPostedAmount(tempPostedAmount);
  }, []);

  const fetchAllPayments = useCallback(
    async (pageNum = 1, allItems = []) => {
      try {
        setIsLoadingTotals(true);

        // First, dispatch the action
        const action = await dispatch(
          getPayments({
            pageNumber: pageNum,
            pageSize: 100, // Request larger pages to reduce number of requests
            append: false, // Don't append in Redux store
          })
        );

        // Check if we have a valid response
        const response = action.payload;
        if (!response || !response.data) {
          console.error("Invalid response format:", response);
          setIsLoadingTotals(false);
          return allItems;
        }

        const newItems = response.data || [];
        const combinedItems = [...allItems, ...newItems];

        console.log(
          `Fetched payments page ${pageNum}, got ${newItems.length} items`
        );

        // If we got a full page, there might be more
        if (newItems.length === 100) {
          // Recursively fetch the next page
          return fetchAllPayments(pageNum + 1, combinedItems);
        } else {
          // We've got all items
          console.log(`Total payments fetched: ${combinedItems.length}`);

          // Store all payments
          setAllPayments(combinedItems);

          // Calculate totals
          let tempPendingCount = 0;
          let tempPendingAmount = 0;
          let tempPostedAmount = 0;

          combinedItems.forEach((payment) => {
            const status = payment.status?.toLowerCase();
            const amount = parseFloat(payment.amount || 0);

            if (!isNaN(amount)) {
              if (!status || status === "pending") {
                tempPendingCount++;
                tempPendingAmount += amount;
              } else if (status === "posted") {
                tempPostedAmount += amount;
              }
            }
          });

          console.log("Payment calculations:", {
            pendingCount: tempPendingCount,
            pendingAmount: tempPendingAmount,
            postedAmount: tempPostedAmount,
            totalPayments: combinedItems.length,
          });

          // Set the state values
          setPendingCount(tempPendingCount);
          setPendingAmount(tempPendingAmount);
          setPostedAmount(tempPostedAmount);

          setIsLoadingTotals(false);
          return combinedItems;
        }
      } catch (error) {
        console.error("Error fetching payments:", error);
        setIsLoadingTotals(false);
        return allItems; // Return what we have so far
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchAllPayments();
  }, [fetchAllPayments]);

  const updateDOMContainers = (pendingCount, pendingAmount, postedAmount) => {
    // Find the existing container elements by their text content or structure
    const pendingCountElement = document
      .querySelector("h4, .MuiTypography-h4")
      .textContent.match(/^\d+$/);
    const pendingAmountElement = document
      .querySelector("h4, .MuiTypography-h4")
      .textContent.match(/^\$[\d,]+\.\d{2}$/);
    const postedAmountElement = document
      .querySelector("h4, .MuiTypography-h4")
      .textContent.match(/^\$[\d,]+\.\d{2}$/);

    // Update the elements if found
    if (pendingCountElement) {
      pendingCountElement.textContent = pendingCount.toString();
    }

    if (pendingAmountElement) {
      pendingAmountElement.textContent = `$${pendingAmount.toFixed(2)}`;
    }

    if (postedAmountElement && postedAmountElement !== pendingAmountElement) {
      postedAmountElement.textContent = `$${postedAmount.toFixed(2)}`;
    }

    console.log("DOM elements updated directly:", {
      pendingCountUpdated: !!pendingCountElement,
      pendingAmountUpdated: !!pendingAmountElement,
      postedAmountUpdated: !!postedAmountElement,
    });
  };

  const handlePostPayment = async (id) => {
    try {
      setIsPosting(true);

      // Post the payment
      // await dispatch(postPayment(id));

      // Set a flag in localStorage to indicate we need to refresh
      localStorage.setItem("paymentPosted", "true");
      localStorage.setItem("paymentPostedTime", Date.now().toString());

      // Add a success message
      toast.success("Payment posted successfully. Refreshing page...");

      // Add a delay to ensure backend processing
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Reload the page
      window.location.reload();
    } catch (error) {
      console.error("Error posting payment:", error);
      setIsPosting(false);
      toast.error("Failed to post payment");
    }
  };

  useEffect(() => {
    const paymentPosted = localStorage.getItem("paymentPosted");

    if (paymentPosted === "true") {
      // Clear the flag
      localStorage.removeItem("paymentPosted");
      localStorage.removeItem("paymentPostedTime");

      // Show a message
      toast.success("Payment posted successfully!");
    }
  }, []);

  return (
    <Box className={classes.gridViewSection}>
      <Paper className={classes.tableRoot} sx={{ position: "relative" }}>
        <DataGridPro
          rows={filteredPayments}
          loading={payments.isLoading}
          columns={columns}
          disableSelectionOnClick
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          paginationMode="client"
          rowCount={filteredPayments?.length || 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[25]}
          pagination
          disableRowSelectionOnClick
        />
      </Paper>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
        {...props}
      />
      <Menu
        anchorEl={openMenu}
        id="power-search-menu"
        open={Boolean(openMenu)}
        onClose={() => {
          setOpenMenu(false);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            minWidth: "180px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <List disablePadding>
          {paymentDetail?.status === "Posted" ? (
            <ListItem
              onClick={() => {
                setOpenConfirm(true);
                setOpenMenu(false);
              }}
              dense
              disablePadding
              size="small"
            >
              <ListItemButton>
                <ListItemText
                  className={classes.menuListText}
                  primary="Revert to Pending"
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem
              onClick={() => {
                handleEditClick(paymentDetail);
                setOpenMenu(false);
              }}
              dense
              disablePadding
              size="small"
            >
              <ListItemButton>
                <ListItemText
                  className={classes.menuListText}
                  primary="Edit Payment"
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem
            onClick={() => {
              setOpenDelete(true);
              setOpenMenu(false);
            }}
            dense
            disablePadding
            size="small"
          >
            <ListItemButton>
              <ListItemText
                className={classes.menuListText}
                primary="Delete Payment"
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Confirmation"
        content="Are you sure you want to delete this payment?"
        handleConfirm={handleConfirm}
        loading={delPayment.isLoading}
      />
      <ConfirmModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        title="Revert Confirmation"
        content="Are you sure you want to Revert to Pending this payment?"
        handleConfirm={handleRevertConfirm}
        loading={updatePayment.isLoading}
      />

      {/* Right before the ApplyPayment component */}
      {console.log("PaymentsTable.jsx paymentDetail:", paymentDetail)}

      <ApplyPayment
        open={openUpdate}
        setOpen={setOpenUpdate}
        id={paymentDetail?.users_v2?.id}
        isUpdate={true}
        prePaymentDetail={paymentDetail}
        handleSuccessCall={() => {
          dispatch(getPayments({}));
          setOpenUpdate(false);
        }}
        showAttachments={true}
        fetchOwnData={true}
        paymentId={paymentDetail?.id}
      />
    </Box>
  );
}

export default PaymentsTable;
