import React, { useState, useEffect, useRef } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Stack,
  InputAdornment,
  FormControl,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnpaidInvoices,
  createPayment,
  deleteAttachment,
  updatePayment,
  getInvoices,
  getPayments,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import moment from "moment";
import { NumberFormat } from "../../../../../utils/numberFormat";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ResponseAlert from "../../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmModal from "../../../../../components/globalModal/ConfirmModal";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ViewInvoice from "./viewInvoice";
import AddIcon from "@mui/icons-material/Add";

function ApplyPayment(props) {
  const {
    classes,
    open,
    setOpen,
    id,
    isUpdate,
    prePaymentDetail,
    handleSuccessCall,
    onSuccess,
    unpaidInvoices: passedUnpaidInvoices,
    refreshPaymentsData,
  } = props;
  const dispatch = useDispatch();
  const [paymentDetail, setPaymentDetail] = useState({
    payment_date: "",
    amount: "",
    type: "",
    description: "",
    email_alert: false,
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [fileDetail, setFileDetail] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [errors, setErrors] = useState({});
  const [filesPreview, setFilesPreview] = useState([]);
  const [files, setFiles] = useState([]);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const isFirstRender = useRef(false);
  const unPaidInvoices = useSelector(
    (state) => state.agentRoster.AgentTabs.unPaidInvoices
  );
  const applyPayment = useSelector(
    (state) => state.agentRoster.AgentTabs.applyPayment
  );
  const editPayment = useSelector(
    (state) => state.agentRoster.AgentTabs.editPayment
  );
  const delAttachment = useSelector(
    (state) => state.agentRoster.AgentTabs.delAttachment
  );
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [availableInvoices, setAvailableInvoices] = useState([]);
  const initialStateSet = useRef(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setPaymentDetail({
      payment_date: "",
      amount: "",
      type: "",
      description: "",
      email_alert: false,
    });
    setSelectedInvoices([]);
    setFilesPreview([]);
    setFiles([]);
    setErrors({});
    setErrorAlert({
      errorMsg: "",
      errorType: "",
      isOpen: false,
    });
    isFirstRender.current = false;
    initialStateSet.current = false;
  };
  const handleUpdateDetail = (value, field) => {
    setPaymentDetail((preDetail) => ({
      ...preDetail,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors({ ...errors, [field]: "" });
    }
  };

  const handleSelectionChange = (selectionModel) => {
    // Filter out duplicates by invoice ID
    const uniqueSelected = availableInvoices
      .filter((row) => selectionModel.includes(row.id))
      .reduce((acc, current) => {
        const isDuplicate = acc.find((item) => item.id === current.id);
        if (!isDuplicate) {
          return [...acc, current];
        }
        return acc;
      }, []);

    setSelectedInvoices(
      uniqueSelected.map((item) => ({
        ...item,
        amount_paid: item.amount_paid || "",
      }))
    );
  };

  const handleFileChange = (files) => {
    let id = Math.random().toString(36).substring(7);
    setFiles((preFiles) => [...preFiles, { id: id, file: files[0] }]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setFilesPreview([
        ...filesPreview,
        {
          id: id,
          file: reader.result,
          url: URL.createObjectURL(files[0]),
        },
      ]);
    };
    let url = reader.readAsDataURL(files[0]);
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };
  const handleSuccessAttachment = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the attachment",
      errorType: "success",
      isOpen: true,
    });
    setFileDetail({});
    setOpenDelete(false);
    setFilesPreview(filesPreview.filter((ite) => ite.id != fileDetail.id));
  };

  const handleDelFile = (item) => {
    if (item?.file_path) {
      setFileDetail(item);
      setOpenDelete(true);
    } else {
      setFiles(files.filter((ite) => ite.id != item.id));
      setFilesPreview(filesPreview.filter((ite) => ite.id != item.id));
    }
  };

  const handleConfirm = () => {
    dispatch(
      deleteAttachment({
        attachment_id: fileDetail.id,
        payment_id: prePaymentDetail.id,
        handleError,
        handleSuccess: handleSuccessAttachment,
      })
    );
  };

  const validateFields = (fields) => {
    const errors = {};
    let isFormValid = true;

    // First validate required fields
    fields.forEach(({ key, message }) => {
      if (!paymentDetail?.[key]) {
        errors[key] = message;
        isFormValid = false;
      }
    });

    // Validate invoices and amounts
    if (selectedInvoices?.length) {
      // Calculate total of amount_paid from selected invoices
      const totalPaidAmount = selectedInvoices.reduce(
        (sum, invoice) => sum + parseFloat(invoice.amount_paid || 0),
        0
      );

      const paymentAmount = parseFloat(paymentDetail?.amount || 0);

      console.log("Validation amounts:", {
        totalPaidAmount,
        paymentAmount,
        selectedInvoices,
        paymentDetail,
      });

      // Compare with exact match
      if (totalPaidAmount === paymentAmount) {
        isFormValid = true;
        // Check attachments
        if (!files?.length && !filesPreview?.length) {
          errors["unpaid_invoice"] =
            "Please upload the invoices attachment first";
          setErrorAlert({
            errorMsg: "Please upload the invoices attachment first",
            errorType: "warning",
            isOpen: true,
          });
          isFormValid = false;
        }
      } else {
        errors["amount"] = "Invoice Payments should equal Payment Amount";
        setErrorAlert({
          errorMsg: "Invoice Payments should equal Payment Amount",
          errorType: "warning",
          isOpen: true,
        });
        isFormValid = false;
      }
    } else {
      errors["unpaid_invoice"] = "Please select unpaid invoice first";
      setErrorAlert({
        errorMsg: "Please select unpaid invoice first",
        errorType: "warning",
        isOpen: true,
      });
      isFormValid = false;
    }

    return { errors, isFormValid };
  };

  const handleValidate = () => {
    const validationRules = [
      { key: "payment_date", message: "Please enter the payment date" },
      { key: "amount", message: "Please enter the amount" },
      { key: "type", message: "Please select the type of payment" },
      { key: "description", message: "Please enter the invoice description" },
    ];

    const { errors, isFormValid } = validateFields(validationRules);
    setErrors(errors);
    return isFormValid;
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully applied the payment",
      errorType: "success",
      isOpen: true,
    });

    handleClose();

    // Call the refresh function passed from the parent component
    if (refreshPaymentsData) {
      refreshPaymentsData();
    }

    dispatch(getUnpaidInvoices({ user_id: id }));
    dispatch(getInvoices({ user_id: id }));

    if (handleSuccessCall) {
      handleSuccessCall();
    }
  };

  const handlePaymentChange = (e, rowId) => {
    const newValue = e.target.value;

    let newInvoices = [...selectedInvoices];
    let index = newInvoices?.findIndex((item) => item.id === rowId);

    if (index !== -1) {
      newInvoices[index] = {
        ...newInvoices[index],
        amount_paid: newValue,
      };

      setSelectedInvoices(newInvoices);
    }
  };

  const handleSubmitFormData = () => {
    const Data = new FormData();
    Data.append("user_id", id);
    Data.append("payment_date", moment(paymentDetail?.payment_date).format());
    Data.append("amount", paymentDetail?.amount);
    Data.append("type", paymentDetail?.type);
    Data.append("description", paymentDetail?.description);
    Data.append("email_alert", paymentDetail?.email_alert);

    if (selectedInvoices?.length) {
      selectedInvoices?.forEach((item, index) => {
        Data.append(`invoices[${index}][invoice_id]`, item?.id);
        Data.append(`invoices[${index}][amount_due]`, item?.amount_due || 0);
        Data.append(`invoices[${index}][amount_paid]`, item.amount_paid || 0);
        Data.append(`invoices[${index}][is_new]`, true);
      });
    }

    files.map((item) => {
      Data.append("attachments[]", item.file);
    });

    dispatch(
      createPayment({
        formData: Data,
        handleSuccess,
        handleError,
        selectedInvoices: selectedInvoices?.map((item) => item.id),
      })
    );
  };

  const handleUpdateFormData = () => {
    const Data = new FormData();
    Data.append("payment_date", moment(paymentDetail?.payment_date).format());
    Data.append("amount", paymentDetail?.amount);
    Data.append("type", paymentDetail?.type);
    Data.append("description", paymentDetail?.description);
    Data.append("email_alert", paymentDetail?.email_alert);

    // Map of invoice IDs to their original payment_invoice records
    const existingPaymentInvoices = new Map(
      prePaymentDetail?.payment_invoices?.map((pi) => [
        pi.user_tab_invoice.id,
        pi,
      ]) || []
    );

    selectedInvoices.forEach((invoice, index) => {
      const existingPaymentInvoice = existingPaymentInvoices.get(invoice.id);

      Data.append(`invoices[${index}][invoice_id]`, invoice.id);
      Data.append(`invoices[${index}][amount_due]`, invoice.amount_due || 0);
      Data.append(`invoices[${index}][amount_paid]`, invoice.amount_paid || 0);

      if (existingPaymentInvoice) {
        // Update existing payment_invoice
        Data.append(`invoices[${index}][id]`, existingPaymentInvoice.id);
        Data.append(`invoices[${index}][is_new]`, false);
      } else {
        // Create new payment_invoice
        Data.append(`invoices[${index}][is_new]`, true);
      }
    });

    files.forEach((item) => {
      Data.append("attachments[]", item.file);
    });

    dispatch(
      updatePayment({
        payment_id: prePaymentDetail?.id,
        formData: Data,
        handleSuccess: handleSuccess,
        handleError,
      })
    );
  };

  const handleApplyPayment = () => {
    if (handleValidate()) {
      handleSubmitFormData();
    }
  };
  const handleUpdatePayment = () => {
    if (handleValidate()) {
      handleUpdateFormData();
    }
  };

  useEffect(() => {
    // Use passed invoices if they exist, otherwise use Redux store data
    const invoicesToUse = passedUnpaidInvoices || unPaidInvoices?.data || [];

    let combinedInvoices = [...invoicesToUse];

    if (isUpdate && prePaymentDetail?.payment_invoices) {
      // Handle all payment invoices
      prePaymentDetail.payment_invoices.forEach((paymentInvoice) => {
        const invoice = paymentInvoice.user_tab_invoice;
        // Find and update the existing invoice or add it if it doesn't exist
        const existingIndex = combinedInvoices.findIndex(
          (inv) => inv.id === invoice.id
        );
        if (existingIndex !== -1) {
          combinedInvoices[existingIndex] = {
            ...combinedInvoices[existingIndex],
            amount_paid: paymentInvoice.amount_paid,
            is_original_payment: true,
          };
        } else {
          combinedInvoices.push({
            ...invoice,
            amount_paid: paymentInvoice.amount_paid,
            is_original_payment: true,
          });
        }
      });
    }

    setAvailableInvoices(combinedInvoices);

    // Set selected invoices for existing payment
    if (isUpdate && prePaymentDetail?.payment_invoices?.length) {
      const selectedInvoices = prePaymentDetail.payment_invoices
        .map((paymentInvoice) => {
          const matchingInvoice = combinedInvoices.find(
            (invoice) => invoice.id === paymentInvoice.user_tab_invoice.id
          );
          if (matchingInvoice) {
            return {
              ...matchingInvoice,
              amount_paid: paymentInvoice.amount_paid,
            };
          }
          return null;
        })
        .filter(Boolean); // Remove any null values

      // Only set initial state if we haven't set it before
      if (selectedInvoices.length && !initialStateSet.current) {
        setSelectedInvoices(selectedInvoices);
        initialStateSet.current = true; // Mark that we've set the initial state
      }
    }
  }, [passedUnpaidInvoices, unPaidInvoices?.data, isUpdate, prePaymentDetail]);

  // Keep the original fetch effect
  useEffect(() => {
    if (open && id && !passedUnpaidInvoices) {
      // Only fetch if we don't have passed invoices
      dispatch(getUnpaidInvoices({ user_id: id }));
    }
  }, [open, id, passedUnpaidInvoices]);

  useEffect(() => {
    if (prePaymentDetail?.id && open) {
      setPaymentDetail({
        payment_date: moment(prePaymentDetail?.payment_date).format(
          "YYYY-MM-DD"
        ),
        amount: prePaymentDetail?.amount,
        type: prePaymentDetail?.type,
        description: prePaymentDetail?.description,
        email_alert: prePaymentDetail?.email_alert || false,
      });
      if (prePaymentDetail?.payment_attachments?.length) {
        setFilesPreview(prePaymentDetail?.payment_attachments);
      }
      if (prePaymentDetail?.payment_invoices?.length) {
        setSelectedInvoices(
          prePaymentDetail?.payment_invoices?.map((item) => ({
            ...item.user_tab_invoice,
            amount_paid: item.amount_paid,
            id: item.user_tab_invoice.id, // Ensure we're using the correct ID
            is_new: false,
          }))
        );
      }
    }
  }, [prePaymentDetail?.id, open]);

  const columns = [
    {
      field: "invoice_date",
      headerName: "DATE",
      width: 120,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.invoice_date).format("L")}
          </Typography>
        );
      },
    },
    {
      field: "invoice_number",
      headerName: "INVOICE #",
      width: 130,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ fontSize: "14px" }}>
              {row.invoice_number}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedInvoice({
                  invoice_number: row.invoice_number,
                  invoice_file_path: row.pdf_path || row.pdf_url,
                });
                setOpenInvoiceModal(true);
              }}
              sx={{
                padding: "2px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <DescriptionOutlinedIcon
                sx={{ fontSize: 16, color: "rgba(0, 0, 0, 0.6)" }}
              />
            </IconButton>
          </Box>
        );
      },
    },
    { field: "category", headerName: "CATEGORY", minWidth: 120, flex: 1 },
    {
      field: "amount_due",
      headerName: "AMT DUE",
      width: 90,
      renderCell: ({ row }) => {
        // Calculate total payments using only valid, non-deleted payments
        const totalPayments =
          row.payments?.reduce((sum, paymentRow) => {
            if (paymentRow?.payment && !paymentRow?.payment?.deleted_at) {
              return sum + Number(paymentRow?.amount_paid || 0);
            }
            return sum;
          }, 0) || 0;

        // Calculate amount due
        const amountDue = Number(row?.amount_due || 0) - totalPayments;

        return (
          <Box align="center">
            {amountDue === 0
              ? "$0.00"
              : NumberFormat({
                  number: amountDue,
                  maximumFractionDigits: 2,
                  currency: "USD",
                })}
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerName: "Payment",
      sortable: false,
      width: 150,
      renderCell: ({ row }) => {
        let isSelected = selectedInvoices?.find((item) => item.id === row.id);

        return (
          <Box
            onClick={(e) => e.stopPropagation()}
            align="center"
            sx={{ paddingRight: "10px" }}
          >
            {isSelected ? (
              <TextField
                fullWidth
                size="small"
                disabled={false}
                value={isSelected?.amount_paid || ""}
                onChange={(e) => handlePaymentChange(e, row.id)}
                onClick={(e) => e.stopPropagation()}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: isSelected?.amount_paid ? (
                    <InputAdornment position="start">$</InputAdornment>
                  ) : null,
                }}
                sx={{
                  borderRadius: "18px",
                  "& fieldset": { border: "none" },
                  backgroundColor: "white",
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                type="number"
              />
            ) : (
              <TextField
                fullWidth
                size="small"
                disabled
                value=""
                sx={{
                  borderRadius: "18px",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#f4f4f4",
                }}
              />
            )}
          </Box>
        );
      },
    },
  ];

  const isFormReadyForInvoices = () => {
    const isReady = !!(
      paymentDetail.payment_date &&
      paymentDetail.amount &&
      paymentDetail.type &&
      paymentDetail.description
    );
    return isReady;
  };

  return (
    <>
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiPaper-root": { overflow: "hidden" },
        }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            {isUpdate ? "EDIT " : "APPLY "}
            PAYMENT
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px 40px" }}>
          <Grid container spacing={3} sx={{ mt: "10px" }}>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Date of Payment
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                type="date"
                value={paymentDetail.payment_date}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "payment_date")
                }
                error={errors.payment_date}
                helperText={errors.payment_date}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Payment Amount
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={paymentDetail.amount}
                onChange={(e) => handleUpdateDetail(e.target.value, "amount")}
                error={errors.amount}
                helperText={errors.amount}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Payment Type
              </InputLabel>
              <Select
                fullWidth
                size="small"
                value={paymentDetail.type}
                onChange={(e) => handleUpdateDetail(e.target.value, "type")}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Payment Method</em>;
                  }
                  return selected;
                }}
              >
                <MenuItem value="Check">Check</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Commission">Commission</MenuItem>
                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                <MenuItem value="E-Payment: Zelle">E-Payment: Zelle</MenuItem>
                <MenuItem value="E-Payment: Venmo">E-Payment: Venmo</MenuItem>
                <MenuItem value="E-Payment: PayPal">E-Payment: PayPal</MenuItem>
                <MenuItem value="E-Payment: Cash App">
                  E-Payment: Cash App
                </MenuItem>
                <MenuItem value="Marketing Budget">Marketing Budget</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.InputLabel}>
                Payment Description
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={paymentDetail.description}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "description")
                }
                error={errors.description}
                helperText={errors.description}
              />
            </Grid>
          </Grid>
          <Box
            sx={{ display: handleSuccessCall && !isUpdate ? "none" : "" }}
            className={classes.unpaidInvoices}
          >
            {" "}
            <InputLabel className={classes.InputLabel2}>
              Select Unpaid Invoices to Apply Payment
            </InputLabel>
            <Box
              className={classes.invoiceTable}
              sx={{
                maxHeight: "300px", // Set a fixed height
                overflowY: "auto", // Enable vertical scrolling
              }}
            >
              <Box className={classes.tableRoot}>
                <DataGrid
                  rows={availableInvoices}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  rowSelectionModel={selectedInvoices.map((inv) => inv.id)}
                  onRowSelectionModelChange={(newSelectionModel) => {
                    const uniqueSelected = availableInvoices
                      .filter((row) => newSelectionModel.includes(row.id))
                      .reduce((acc, current) => {
                        const isDuplicate = acc.find(
                          (item) => item.id === current.id
                        );
                        if (!isDuplicate) {
                          return [...acc, current];
                        }
                        return acc;
                      }, []);

                    setSelectedInvoices(
                      uniqueSelected.map((item) => ({
                        ...item,
                        amount_paid: "",
                      }))
                    );
                  }}
                  isRowSelectable={(params) => isFormReadyForInvoices()}
                  sx={{
                    border: 0,
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#f4f4f4",
                      borderRadius: "20px",
                    },
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                      transform: "none !important",
                      width: "100% !important",
                      margin: "0 !important",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    },
                    "& .MuiDataGrid-row": {
                      borderRadius: "20px",
                      backgroundColor: "white",
                      margin: "2px 0",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    },
                    "& .MuiDataGrid-cell": {
                      padding: "0 8px",
                    },
                    "& .MuiCheckbox-root": {
                      color: isFormReadyForInvoices() ? "#757575" : "#bdbdbd",
                    },
                    "& .MuiCheckbox-root.Mui-checked": {
                      color: "#757575",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#F3FFF5 !important",
                    },
                    "& .Mui-selected:hover": {
                      backgroundColor: "#daf2de !important",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: handleSuccessCall && !isUpdate ? "none" : "" }}
            className={classes.attachmentSection}
          >
            <InputLabel className={classes.InputLabel2}>
              Add Attachment
            </InputLabel>
            <Box className={classes.attachments}>
              <Dropzone
                onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
                noClick={filesPreview.length > 0} // Disable click when files exist
                noKeyboard={filesPreview.length > 0} // Disable keyboard when files exist
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                  open,
                }) => {
                  // Combined drag state - true when any drag operation is happening
                  const isAnyDragActive =
                    isDragActive || isDragAccept || isDragReject;

                  // Create a modified version of getRootProps that overrides the style
                  const rootProps = getRootProps();

                  return (
                    <Box
                      {...rootProps}
                      className={classes.dropzoneSection}
                      sx={{
                        // Base styles - completely transparent by default
                        border: "2px dashed transparent",
                        backgroundColor: "transparent", // Always transparent background
                        height: "auto",
                        minHeight: "50px",
                        transition: "all 0.3s ease",
                        padding: "16px", // Add padding for content
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start", // Left-align all content
                        justifyContent: "center", // Center vertically
                        position: "relative", // Add position relative for absolute positioning

                        // Show styles when no files uploaded
                        ...(filesPreview.length === 0 && {
                          border: "2px dashed #757575",
                          height: "150px",
                          minHeight: "150px",
                          alignItems: "center", // Center horizontally when no files
                        }),

                        // Only show border when actively dragging a file over the area
                        ...(isAnyDragActive &&
                          filesPreview.length > 0 && {
                            border: "2px dashed #757575",
                          }),

                        // Don't show border on regular hover if files exist
                        '&[data-ishover="true"]':
                          filesPreview.length === 0
                            ? {
                                border: "2px dashed #757575",
                              }
                            : {},
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.setAttribute("data-ishover", "true");
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.setAttribute("data-ishover", "false");
                      }}
                      data-ishover="false"
                    >
                      <input {...getInputProps()} />

                      {/* Drag text overlay - only visible when dragging */}
                      {filesPreview.length > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
                            opacity: isAnyDragActive ? 1 : 0, // Only visible when dragging
                            transition: "opacity 0.3s ease",
                            pointerEvents: "none", // Don't interfere with mouse events
                            zIndex: 10, // Ensure it's above the content
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#000",
                              fontWeight: 500,
                            }}
                          >
                            Drag & Drop Your File
                          </Typography>
                        </div>
                      )}

                      {/* Text for initial state - only shown when no files */}
                      {filesPreview.length === 0 && (
                        <div
                          style={{
                            alignSelf: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "inherit",
                              transition: "color 0.3s ease",
                              textAlign: "center",
                            }}
                          >
                            Click to Upload or Drag & Drop Your File
                          </Typography>
                        </div>
                      )}

                      {/* File previews with padding for vertical centering */}
                      {filesPreview.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "16px",
                            width: "100%",
                            justifyContent: "flex-start",
                            alignItems: "center", // Keep center alignment
                            alignSelf: "flex-start", // Change to flex-start
                            position: "relative",
                            zIndex: 5,
                            paddingTop: "30px", // Add top padding
                            paddingBottom: "16px", // Add bottom padding
                          }}
                        >
                          {filesPreview.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                marginBottom: "5px",
                              }}
                            >
                              {item.file.slice(17, 20) === "pdf" ? (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedAttachment(item);
                                    setOpenAttachmentModal(true);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    display: "block",
                                  }}
                                >
                                  <iframe
                                    style={{ pointerEvents: "none" }}
                                    width="120"
                                    height="120"
                                    src={item.file_path || item.file}
                                  >
                                    {" "}
                                  </iframe>
                                </div>
                              ) : (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedAttachment(item);
                                    setOpenAttachmentModal(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                      objectFit: "cover",
                                    }}
                                    src={item.file_path || item.file}
                                    alt={`expense document ${index}`}
                                  />
                                </div>
                              )}

                              {/* Remove button with white background */}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-10px",
                                  right: "-10px",
                                  backgroundColor: "white",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelFile(item);
                                }}
                              >
                                <HighlightOffRoundedIcon
                                  style={{
                                    color: "#757575",
                                  }}
                                />
                              </div>
                            </div>
                          ))}

                          {/* Add button */}
                          <div
                            style={{
                              width: "60px",
                              height: "120px",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              marginLeft: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                open();
                              }}
                              style={{
                                width: "24px",
                                height: "24px",
                                backgroundColor: "black",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                              }}
                            >
                              <AddIcon
                                style={{ color: "white", fontSize: "16px" }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Box>
                  );
                }}
              </Dropzone>
            </Box>
          </Box>

          <Box sx={{ mt: "24px" }}>
            <InputLabel className={classes.InputLabel}>
              Schedule Email Alerts to Agent
            </InputLabel>
            <Box className={classes.selectionBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={paymentDetail?.email_alert}
                    onChange={(e) =>
                      handleUpdateDetail(e.target.checked, "email_alert")
                    }
                  />
                }
                label="Send Payment Alert"
                color="inherit"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {" "}
          <Box
            className={classes.buttonSection}
            sx={{ padding: "0 40px 20px" }}
          >
            {isUpdate ? (
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                onClick={handleUpdatePayment}
                loadingPosition="start"
                loading={editPayment.isLoading}
                disabled={editPayment.isLoading}
              >
                Update Payment
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                onClick={handleApplyPayment}
                loadingPosition="start"
                loading={applyPayment.isLoading}
                disabled={applyPayment.isLoading}
              >
                Apply payment
              </LoadingButton>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Confirmation"
        content="Are you sure you want to delete this attachment?"
        handleConfirm={handleConfirm}
        loading={delAttachment.isLoading}
      />
      <ViewInvoice
        open={openInvoiceModal}
        setOpen={setOpenInvoiceModal}
        pdfUrl={
          selectedInvoice?.invoice_file_path || selectedInvoice?.invoice_file
        }
        invoiceNumber={selectedInvoice?.invoice_number}
      />
      <Dialog
        open={openAttachmentModal}
        onClose={() => setOpenAttachmentModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">View Attachment</Typography>
            <IconButton onClick={() => setOpenAttachmentModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedAttachment && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="400px"
            >
              {selectedAttachment.file.slice(17, 20) === "pdf" ? (
                <iframe
                  src={selectedAttachment.file_path || selectedAttachment.file}
                  width="100%"
                  height="600px"
                  style={{ border: "none" }}
                  title="PDF Viewer"
                />
              ) : (
                <img
                  src={selectedAttachment.file_path || selectedAttachment.file}
                  alt="Attachment"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "600px",
                    objectFit: "contain",
                  }}
                />
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
export default withStyles(styleSheet, { name: "ApplyPaymentStyle" })(
  ApplyPayment
);
