import React, { useState, useEffect, useRef } from "react";
import { TextField, Autocomplete, Box } from "@mui/material";
import { getData } from "../../../../utils/getData";
import axios from "axios";

const getAddress = (data, flag) => {
  if (data.source === "solr" || flag) {
    return `${data?.StreetNumber || ""} ${data?.StreetName || ""} ${
      data?.StateOrProvince || ""
    } ${data?.City || ""}`;
  } else if (data.source === "google") {
    return data.description; // Use Google Maps suggestion description
  }
  return "";
};

function SearchProperties(props) {
  let {
    handleUpdateData,
    agentData,
    transactionDetail,
    reset2Step2,
    selectedProperty,
    setSelectedProperty,
    errors,
  } = props;
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTimeout = useRef(null);

  const handleFilterOptions = (options, search, flag) => {
    return options.filter((option) => {
      const address = getAddress(option, flag).toLowerCase();
      const parts = address.split(" "); // Split address into words

      // Check if any part starts with the search string (starts with match)
      const startsWithMatch = parts.some((part) => part.startsWith(search));

      // Check if the search string exists anywhere in the address (partial match)
      const partialMatch = address.includes(search);
      const starting = address.startsWith(search.toLowerCase());
      // Return true if either of the conditions is satisfied
      return startsWithMatch || partialMatch || starting;
    });
  };

  const parseSearchQuery = (input) => {
    const trimmedInput = input.trim();

    // Check if input contains ONLY digits and has exactly 7 or more digits → Listing ID
    if (/^\d{7,}$/.test(trimmedInput)) {
      return `ListingId:${trimmedInput}`;
    }

    // Check if input starts with 1 to 6 digits followed by a space and a string → Full Street Address
    const match = trimmedInput.match(/^(\d{1,6})\s+(.+)$/);
    if (match) {
      return `StreetNumber:${match[1]} OR StreetName:${match[2]}`;
    }

    // NEW: If input is ONLY a number (1-6 digits) → Treat as Street Number
    if (/^\d{1,6}$/.test(trimmedInput)) {
      return `StreetNumber:${trimmedInput}`;
    }

    // If it doesn't match any expected format, return as unknown
    return `StreetName:${trimmedInput}`;
  };

  const handleSearch = async (value) => {
    setLoading(true);
    try {
      // Step 1: Fetch results from Solr API
      let url = `${process.env.REACT_APP_BASE_URL}api/common/solr/${
        agentData?.market?.datasets[0]
      }_Property/query?q=${parseSearchQuery(
        value
      )}&sort=ModificationTimestamp asc&rows=50`;

      const result = await getData(url);

      if (result?.response.docs?.length) {
        // Add a `source` key to differentiate between Solr and Google results
        const solrResults = result.response.docs.map((doc) => ({
          ...doc,
          source: "solr",
        }));
        setOptions(solrResults);
      } else {
        // Step 2: Fetch suggestions from Google Maps API as fallback
        const googleResults = await fetchGoogleSuggestions(value);
        if (googleResults.length) {
          setOptions(googleResults);
        }
      }
    } catch (error) {
      const googleResults = await fetchGoogleSuggestions(value);
      if (googleResults.length) {
        setOptions(googleResults);
      }
      // toast.error("Error fetching data", { theme: "colored" });
    } finally {
      setLoading(false);
    }
  };

  const fetchGoogleSuggestions = async (input) => {
    const googleApiKey = "AIzaSyDDyzxuxXFRmkI7ttiKguogSIh8sJmDb9g";
    const googleUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=${googleApiKey}&components=country:us`;

    try {
      const response = await axios.get(googleUrl);
      if (response.data.status === "OK") {
        return response.data.predictions.map((place) => ({
          ...place,
          source: "google", // Identify these results as from Google
        }));
      }
      return [];
    } catch (error) {
      console.error("Error fetching Google Maps suggestions:", error);
      return [];
    }
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (searchTerm) {
        handleSearch(searchTerm);
      }
    }, 1000);

    return () => clearTimeout(debounceTimeout.current);
  }, [searchTerm]);

  return (
    <Box>
      <Autocomplete
        options={options}
        fullWidth
        loading={loading}
        value={selectedProperty && searchTerm ? selectedProperty : null}
        onInputChange={(event, value, reason) => {
          if (searchTerm !== value && reason === "input") {
            setSearchTerm(value);
          } else if (reason === "clear") {
            setSelectedProperty(null); // Handle clear event
            reset2Step2();
          }
        }}
        disabled={!agentData?.id}
        onChange={(e, value) => {
          if (value) {
            setSelectedProperty(value);
            handleUpdateData(
              {
                name: getAddress(value, true),
                address: getAddress(value, true),
                street: value?.StreetName,
                city: value?.City,
                zip_code: value?.PostalCode,
                state: value?.StateOrProvince,
                tax_id: value?.ParagonTaxID,
                mls_listing_id: value?.ListingId,
              },
              value?.StreetNumber,
              value
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size="small"
            placeholder="Start typing address or MLS number here..."
            error={errors?.address}
            helperText={errors?.address}
          />
        )}
        filterOptions={(options, { inputValue }) => {
          return handleFilterOptions(options, inputValue.toLowerCase());
        }}
        getOptionLabel={(option) => getAddress(option)}
        freeSolo
      />
    </Box>
  );
}

export default SearchProperties;
