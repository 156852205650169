export const styleSheet = () => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
  },
  modalHeading: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: "black !important",
  },
  stepsDisplay: {
    width: "160px !important",
    textAlign: "center !important",
    padding: "6px !important",
  },
  stepsSection: {
    borderBottom: "3px solid black !important",
  },
  stepsDisplayContent: {
    fontSize: "13px  !important",
    fontWeight: "500 !important",
  },
  inputLabel: {
    fontSize: "14px  !important",
    fontWeight: "500 !important",
    color: "black !important",
  },
  inputLabel2: {
    fontSize: "13px  !important",
    fontWeight: "500 !important",
    color: "#787877 !important",
  },
  folderForm: {
    "& .MuiTypography-root": {
      fontSize: "14px !important",
      color: "black !important",
      fontWeight: "500 !important",
    },
  },
  radioLabel: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "6px !important",
  },
  nextStep: {
    backgroundColor: "black !important",
    color: "white !important",
    textTransform: "capitalize !important",
    padding: "6px 24px !important",
  },
  backButton: {
    textTransform: "capitalize !important",
    padding: "6px 24px !important",
  },
  formTabs: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center",
    width: "100%",
  },
  forTabsItem: {
    width: "100px",
    height: "50px",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    fontSize: "12px !important",
    border: "1px solid #f1f1f0",
    outlineTop: "1px solid #f1f1f0",
    backgroundColor: "white",
    cursor: "pointer !important",
    fontWeight: "500",
    textAlign: "center",
    lineHeight: "13px",
    "&:hover": {
      backgroundColor: "#f9f9f8",
    },
  },
  formSection: {
    borderRadius: "10px !important",
    overflow: "hidden",
  },
  formTabDetail: {
    borderLeft: "1px solid #f1f1f0",
    borderRight: "1px solid #f1f1f0",
    borderBottom: "1px solid #f1f1f0",
    backgroundColor: "#f9f9f8",
    padding: "12px !important",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    fontSize: "13px",
  },
  selectedFormsSection: {
    marginTop: "16px !important",
  },
  selectedForms: {
    backgroundColor: "#f9f9f8",
    border: "1px solid #f1f1f0",
    padding: "16px !important",
  },
  uploadButton: {
    textTransform: "capitalize !important",
    fontSize: "12px !important",
    backgroundColor: "#225ccf !important",
    color: "white !important",
  },
  tabsRightSection: {
    display: "flex !important",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "50px",
    flex: 1,
    borderBottom: "1px solid #f1f1f0",
  },
  filterForms: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    marginTop: "3.5px !important",
  },
  filterFormsRadios: {
    marginTop: "-6px !important",
    "& .MuiTypography-root": {
      fontSize: "13px !important",
    },
  },
  mAccordianBorder: {
    border: "0 !important",
    backgroundColor: "#f9f9f8 !important",
    padding: "10px !important",
  },
  folderItem: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  folderItemContent: {
    fontSize: "13px !important",
    color: "black !important",
  },
  skeltonRow: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  folderItemBox: {
    width: "17px !important",
    height: "17px !important",
    border: "1px solid #dfdedf !important",
    backgroundColor: "#f2eff1 !important",
    borderRadius: "3px ",
  },
  selectedFormsHeading: {
    fontSize: "14px !important",
    marginBottom: "8px !important",
    fontWeight: "500 !important",
  },
  selectedFormsItem: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
  },
  selectedFormsItemTitle: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
  },
});
