import React, { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  Typography,
  Skeleton,
  Grid,
  Paper,
  MenuItem,
  Button,
  Select,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NumberFormat } from "../../../../../utils/numberFormat";
import { useSelector, useDispatch } from "react-redux";
import { DataGridPro, GridRow } from "@mui/x-data-grid-pro";
import moment from "moment";
import ResponseAlert from "../../../../../components/responseAlert";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import TableSkeleton from "../../../../../components/loader/tableLoader.jsx";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import OutsideClickHandler from "react-outside-click-handler";
import {
  deleteAwardBudget,
  getMarketingBudget,
  getInvoices,
  // getPaymentDetail,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import AwardBudget from "./awardBudget.jsx";
import ConfirmModal from "../../../../../components/globalModal/ConfirmModal";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { format } from 'date-fns';
import { createPortal } from 'react-dom';
import ViewInvoice from './viewInvoice';

function MarketingBudget(props) {
  const { classes, open, setOpen, id } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [awardDetail, setAwardDetail] = useState({});
  const [openAward, setOpenAward] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [allBudgetItems, setAllBudgetItems] = useState([]);
  const [allMarketingBudgetRecords, setAllMarketingBudgetRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalBudgetAmount, setTotalBudgetAmount] = useState(0);
  const [totalSpentAmount, setTotalSpentAmount] = useState(0);
  const [totalRemainingAmount, setTotalRemainingAmount] = useState(0);
  const [marketingBudgetData, setMarketingBudgetData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [selectedPdfPath, setSelectedPdfPath] = useState(null);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState(null);
  const [totalAwarded, setTotalAwarded] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const marketingBudget = useSelector((state) => {
    const budgets = state.agentRoster.AgentTabs.marketingBudget;
    return {
      ...budgets,
      data: Array.isArray(budgets?.data)
        ? budgets.data.filter(budget =>
            Number(budget.amount) > 0 || // Keep all awards (positive amounts)
            (Number(budget.amount) < 0 && budget.invoice_numbers !== null) // Only keep payments with invoice numbers
          )
        : [],
      hasMore: budgets?.count > (budgets?.data?.length || 0) // Add hasMore property
    };
  });
  const invoices = useSelector((state) => state.agentRoster.AgentTabs.invoices);
  const delAward = useSelector((state) => state.agentRoster.AgentTabs.delAward);

  const handleToggleExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    
    try {
      // Check if the date string is valid
      const timestamp = Date.parse(dateString);
      if (isNaN(timestamp)) {
        console.warn('Invalid date string:', dateString);
        return '';
      }
      
      const date = new Date(timestamp);
      
      // Format as MM/DD/YYYY
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      
      return `${month}/${day}/${year}`;
    } catch (error) {
      console.error('Error formatting date:', error, 'for date string:', dateString);
      return '';
    }
  };

  const columns = [
    {
      field: "date",
      headerName: "DATE",
      headerAlign: "left",
      renderCell: ({ row }) => {
        // Use marketing_budget_date if available, fall back to date
        const dateToUse = row.marketing_budget_date || row.date;
        return (
          <Typography sx={{ fontSize: "14px", transform: "translateY(4px)" }}>
            {formatDateString(dateToUse)}
          </Typography>
        );
      },
      minWidth: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "type",
      headerName: "TYPE",
      headerAlign: "left",
      renderCell: ({ row }) => {
        const isAward = Number(row.amount) > 0;
        const displayText = isAward ? "Award" : "Payment";
        
        const backgroundColor = isAward ? "#cdf9d7" : "#E6E6E6";
        const color = isAward ? "#005213" : "#000000";

        return (
          <Box
            sx={{
              background: backgroundColor,
              color: color,
              padding: "3px 10px",
              borderRadius: "8px",
              fontSize: "13px",
              fontWeight: 400,
              textAlign: "center",
              display: "inline-block",
              width: "auto",
              minWidth: "unset",
              whiteSpace: "nowrap",
              transform: "translateY(4px)"
            }}
          >
            {displayText}
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      headerAlign: "left",
      renderCell: ({ row }) => {
        // Check if this is a payment (negative amount)
        const isPayment = Number(row.amount) < 0;
        
        let displayText = row?.description || "";
        
        // If it's a payment, try to get invoice numbers from different possible properties
        if (isPayment) {
          // Try different possible property names for invoice numbers
          const invoiceNumbers = row.invoice_numbers || row.invoiceNumbers || row.invoice_ids || row.invoices;
          
          if (invoiceNumbers) {
            // Handle different possible formats of invoice numbers
            let formattedInvoices = "";
            
            if (Array.isArray(invoiceNumbers)) {
              formattedInvoices = invoiceNumbers.join(', ');
            } else if (typeof invoiceNumbers === 'string') {
              formattedInvoices = invoiceNumbers;
            } else if (typeof invoiceNumbers === 'object') {
              // If it's an object, try to extract values
              formattedInvoices = Object.values(invoiceNumbers).join(', ');
            }
            
            if (formattedInvoices) {
              // Check if the description already contains "Invoice Payment:"
              if (displayText.includes("Invoice Payment:")) {
                // Replace everything after "Invoice Payment:" with our formatted invoice numbers
                displayText = `Invoice Payment: ${formattedInvoices}`;
              } else {
                displayText = `${displayText} ${formattedInvoices}`;
              }
            }
          }
        }

        
        return (
          <Typography sx={{ fontSize: "14px", transform: "translateY(4px)" }}>
            {displayText}
          </Typography>
        );
      },
      minWidth: 225,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      headerAlign: "left",
      renderCell: ({ row }) => {
        return (
          <Box
            align="center"
            sx={{ 
              color: Number(row?.amount) > 0 ? "#00B82D" : "#F85454",
              transform: "translateY(4px)"
            }}
          >
            {row.amount
              ? NumberFormat({
                  number: Number(row?.amount),
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              : "$0.00"}
          </Box>
        );
      },
      alignItems: "center",
      width: 145,
      editable: false,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "",
      headerAlign: "left",
      renderCell: ({ row }) => {
        return (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            transform: "translateY(4px)",
          }}>
            {row.type === "Payment" && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleExpand(row.id);
                }}
                size="small"
                sx={{ 
                  marginLeft: '10px',
                  marginRight: '0px',
                  marginTop: '0px',
                  marginBottom: '6px'
                }}
              >
                {expandedRows[row.id] ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </IconButton>
            )}
            
            {row.type === "Award" ? (
              <IconButton
                data-row-id={row.id}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMenu(row.id);
                }}
                sx={{ 
                  marginLeft: '10px',
                  marginRight: '0px',
                  marginTop: '0px',
                  marginBottom: '6px',
                  padding: '4px', // Reduce padding to make the hover circle smaller
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: '50%'
                  }
                }}
              >
                <MoreVertRoundedIcon />
              </IconButton>
            ) : null}
          </Box>
        );
      },
      width: 80,
      editable: false,
      sortable: false,
    },
  ];

  const currentPageData = useMemo(() => {
    // Make sure we have data
    if (!allMarketingBudgetRecords || allMarketingBudgetRecords.length === 0) {
      return [];
    }
    
    // Create a sorted copy of the data
    const sortedData = [...allMarketingBudgetRecords].sort((a, b) => {
      const dateA = new Date(a.date || '1970-01-01').getTime();
      const dateB = new Date(b.date || '1970-01-01').getTime();
      return dateB - dateA; // Descending order (newest first)
    });
    
    // Then paginate
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return sortedData.slice(startIndex, endIndex);
  }, [allMarketingBudgetRecords, page, pageSize]);

  const handleSuccess = () => {
    setOpenDelete(false);
    setAwardDetail({});
    setErrorAlert({
      errorMsg: "You have successfully deleted the award budget",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleConfirm = () => {
    dispatch(
      deleteAwardBudget({
        marketing_budget_id: awardDetail?.id,
        amount: awardDetail.amount,
        handleError,
        handleSuccess,
      })
    );
  };

  const fetchAllMarketingBudgetRecords = useCallback(async () => {
    try {
      setIsLoading(true);
      
      // Use a larger page size to reduce number of API calls
      const fetchPage = async (pageNum = 1, accumulated = []) => {
        // Remove the sorting parameters to ensure we get data
        const action = await dispatch(getMarketingBudget({ 
          user_id: id,
          pageNumber: pageNum,
          pageSize: 100 // Larger page size
        }));
        
        if (!action.payload || !action.payload.data) {
          console.error("Invalid response format:", action.payload);
          return accumulated;
        }
        
        const newItems = action.payload.data || [];
        const combined = [...accumulated, ...newItems];
        
        // If we got a full page, fetch the next page
        if (newItems.length === 100) {
          return fetchPage(pageNum + 1, combined);
        }
        
        return combined;
      };
      
      // Start the fetching process
      let allRecords = await fetchPage();
      
      // Update state with all records
      setAllMarketingBudgetRecords(allRecords);
      setTotalCount(allRecords.length);
      
      // Calculate totals based on all records
      calculateTotals(allRecords);
      
      setIsLoading(false);
      return allRecords;
    } catch (error) {
      console.error("Error fetching marketing budget records:", error);
      setIsLoading(false);
      return [];
    }
  }, [dispatch, id]);

  const calculateTotals = useCallback((records) => {
    if (!records || !records.length) return;
    
    let totalBudget = 0;
    let totalSpent = 0;
    let totalRemaining = 0;
    let awarded = 0;
    let payments = 0;
    
    records.forEach(record => {
      const budget = parseFloat(record.budget_amount || 0);
      const spent = parseFloat(record.spent_amount || 0);
      const amount = parseFloat(record.amount || 0);
      
      if (!isNaN(budget)) totalBudget += budget;
      if (!isNaN(spent)) totalSpent += spent;
      if (Number(amount) > 0) {
        awarded += amount;
      } else {
        payments += Math.abs(amount);
      }
    });
    
    totalRemaining = totalBudget - totalSpent;
    
    setTotalBudgetAmount(totalBudget);
    setTotalSpentAmount(totalSpent);
    setTotalRemainingAmount(totalRemaining);
    setTotalAwarded(awarded);
    setTotalPayments(payments);
  }, []);

  useEffect(() => {
    if (id && open) {
      // Initialize state variables
      setAllMarketingBudgetRecords([]);
      setIsLoading(true);
      setTotalAwarded(0);
      setTotalPayments(0);
      
      // Fetch all marketing budget records
      fetchAllMarketingBudgetRecords().then(records => {
        calculateTotals(records);
      });
    }
  }, [id, open, fetchAllMarketingBudgetRecords, calculateTotals]);

  const refreshMarketingBudgetData = useCallback(() => {
    // Clear current data
    setAllMarketingBudgetRecords([]);
    setIsLoading(true);
    
    // Fetch all marketing budget records again
    fetchAllMarketingBudgetRecords();
  }, [fetchAllMarketingBudgetRecords]);

  const fetchMarketingBudgetData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      
      // Get the marketing budget data
      const response = await dispatch(getMarketingBudget({ 
        user_id: id 
      }));
      
      
      // Check if there was an error
      if (response.error) {
        console.error('Error in marketing budget API:', response.error);
        setIsLoadingData(false);
        return;
      }
      
      // Process the data
      if (response.payload && response.payload.data) {
        const processedData = response.payload.data.map(item => {
          // Extract existing data for invoices
          const invoiceNumbers = [];
          const invoiceDates = [];
          const invoiceCategories = [];
          const invoiceDescriptions = [];
          const invoiceAmountPaid = []; // Add this new array
          
          // Check if payment_invoices exist in the original data
          if (item.payment && item.payment.payment_invoices) {
            item.payment.payment_invoices.forEach(paymentInvoice => {
              if (paymentInvoice.invoice) {
                invoiceNumbers.push(paymentInvoice.invoice.invoice_number);
                invoiceDates.push(paymentInvoice.invoice.invoice_date);
                invoiceCategories.push(paymentInvoice.invoice.category);
                invoiceDescriptions.push(paymentInvoice.invoice.description);
                // Add the amount_paid to the new array
                invoiceAmountPaid.push(paymentInvoice.amount_paid);
              }
            });
          }
          
          return {
            ...item,
            invoice_numbers: invoiceNumbers.length > 0 ? invoiceNumbers : null,
            invoice_dates: invoiceDates.length > 0 ? invoiceDates : null,
            invoice_categories: invoiceCategories.length > 0 ? invoiceCategories : null,
            invoice_descriptions: invoiceDescriptions.length > 0 ? invoiceDescriptions : null,
            invoice_amount_paid: invoiceAmountPaid.length > 0 ? invoiceAmountPaid : null, // Include the new array
          };
        });
        
        // Update state with the processed data
        setMarketingBudgetData(processedData);
      } else {
        console.warn('No data returned from marketing budget API');
        setMarketingBudgetData([]);
      }
      
      setIsLoadingData(false);
    } catch (error) {
      console.error("Error fetching marketing budget data:", error);
      setIsLoadingData(false);
      setMarketingBudgetData([]);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      fetchMarketingBudgetData();
    }
  }, [id, fetchMarketingBudgetData]);

  // useEffect(() => {
  //   if (props.row && props.row.payment_id && expandedRows[props.rowId]) {
  //     // Fetch payment details using the payment_id
  //     dispatch(getPaymentDetail({ 
  //       payment_id: props.row.payment_id,
  //       handleSuccess: (response) => {
  //         setPaymentDetails(response);
  //       }
  //     }));
  //   }
  // }, [props.row, props.rowId, expandedRows, dispatch]);

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ 
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiDialog-paper": { maxWidth: "1100px !important", width: "1100px !important" },
          '& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-4': {
            paddingTop: '8px'
          }
        }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth={false}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            padding: '8px 24px',
            minHeight: '40px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Marketing Budget
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 4
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          padding: '12px 24px 12px !important'
        }}>
          <Grid container spacing={2} sx={{ mt: 1, mb: 2, position: 'relative', width: '100%' }}>
            <Grid item xs={12} md={3.25} sx={{ position: 'relative' }}>
              <Card
                sx={{
                  background: '#f5f5f5',
                  borderRadius: '16px',
                  padding: '16px 20px',
                  boxShadow: 'none',
                  border: 'none'
                }}
              >
                <Typography 
                  sx={{ 
                    fontSize: '16px', 
                    color: 'text.secondary',
                    mb: 1
                  }}
                >
                  Total Marketing Budget
                </Typography>
                <Typography 
                  sx={{ 
                    fontSize: '28px', 
                    fontWeight: 400,
                    color: 'text.primary'
                  }}
                >
                  {NumberFormat({
                    number: invoices?.marketingBudgetTotal || 0,
                    maximumFractionDigits: 2,
                    currency: "USD",
                  })}
                </Typography>
              </Card>
            </Grid>

            <Grid item xs={12} md={3.25} sx={{ position: 'relative' }}>
              <Card
                sx={{
                  background: '#f5f5f5',
                  borderRadius: '16px',
                  padding: '16px 20px',
                  boxShadow: 'none',
                  border: 'none'
                }}
              >
                <Typography 
                  sx={{ 
                    fontSize: '16px', 
                    color: 'text.secondary',
                    mb: 1
                  }}
                >
                  Total Awarded
                </Typography>
                <Typography 
                  sx={{ 
                    fontSize: '28px', 
                    fontWeight: 400,
                    color: 'text.primary'
                  }}
                >
                  {NumberFormat({
                    number: totalAwarded || 0,
                    maximumFractionDigits: 2,
                    currency: "USD",
                  })}
                </Typography>
              </Card>
            </Grid>

            <Grid item xs={12} md={3.25} sx={{ position: 'relative' }}>
              <Card
                sx={{
                  background: '#f5f5f5',
                  borderRadius: '16px',
                  padding: '16px 20px',
                  boxShadow: 'none',
                  border: 'none'
                }}
              >
                <Typography 
                  sx={{ 
                    fontSize: '16px', 
                    color: 'text.secondary',
                    mb: 1
                  }}
                >
                  Total Payments
                </Typography>
                <Typography 
                  sx={{ 
                    fontSize: '28px', 
                    fontWeight: 400,
                    color: 'text.primary'
                  }}
                >
                  {NumberFormat({
                    number: totalPayments || 0,
                    maximumFractionDigits: 2,
                    currency: "USD",
                  })}
                </Typography>
              </Card>
            </Grid>

            <Grid item xs={12} md={2.25} sx={{ 
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#000',
                  color: '#fff',
                  borderRadius: '12px',
                  padding: '8px 24px',
                  textTransform: 'none',
                  height: 'auto',
                  minHeight: '36px',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    backgroundColor: '#333'
                  }
                }}
                onClick={() => setOpenAward(true)}
              >
                Award Budget
              </Button>
            </Grid>
          </Grid>
          
          <Box 
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              margin: '0 -24px',
              padding: '0 24px'
            }}
          >
            <Box 
              sx={{
                flex: 1,
                overflowY: 'auto',
                overflowX: 'visible',
                paddingBottom: '90px',
                position: 'relative',
                margin: '0 -24px',
                padding: '0 24px',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  backgroundColor: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '4px',
                  border: 'none'
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                  border: 'none'
                },
                '&::after': {
                  content: '""',
                  display: 'block',
                  height: '90px',
                  position: 'sticky',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: 'white',
                  marginBottom: '-90px'
                }
              }}
            >
              <Box 
                className={classes.PaymentViewSection}
                sx={{ 
                  borderRadius: '18px',
                  overflow: 'visible',
                  position: 'relative',
                  padding: '1px',
                }}
              >
                <Box 
                  className={classes.paymentTableRoot} 
                  sx={{ 
                    position: "relative",
                    marginTop: "0px !important",
                    overflow: "visible !important",
                    zIndex: 2,
                    '& .MuiDataGrid-root': {
                      border: 'none !important',
                      padding: "0 !important",
                      overflow: "visible !important"
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      overflow: 'visible !important',
                    },
                    '& .MuiDataGrid-virtualScrollerContent': {
                      overflow: 'visible !important',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                      overflow: 'visible !important',
                    },
                    '& .MuiDataGrid-row:first-of-type': {
                      marginTop: '10px !important'
                    }
                  }}
                >
                  <DataGridPro
                    rows={currentPageData}
                    columns={columns}
                    loading={isLoading}
                    disableSelectionOnClick
                    hideFooter
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }],
                      },
                    }}
                    sortingMode="client"
                    sx={{
                      border: 'none !important',
                      overflow: 'visible !important',
                      '& .MuiDataGrid-root': {
                        overflow: 'visible !important'
                      },
                      '& .MuiDataGrid-row': {
                        height: '48px !important',
                        minHeight: '48px !important',
                        maxHeight: '48px !important',
                        zIndex: 1
                      },
                      '& .MuiDataGrid-cell': {
                        height: '48px !important',
                        padding: '0 !important',
                        '& .MuiBox-root, & .MuiTypography-root': {
                          marginTop: '-22px !important'
                        }
                      },
                      '& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row': {
                        height: '48px !important',
                        minHeight: '48px !important',
                        maxHeight: '48px !important',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        minHeight: '60px !important',
                        padding: '8px 0 16px 0 !important',
                        marginBottom: '0 !important'
                      },
                      '& .MuiDataGrid-virtualScrollerContent': {
                        marginTop: '0 !important',
                        paddingTop: '0 !important'
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        marginTop: '0 !important',
                        '& .MuiDataGrid-row:first-of-type': {
                          marginTop: '8px !important'
                        }
                      },
                      '& .MuiDataGrid-cell': {
                        height: '48px !important',
                        display: 'flex !important',
                        alignItems: 'center !important',
                        justifyContent: 'flex-start !important',
                        padding: '0 !important',
                        '& .MuiBox-root, & .MuiTypography-root': {
                          marginTop: '-22px !important'
                        }
                      },
                      '& .MuiDataGrid-cell[data-field="Amount"]': {
                        display: 'flex !important',
                        alignItems: 'center !important',
                        '& .MuiBox-root': {
                          height: '100%',
                          display: 'flex !important',
                          alignItems: 'center !important',
                          marginTop: '-9px !important'
                        }
                      },
                      '& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textCenter': {
                        height: '100% !important',
                        '& .MuiBox-root': {
                          height: '100% !important',
                          alignItems: 'center !important',
                          marginTop: '-30px !important',
                          '& .MuiButtonBase-root .MuiSvgIcon-root[data-testid="MoreVertRoundedIcon"]': {
                            marginTop: '18px !important'
                          }
                        }
                      },
                      '& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textLeft': {
                        paddingLeft: '20px !important'
                      },
                      '& .MuiDataGrid-main': {
                        overflow: 'visible !important'
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        overflow: 'visible !important'
                      },
                      '& .MuiDataGrid-row': {
                        overflow: 'visible !important'
                      },
                      '& .MuiDataGrid-columnHeader': {
                        paddingLeft: '14px !important'
                      },
                      '& .MuiDataGrid-columnHeaderTitle': {
                        paddingLeft: '6px'
                      }
                    }}
                    components={{
                      LoadingOverlay: TableSkeleton,
                      Row: (props) => (
                        <Box 
                          className={`css-1ix50fd`}
                          sx={{ 
                            position: "relative", 
                            overflow: "visible",
                            width: "100%",
                            margin: "5px 0 !important",
                            marginBottom: "5px !important",
                            background: "white !important",
                            borderRadius: '20px !important',
                            boxShadow: expandedRows[props.row.id] ? 'none !important' : '0px 10px 27px 0px rgba(0, 0, 0, 0.08) !important',
                            zIndex: 0,
                            '& .MuiDataGrid-row': {
                              background: 'white !important',
                              boxShadow: 'none !important',
                              position: 'relative',
                              zIndex: 1,
                              top: expandedRows[props.row.id] ? '0 !important' : 'auto !important',
                              marginTop: expandedRows[props.row.id] ? '0 !important' : 'auto !important',
                              minHeight: '60px !important',
                              '& .MuiDataGrid-cell': {
                                minHeight: '60px !important',
                                '& .MuiBox-root, & .MuiTypography-root, & .MuiButtonBase-root': {
                                  transform: 'translateY(4px)'
                                }
                              },
                              '& .MuiDataGrid-cell[data-field="Amount"]': {
                                '& .MuiBox-root': {
                                  height: '100%',
                                  display: 'flex !important',
                                  alignItems: 'center !important'
                                }
                              },
                              '& .MuiDataGrid-cell[data-field="actions"]': {
                                '& .MuiBox-root': {
                                  height: '100%',
                                  display: 'flex !important',
                                  alignItems: 'center !important',
                                  justifyContent: 'center !important'
                                }
                              }
                            }
                          }}
                        >
                          <Box sx={{ position: "relative", overflow: "visible", zIndex: 10 }}>
                            <GridRow {...props} />
                            
                            {openMenu === props.row.id && props.row.type === "Award" ? (
                              <OutsideClickHandler
                                onOutsideClick={() => {
                                  setOpenMenu(false);
                                }}
                              >
                                {createPortal(
                                  <div>
                                    <div 
                                      style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 9998,
                                        background: 'transparent',
                                        pointerEvents: 'none'
                                      }}
                                    />
                                    <Paper
                                      sx={{
                                        position: "fixed",
                                        top: (() => {
                                          // Get the position of the button that was clicked
                                          const button = document.querySelector(`[data-row-id="${props.row.id}"]`);
                                          if (button) {
                                            const rect = button.getBoundingClientRect();
                                            return rect.top + 40; // Position below the button
                                          }
                                          return window.scrollY + 100; // Fallback position
                                        })(),
                                        left: (() => {
                                          // Get the position of the button that was clicked
                                          const button = document.querySelector(`[data-row-id="${props.row.id}"]`);
                                          if (button) {
                                            const rect = button.getBoundingClientRect();
                                            return rect.right - 175; // Adjusted position (between -190 and -160)
                                          }
                                          return window.scrollX + 100; // Fallback position
                                        })(),
                                        height: "auto",
                                        border: "0.1px solid lightgrey",
                                        background: "white",
                                        width: "180px",
                                        zIndex: 999999,
                                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                                        pointerEvents: 'auto',
                                        "&:before": {
                                          content: '""',
                                          position: "absolute",
                                          top: "-8px",
                                          right: "13px",
                                          borderLeft: "8px solid transparent",
                                          borderRight: "8px solid transparent",
                                          borderBottom: "8px solid lightgrey",
                                          zIndex: 1,
                                        },
                                        "&:after": {
                                          content: '""',
                                          position: "absolute",
                                          top: "-7px",
                                          right: "14px",
                                          borderLeft: "7px solid transparent",
                                          borderRight: "7px solid transparent",
                                          borderBottom: "7px solid white",
                                          zIndex: 2,
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          setOpenMenu(false);
                                          setOpenAward(true);
                                          setAwardDetail(props?.row);
                                        }}
                                      >
                                        Edit Award
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          setOpenMenu(false);
                                          setAwardDetail(props?.row);
                                          setOpenDelete(true);
                                        }}
                                      >
                                        Remove Award
                                      </MenuItem>
                                    </Paper>
                                  </div>,
                                  document.body
                                )}
                              </OutsideClickHandler>
                            ) : null}
                            
                            {expandedRows[props.rowId] && props.row.type === "Payment" && (
                              <Box
                                className="expanded-content"
                                sx={{
                                  padding: "20px",
                                  paddingTop: "35px !important",
                                  paddingBottom: "20px !important",
                                  background: "#FFFFFF !important",
                                  marginTop: '-20px !important',
                                  position: 'relative',
                                  zIndex: 0,
                                  borderRadius: '20px',
                                  boxShadow: '0px 10px 50px 0px rgba(0, 0, 0, 0.12)',
                                  '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '40px',
                                    background: 'white'
                                  },
                                  '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    right: '20px',
                                    height: '1px',
                                    background: 'rgba(224, 224, 224, 1)',
                                    zIndex: 2
                                  },
                                  '& .MuiDataGrid-root': {
                                    border: 'none',
                                    '& .MuiDataGrid-virtualScroller': {
                                      marginTop: '8px'
                                    },
                                    '& .MuiDataGrid-row': {
                                      cursor: 'pointer',
                                      backgroundColor: '#fff',
                                      margin: '4px 0',
                                      borderRadius: '100px',
                                      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                                      '& .MuiDataGrid-cell': {
                                        borderBottom: 'none'
                                      },
                                      '&:hover': {
                                        backgroundColor: '#f5f5f5'
                                      }
                                    },
                                    '& .MuiDataGrid-columnHeaders': {
                                      borderBottom: 'none',
                                      backgroundColor: 'transparent'
                                    },
                                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                                      '& .MuiDataGrid-row': {
                                        height: '40px !important',
                                        minHeight: '40px !important',
                                        maxHeight: '40px !important'
                                      },
                                      '& .MuiDataGrid-cell': {
                                        height: '40px !important',
                                        minHeight: '40px !important',
                                        maxHeight: '40px !important',
                                        paddingTop: "12px !important"
                                      }
                                    }
                                  }
                                }}
                              >
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    <Typography sx={{ 
                                      fontSize: '16px', 
                                      fontWeight: 500, 
                                      color: 'text.secondary'
                                    }}>
                                      Invoices Paid
                                    </Typography>
                                    <Box>
                                      <DataGridPro
                                        rows={(() => {
                                          const originalRow = marketingBudget.data?.find(item => item.id === props.row.id);
                                          
                                          if (props.row.invoice_numbers && originalRow) {
                                            return props.row.invoice_numbers.map((invoice, index) => {
                                              return {
                                                id: index,
                                                invoice_number: invoice,
                                                invoice_date: props.row.invoice_dates?.[index] || null,
                                                category: props.row.invoice_categories?.[index] || null,
                                                description: props.row.invoice_descriptions?.[index] || null,
                                                amount: props.row.invoice_amount_paid?.[index] || null,
                                                invoice_pdf_path: props.row.invoice_pdf_path?.[index] || null,  // Updated to use the correct path
                                              };
                                            });
                                          }
                                          
                                          return [];
                                        })()}
                                        autoHeight
                                        hideFooter
                                        rowHeight={52}
                                        sx={{
                                          border: 'none',
                                          '& .MuiDataGrid-virtualScroller': {
                                            marginTop: '0px !important'
                                          },
                                          '& .MuiDataGrid-row': {
                                            cursor: 'pointer',
                                            backgroundColor: '#fff',
                                            margin: '4px 0',
                                            borderRadius: '8px',
                                            border: '1px solid rgba(224, 224, 224, 0.8) !important',
                                            '& .MuiDataGrid-cell': {
                                              borderBottom: 'none'
                                            },
                                            '&:hover': {
                                              backgroundColor: '#f5f5f5'
                                            }
                                          },
                                          '& .MuiDataGrid-columnHeaders': {
                                            borderBottom: 'none',
                                            backgroundColor: 'transparent',
                                            paddingBottom: '0px !important',
                                            '& .MuiDataGrid-columnHeader': {
                                              paddingLeft: '16px !important'
                                            },
                                            marginBottom: '0px !important',
                                          },
                                          
                                        }}
                                        columns={[
                                          {
                                            field: "invoice_date",
                                            headerName: "Invoice Date",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Typography sx={{ fontSize: "14px" }}>
                                                  {row.invoice_date ? formatDateString(row.invoice_date) : 'N/A'}
                                                </Typography>
                                              );
                                            },
                                            flex: 0.8,
                                            width: 110,
                                            minWidth: 110,
                                            editable: false,
                                            sortable: false,
                                          },
                                          {
                                            field: "invoice_number",
                                            headerName: "INVOICE #",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Typography sx={{ 
                                                  fontSize: "14px",
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  gap: '8px'
                                                }}>
                                                  {row.invoice_number || 'N/A'}
                                                  <DescriptionRoundedIcon 
                                                    sx={{ 
                                                      fontSize: '18px',
                                                      color: '#666',
                                                      cursor: 'pointer',
                                                      '&:hover': {
                                                        color: '#333'
                                                      }
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setSelectedPdfPath(row.invoice_pdf_path);
                                                      setSelectedInvoiceNumber(row.invoice_number);
                                                      setOpenPdfViewer(true);
                                                    }}
                                                  />
                                                </Typography>
                                              );
                                            },
                                            flex: 0.8,
                                            width: 110,
                                            minWidth: 110,
                                            editable: false,
                                            sortable: false,
                                          },
                                          {
                                            field: "category",
                                            headerName: "Category",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Typography sx={{ fontSize: "14px" }}>
                                                  {row.category || 'N/A'}
                                                </Typography>
                                              );
                                            },
                                            flex: 1,
                                            editable: false,
                                            sortable: false,
                                          },
                                          {
                                            field: "description",
                                            headerName: "Description",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Typography sx={{ fontSize: "14px" }}>
                                                  {row.description || 'N/A'}
                                                </Typography>
                                              );
                                            },
                                            flex: 1.5,
                                            editable: false,
                                            sortable: false,
                                          },
                                          {
                                            field: "amount",
                                            headerName: "Amount",
                                            renderCell: ({ row }) => {
                                              return (
                                                <Typography sx={{ fontSize: "14px" }}>
                                                  {NumberFormat({
                                                    number: row.amount || 0,
                                                    maximumFractionDigits: 2,
                                                    currency: "USD",
                                                  })}
                                                </Typography>
                                              );
                                            },
                                            flex: 0.8,
                                            width: 100,
                                            minWidth: 100,
                                            editable: false,
                                            sortable: false,
                                            align: 'left',
                                            headerAlign: 'left',
                                          }
                                        ]}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'flex-end', 
            mt: 2,
            mb: 0,
            pr: 2,
            pb: 0
          }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              Rows per page:
            </Typography>
            <Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(e.target.value);
                setPage(1); // Reset to first page when changing page size
              }}
              size="small"
              sx={{ 
                mr: 2, 
                minWidth: '80px',
                height: '28px',      // Reduced height
                '.MuiOutlinedInput-input': {
                  padding: '4px 14px 4px 10px', // Reduced padding
                  fontSize: '0.875rem'          // Slightly smaller font
                },
                '.MuiSelect-icon': {
                  top: 'calc(50% - 0.5em)' // Adjust icon position
                }
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            
            <Typography variant="body2" sx={{ mr: 2 }}>
              {allMarketingBudgetRecords.length > 0 ? 
               `${(page - 1) * pageSize + 1}–${Math.min(page * pageSize, allMarketingBudgetRecords.length)} of ${allMarketingBudgetRecords.length}` :
               "0-0 of 0"}
            </Typography>
            
            <IconButton 
              disabled={isLoading || page === 1 || !allMarketingBudgetRecords.length}
              onClick={() => setPage(prev => Math.max(1, prev - 1))}
              size="small"
              sx={{ mr: 1, padding: '4px' }}
            >
              {isLoading ? <CircularProgress size={16} /> : <ChevronLeftIcon fontSize="small" />}
            </IconButton>
            
            <IconButton 
              disabled={isLoading || page * pageSize >= allMarketingBudgetRecords.length}
              onClick={() => setPage(prev => prev + 1)}
              size="small"
              sx={{ padding: '4px' }}
            >
              {isLoading ? <CircularProgress size={16} /> : <ChevronRightIcon fontSize="small" />}
            </IconButton>
          </Box>
          
          <Box sx={{ 
            height: '0px', 
            visibility: 'hidden',
            marginBottom: '0'
          }} />
        </DialogContent>
      </Dialog>
      <AwardBudget
        open={openAward}
        setOpen={setOpenAward}
        id={id}
        isUpdate={awardDetail?.id ? true : false}
        preAwardDetail={awardDetail}
        setAwardDetail={setAwardDetail}
      />
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Confirmation"
        content="Are you sure you want to delete this award budget?"
        handleConfirm={handleConfirm}
        loading={delAward.isLoading}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <ViewInvoice 
        open={openPdfViewer}
        setOpen={setOpenPdfViewer}
        pdfUrl={selectedPdfPath}
        invoiceNumber={selectedInvoiceNumber}
        showInvoicePrefix={true}
      />
    </Fragment>
  );
}

export default MarketingBudget;