import axios from "axios";

export const serverCall = async (method, url, payload) => {
  const token = localStorage.getItem("token");
  try {
    let result = await axios({
      method: method,
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    });
    return result.data;
  } catch (error) {
    console.log("GET_DATA_ERROR: ", error);
  }
};
