export const styleSheet = () => ({
  tableRoot: {
    height: "calc(100vh - 140px)",
    width: "100%",
    backgroundColor: "white",
    maxHeight: "900px",
    minHeight: "500px",
  },
  agentTableHeading: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
});
