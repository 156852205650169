import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Card, Typography, Avatar } from "@mui/material";
import { TabContext } from "@mui/lab";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import { Container } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import OpenHouseTable from "./openHouseTable";
import { getOpenHouseProjectList } from "../../../redux/projects/projects";
function OpenHouse(props) {
  let { classes } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    sortBy: "",
    status: [
      "future",
      "queued",
      "correction",
      "working",
      "review",
      "submitted",
      "approved",
      "pending",
      "completed",
      "canceled",
      "milestone completed",
      "closed",
      "ready_to_complete",
    ],
    search: "",
  });

  useEffect(() => {
    if (location.pathname) {
      let filterStatus = [];
      if (
        location.pathname?.split("/")?.[3] === "current" ||
        location.pathname?.split("/")?.[3] === "future"
      ) {
        filterStatus = [
          "future",
          "queued",
          "correction",
          "working",
          "review",
          "submitted",
          "approved",
          "completed",
          "pending",
          "milestone completed",
          "closed",
          "ready_to_complete",
        ];
      } else if (location.pathname?.split("/")?.[3] === "completed") {
        filterStatus = ["completed"];
      } else if (location.pathname?.split("/")?.[3] === "canceled") {
        filterStatus = ["canceled"];
      }
      dispatch(
        getOpenHouseProjectList({
          ...filters,
          status: filterStatus,
          openHouseType: location.pathname?.split("/")?.[3],
        })
      );
      setFilters({
        ...filters,
        status: filterStatus,
        openHouseType: location.pathname?.split("/")?.[3],
      });
    }
  }, [location.pathname, filters.pageNumber]);

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{ position: "sticky", top: "64px", zIndex: "100" }}
      >
        {" "}
        <Box className={classes.SubHeaderBarArea}>
          <Box className={classes.SubHeaderBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TransactionsTabsUI}>
                  <Tabs
                    value={location.pathname}
                    TabIndicatorProps={{
                      sx: { background: "black", color: "black" },
                    }}
                    variant="scrollable"
                    aria-label="transactions-tabs"
                    sx={{ justifyContent: "space-around" }}
                  >
                    <Tab
                      label="Current"
                      value={`/listings/open-house/current`}
                      to={`/listings/open-house/current`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => setFilters({ ...filters, pageNumber: 1 })}
                    />
                    <Tab
                      label="Future"
                      value={`/listings/open-house/future`}
                      to={`/listings/open-house/future`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => setFilters({ ...filters, pageNumber: 1 })}
                    />
                    <Tab
                      label="Completed"
                      value={`/listings/open-house/completed`}
                      to={`/listings/open-house/completed`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => setFilters({ ...filters, pageNumber: 1 })}
                    />
                    <Tab
                      label="Canceled"
                      value={`/listings/open-house/canceled`}
                      to={`/listings/open-house/canceled`}
                      component={Link}
                      sx={{ padding: "0px 10px" }}
                      onClick={() => setFilters({ ...filters, pageNumber: 1 })}
                    />
                  </Tabs>
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
        </Box>
      </Card>
      <Container className={classes.openHouseListArea} maxWidth="xl">
        <Box className={classes.detailCardSection}>
          <Card className={classes.detailCard}>
            <div>
              <Avatar className={classes.avatar}> OP</Avatar>
            </div>

            <div>
              <Typography className={classes.title}>8</Typography>
              <Typography className={classes.desc}>Open Houses</Typography>
            </div>
          </Card>

          <Card className={classes.detailCard}>
            <div>
              <Avatar className={classes.avatar}> OP</Avatar>
            </div>

            <div>
              <Typography className={classes.title}>3</Typography>
              <Typography className={classes.desc}>This Week</Typography>
            </div>
          </Card>

          <Card className={classes.detailCard}>
            <div>
              <Avatar className={classes.avatar}> OP</Avatar>
            </div>

            <div>
              <Typography className={classes.title}>5</Typography>
              <Typography className={classes.desc}>
                Tasks Out Standings
              </Typography>
            </div>
          </Card>
        </Box>
        <Box className={classes.openHouseListAreaTop}>
          <Typography className={classes.openHouseListHeading}>
            {location.pathname?.split("/")?.[3]} Projects
          </Typography>
        </Box>
        <Routes>
          <Route
            path="/current"
            element={
              <OpenHouseTable
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
          <Route
            path="/future"
            element={
              <OpenHouseTable
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
          <Route
            path="/completed"
            element={
              <OpenHouseTable
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
          <Route
            path="/canceled"
            element={
              <OpenHouseTable
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
        </Routes>
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "OpenHouseStyle" })(OpenHouse);
