import React, { useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import TableSkeleton from "../../../components/loader/tableLoader.jsx";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../components/uiElements/avatar/index.jsx";
import { NumberFormat } from "../../../utils/numberFormat";
import moment from "moment";

function ActivityRecordsTable(props) {
  let { classes, data, loading, filters, userList } = props;
  const navigate = useNavigate();
  const [hideDeactivated, setHideDeactivated] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        let user = userList?.find((item) => item.id === params?.row.id);
        const profileImage =
          user?.profile_images?.profile_img_thumbnail ||
          user?.profile_images?.profile_img ||
          user?.profile_images?.isolated_headshot;
        return (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar
              sx={{ width: "30px", height: "30px", fontSize: "14px" }}
              src={profileImage}
            >{`${params.row.name.split("")[0]?.slice(0, 1)}${params.row.name
              .split("")[1]
              ?.slice(0, 1)
              ?.toUpperCase()}`}</Avatar>
            <Box
              sx={{
                color: params?.row.status === "Departed" ? "lightgrey" : "",
              }}
            >
              {" "}
              {params.row.name}
            </Box>
          </Stack>
        );
      },
      flex: 1,
      minWidth: 220,
      editable: false,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "Join Date",
      flex: 1,
      minWidth: 130,
      renderCell: ({ row }) => {
        return row.createdAt ? (
          <Typography
            sx={{ color: row.status === "Departed" ? "lightgrey" : "" }}
          >
            {moment(row.createdAt, "MMMM Do YYYY, h:mm:ss A").format(
              "MMM D, YYYY"
            )}
          </Typography>
        ) : (
          ""
        );
      },
      editable: false,
      sortable: true,
    },
    {
      field: "totalVolume",
      headerName: "Total Volume",
      flex: 1,
      minWidth: 130,
      renderCell: ({ row }) => {
        return row.totalVolume ? (
          <Typography>
            {NumberFormat({
              number: row.totalVolume,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalVolume ? Number(params.row.totalVolume) : "0",
      editable: false,
      sortable: true,
    },
    {
      field: "totalClosings",
      headerName: "Total Closings",
      valueGetter: (params) =>
        params.row.totalClosings ? Number(params.row.totalClosings) : "0",
      renderCell: ({ row }) => {
        return row.totalClosings ? (
          <Typography
            onClick={() => {
              navigate("/profit-report/transactions", {
                state: {
                  agent_ids: JSON.stringify([`${row.agent_id}`]),
                  start_date: filters.fromDate,
                  end_date: filters.lastDate,
                  team_id: filters.team_id,
                },
              });
              localStorage.setItem("filters", JSON.stringify(filters));
            }}
            sx={{ cursor: "pointer", width: "100%", color: "#35A2EB" }}
          >
            {row.totalClosings}
          </Typography>
        ) : (
          "0"
        );
      },
      editable: false,
      flex: 1,
      minWidth: 80,
      sortable: true,
    },
    {
      field: "totalLeads",
      headerName: "Total Leads",

      valueGetter: (params) =>
        params.row.totalLeads ? Number(params.row.totalLeads) : "0",
      renderCell: ({ row }) => {
        return row.totalLeads ? (
          <Typography
            onClick={() => {
              navigate("/leads/people", {
                state: {
                  from_agentprofit: 1,
                  agentprofit_agent_id: JSON.stringify([`${row.agent_id}`]),
                  agentprofit_start_date: filters.fromDate,
                  agentprofit_end_date: filters.lastDate,
                  agentprofit_team_id: filters.team_id,
                },
              });
              localStorage.setItem("filters", JSON.stringify(filters));
            }}
            sx={{ cursor: "pointer", width: "100%", color: "#35A2EB" }}
          >
            {row.totalLeads}
          </Typography>
        ) : (
          "0"
        );
      },
      editable: false,
      flex: 1,
      minWidth: 80,
      sortable: true,
    },
    {
      field: "totalGCI",
      headerName: "Total GCI",
      renderCell: ({ row }) => {
        return row.totalGCI ? (
          <Typography>
            {NumberFormat({
              number: row.totalGCI,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalGCI ? Number(params.row.totalGCI) : "0",
      editable: false,
      flex: 1,
      minWidth: 130,
      sortable: true,
    },
    {
      field: "totalTeamLeaderCommission",
      headerName: "Total Team Leader Commission",
      renderCell: ({ row }) => {
        return row.totalTeamLeaderCommission ? (
          <Typography>
            {NumberFormat({
              number: row.totalTeamLeaderCommission,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalTeamLeaderCommission
          ? Number(params.row.totalTeamLeaderCommission)
          : "0",
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: "totalCompanyDollars",
      headerName: "Total Company Dollars",
      renderCell: ({ row }) => {
        return row.totalCompanyDollars ? (
          <Typography>
            {NumberFormat({
              number: row.totalCompanyDollars,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalCompanyDollars
          ? Number(params.row.totalCompanyDollars)
          : "0",
      editable: false,
      flex: 1,
      minWidth: 130,
      sortable: true,
    },
    {
      field: "netProfit",
      headerName: "Total Net Profit",
      editable: false,
      renderCell: ({ row }) => {
        return row.netProfit ? (
          <Typography>
            {NumberFormat({
              number: row.netProfit,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.netProfit ? Number(params.row.netProfit) : "0",
      sortable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: "lifetimeProfits",
      headerName: "Prior Profitability",
      editable: false,
      renderCell: ({ row }) => {
        return row.netProfit || row.lifetimeProfit ? (
          <Typography>
            {Number(row.lifetimeProfit) - Number(row.netProfit || 0) > 0
              ? NumberFormat({
                  number:
                    Number(row.lifetimeProfit) - Number(row.netProfit || 0),
                  maximumFractionDigits: 0,
                  currency: "USD",
                })
              : 0}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.netProfit || params.row.lifetimeProfit
          ? Number(params.row?.lifetimeProfit) -
            Number(params.row?.netProfit || 0)
          : "0",
      sortable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: "lifetimeProfit",
      headerName: "Life time Profitability",
      editable: false,
      renderCell: ({ row }) => {
        return row.lifetimeProfit ? (
          <Typography>
            {NumberFormat({
              number: row.lifetimeProfit,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.lifetimeProfit ? Number(params.row.lifetimeProfit) : "0",
      sortable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: "totalOverhead",
      headerName: "Total Overhead",
      editable: false,
      renderCell: ({ row }) => {
        return row.totalOverhead ? (
          <Typography>
            {NumberFormat({
              number: row.totalOverhead,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalOverhead ? Number(params.row.totalOverhead) : "0",
      sortable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: "totalLeadCost",
      headerName: "Total Lead Cost",
      editable: false,
      renderCell: ({ row }) => {
        return row.totalLeadCost ? (
          <Typography>
            {NumberFormat({
              number: row.totalLeadCost,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalLeadCost ? Number(params.row.totalLeadCost) : "0",
      sortable: true,
      flex: 1,
      minWidth: 130,
    },
  ];

  return (
    <Box>
      <Box className={classes.toggleSection}>
        <FormControlLabel
          control={
            <Switch
              checked={hideDeactivated}
              onChange={(e) => setHideDeactivated(e.target.checked)}
            />
          }
          label="Hide Deactivated"
        />
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={hideDeactivated ? data?.filter((item) => item.status !== "Departed") : data}
          loading={loading}
          columns={columns}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          initialState={{
            pinnedColumns: { left: ["name"] },
          }}
          sx={{
            '& .MuiDataGrid-root': {
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            },
            '& .MuiDataGrid-main': {
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto'
            },
            '& .MuiDataGrid-virtualScroller': {
              overflow: 'auto !important',
              marginBottom: '56px',
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
                display: 'block'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#F5F5F5'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888'
              }
            },
            '& .MuiDataGrid-footerContainer': {
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '56px',
              backgroundColor: 'white',
              borderTop: '1px solid rgba(224, 224, 224, 1)',
              paddingRight: '8px'
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              minHeight: 'auto'
            },
            '& .MuiDataGrid-virtualScrollerRenderZone': {
              width: 'fit-content'
            },
            "& .MuiDataGrid-pinnedColumnHeaders": {
              borderRight: "none !important",
              boxShadow: "none !important",
            },
            "& .MuiDataGrid-cell": {
              borderRight: "none !important",
            }
          }}
          style={{
            height: '100%',
            width: '100%',
            position: 'relative'
          }}
          headerHeight={56}
          disableExtendRowFullWidth={false}
          hideFooter={false}
          hideFooterPagination={true}
          hideFooterSelectedRowCount={false}
        />
      </Paper>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ActivityRecordsTableStyle" })(
  ActivityRecordsTable
);
