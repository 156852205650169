import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const createInvoice = createAsyncThunk(
  "AgentTabs/createInvoice",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/users/v2/tabs/invoice`,
        {
          ...data?.schema,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.data && data?.handleSuccess) {
        data?.handleSuccess();
      }
      return resp.data?.invoice;
    } catch (error) {
      if (error.response) {
        if (data?.handleError) {
          data?.handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (data?.handleError) {
          data?.handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
