export const styleSheet = () => ({
  pageRoot: {
    marginTop: "20px",
    backgroundColor: "#F5F5F5",
    minHeight: "100vh",
  },
  mDisplay: {
    width: "100%",
    display: "flex",
    backgroundColor: "#F5F5F5",
  },
  mWidth_15: {
    width: "15%",
    height: "100%",
    overflowY: "auto",
    backgroundColor: "#fff",
    // position: "fixed",
    position: "sticky",
    top: "70px",
  },
  mWidth_85: {
    // position: "relative",
    // left: "25%",
    width: "85%",
    backgroundColor: "#F5F5F5",
    padding: "10px 20px",
    border: "1px solid lightgrey",
  },
  mainStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
  },
  mColor: {
    backgroundColor: "#ededed",
  },
  mCanvas: {
    display: "block",
    margin: "auto",
  },
  mPosition: {
    position: "relative",
    marginBottom: "30px",
  },
  mButtonStyle: {
    background: "#000000 !important",
    borderRadius: "12px !important",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "36px",
    color: "#ffffff !important",
    padding: "7px 30px !important",
    margin: "10px 0 !important",
    "&:disabled": {
      opacity: "0.5",
    },
  },
  mAccordianColor: {
    background: "#ededed !important",
  },
  tabBarArea: {
    position: "sticky",
    top: 0, // Adjust based on your layout
    // boxShadow: "0px 0px 0px 1px #E0E0E0",
    // height:"100%"
  },
  mAccordianDetails: {
    backgroundColor: "#fff",
    padding: "0 !important",
  },
  mAccordianTxt: {
    textAlign: "center",
    paddingTop: "10px",
  },
  mAccordianImage: {
    margin: "auto",
    width: "80px",
    border: "0.5px solid #a39b9b",
    filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))",
    cursor: "pointer",
  },
  mActive: {
    border: "0.8px solid #1D30D4 !important",
  },
  transactionDetailSection: {
    padding: "16px",
    border: "2px solid  #1D30D4 !important",
    backgroundColor: "#dde5f0 !important",
  },
  transactionDetailHead: {
    fontSize: "13px !important",
    textAlign: "center !important",
    color: "#7e8290 !important",
    fontWeight: "500 !important",
    "& span": {
      fontWeight: "600 !important",
      color: "black !important",
    },
    "& a": {
      fontWeight: "600 !important",
      color: "#325bab !important",
      marginLeft: "12px",
      cursor: "pointer",
    },
  },
  transactionContTop: {
    color: "#7e8290 !important",
    fontWeight: "500 !important",
    fontSize: "13px !important",
    textAlign: "center !important",
  },
  transactionContBottom: {
    fontWeight: "600 !important",
    color: "black !important",
    fontSize: "13px !important",
    textAlign: "center !important",
  },
  subHeaderSec: {
    height: "50px !important",
    backgroundColor: "white !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    marginTop: "-3px",
  },
  subHeaderBack: {
    width: "50px",
    height: "50px",
    backgroundColor: "#285dc6",
    display: "flex !important",
    justifyContent: "center!important",
    alignItems: "center !important",
    color: "white !important",
    cursor: "pointer !important",
  },
  subHeaderLeft: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "12px !important",
  },
  subHeaderRight: {
    paddingRight: "16px",
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    gap: "12px !important",
  },
  headerButton: {
    borderRadius: "20px !important",
    textTransform: "capitalize !important",
    backgroundColor: "#225ccf !important",
    color: "white !important",
    width: "100px !important",
  },
  sideBarDocName: {
    display: "-webkit-box !important",
    "-webkit-line-clamp": "2 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
    fontSize: "12px !important",
  },
  subHeaderCenter: {
    "& .MuiTab-label": {
      textTransform: "capitalize !important",
    },
  },
  peopleSection: {
    margin: "50px 30px",
    padding: "24px",
    minHeight: "200px",
    background: "white",
    borderTop: "4px solid #1448b0",
  },
  fieldSection: {
    margin: "50px 30px",
    padding: "70px 50px",
    minHeight: "200px",
    background: "white",
  },
  historySection: {
    margin: "50px 30px",
    padding: "50px",
    minHeight: "200px",
    background: "white",
  },
  historyAreaItem: {
    borderLeft: "4px solid #4cd4a0",
    padding: "8px",
  },
  historyHeader: {
    backgroundColor: "#4cd4a0",
    padding: "5px",
  },
  historyHeaderContent: {
    color: "white !important",
  },
  historyArea: {
    padding: "5px",
    marginTop: "12px",
  },
  peopleItemRow: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px",
    marginTop: "10px !important",
  },
  peopleBelowSection: {
    display: "flex !important",
    flexDirection: "column !important",
    gap: "16px !important",
  },
  peopleSectionItemLabel: {
    color: "#9f9fa3 !important",
    fontSize: "14px !important",
  },
  SectionFields: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "6px",
    width: "100%",
    backgroundColor: "#eeeeed !important",
    border: "1px solid #e2e2e1 !important",
    padding: "1px",
    flexWrap: "wrap",
  },
  SectionFieldsItem: {
    backgroundColor: "#a2c8ed !important",
    height: "26px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 4px",
    gap: "10px",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  pageHeading: {
    borderBottom: "2px solid #1448b0",
  },
  fieldItemLabel: {
    color: "#9f9fa3 !important",
    fontSize: "14px !important",
  },
  fieldsSection: {
    display: "flex !important",
    flexDirection: "column !important",
    gap: "20px !important",
    marginTop: "20px !important",
  },
  fieldSectionPage: {
    marginBottom: "20px !important",
  },
  dateContent: {
    fontSize: "14px !important",
    marginTop: "6px !important",
    color: "#9b9b9e !important",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
  },
  modalHeading: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: "black !important",
    textTransform: "capitalize !important",
  },
  inputLabel: {
    color: "#9f9fa3 !important",
    fontSize: "14px !important",
    textTransform: "capitalize !important",
  },
});
