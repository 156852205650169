import React, { useState } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel,
  Button,
  IconButton,
} from "@mui/material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import AddUserOnTransaction from "./addUser";
import ViewUserDetail from "./viewUserDetail";
function Peoples(props) {
  const [openAdd, setOpenAdd] = useState(false);
  const [editDetail, setEditDetail] = useState({
    type: "",
    action: "",
    preDetail: {},
  });
  const [openDetail, setOpenDetail] = useState(false);

  const { classes, docDetail } = props;
  const navigate = useNavigate();
  return (
    <Box className={classes.peopleSection}>
      <br />
      <Box className={classes.peopleItemRow}>
        <Typography sx={{ fontSize: "13px" }}>I am working with:</Typography>
        <RadioGroup
          value={docDetail?.data?.representation_side}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="doc-row-radio-buttons-group"
          sx={{ "& .MuiTypography-root": { fontSize: "14px" }, ml: "20px" }}
        >
          <FormControlLabel
            value="listing"
            control={<Radio />}
            label="Sellers"
          />
          <FormControlLabel value="buying" control={<Radio />} label="Buyers" />
          <FormControlLabel value="both" control={<Radio />} label="Both" />
        </RadioGroup>
      </Box>
      <Box className={classes.peopleItemRow}>
        <Typography sx={{ fontSize: "13px" }}>
          I am providing support for:
        </Typography>
        <RadioGroup
          value={docDetail?.data?.folder_type || "none"}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="doc-row-radio-buttons-group"
          sx={{ "& .MuiTypography-root": { fontSize: "14px" }, ml: "20px" }}
        >
          <FormControlLabel
            value="none"
            control={<Radio />}
            label="None"
            disabled
          />
          <FormControlLabel value="sale" control={<Radio />} label="Sellers" />
          <FormControlLabel value="lease" control={<Radio />} label="Buyers" />
        </RadioGroup>
      </Box>
      <br />
      <Typography
        sx={{
          fontSize: "14px",
          color: "#1448b0",
          fontWeight: "500",
          mb: "16px",
        }}
      >
        Click on any name to see contact detail
      </Typography>
      <Box className={classes.peopleBelowSection}>
        <Box className={classes.peopleSectionItem}>
          <InputLabel className={classes.peopleSectionItemLabel}>
            Full Legal Names of All Buyer(s)
          </InputLabel>
          <Box className={classes.SectionFields}>
            {docDetail?.data?.buyers?.length ? (
              docDetail?.data?.buyers?.map((buyer, index) => (
                <Box key={index} className={classes.SectionFieldsItem}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenDetail(true);
                      setEditDetail({
                        type: "Buyer",
                        action: "update",
                        preDetail: { ...buyer },
                      });
                    }}
                  >
                    {buyer?.first_name} {buyer?.last_name}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setOpenDetail(true);
                      setEditDetail({
                        type: "Buyer",
                        action: "update",
                        preDetail: { ...buyer },
                        isEditable: true,
                      });
                    }}
                    size="small"
                  >
                    <ModeEditOutlinedIcon
                      fontSize="small"
                      sx={{ fontSize: "20px" }}
                    />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: "#a2c8ed",
                  textTransform: "capitalize",
                  borderRadius: "0px",
                  boxShadow: "none",
                }}
                color="inherit"
                startIcon={<AddRoundedIcon fontSize="small" />}
                onClick={() => {
                  setOpenAdd(true);
                  setEditDetail({
                    type: "Buyer",
                    action: "add",
                    preDetail: {},
                  });
                }}
              >
                {" "}
                Add Buyer{" "}
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classes.peopleSectionItem}>
          <InputLabel className={classes.peopleSectionItemLabel}>
            Full Legal Names of All Seller(s)
          </InputLabel>
          <Box className={classes.SectionFields}>
            {docDetail?.data?.sellers?.length ? (
              docDetail?.data?.sellers?.map((buyer, index) => (
                <Box key={index} className={classes.SectionFieldsItem}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenDetail(true);
                      setEditDetail({
                        type: "Seller",
                        action: "update",
                        preDetail: { ...buyer },
                      });
                    }}
                  >
                    {buyer?.first_name} {buyer?.last_name}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setOpenDetail(true);
                      setEditDetail({
                        type: "Seller",
                        action: "update",
                        preDetail: { ...buyer },
                        isEditable: true,
                      });
                    }}
                    size="small"
                  >
                    <ModeEditOutlinedIcon
                      fontSize="small"
                      sx={{ fontSize: "20px" }}
                    />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: "#a2c8ed",
                  textTransform: "capitalize",
                  borderRadius: "0px",
                  boxShadow: "none",
                }}
                color="inherit"
                startIcon={<AddRoundedIcon fontSize="small" />}
                onClick={() => {
                  setOpenAdd(true);
                  setEditDetail({
                    type: "Seller",
                    action: "add",
                    preDetail: {},
                  });
                }}
              >
                {" "}
                Add Seller{" "}
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classes.peopleSectionItem}>
          <InputLabel className={classes.peopleSectionItemLabel}>
            Name of buying Broker/Salesperson
          </InputLabel>
          <Box className={classes.SectionFields}>
            {docDetail?.data?.buyer_brokers?.length ? (
              docDetail?.data?.buyer_brokers?.map((buyer, index) => (
                <Box key={index} className={classes.SectionFieldsItem}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenDetail(true);
                      setEditDetail({
                        type: "Buyer Broker",
                        action: "update",
                        preDetail: { ...buyer },
                      });
                    }}
                  >
                    {buyer?.first_name} {buyer?.last_name}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setOpenDetail(true);
                      setEditDetail({
                        type: "Buyer Broker",
                        action: "update",
                        preDetail: { ...buyer },
                        isEditable: true,
                      });
                    }}
                    size="small"
                  >
                    <ModeEditOutlinedIcon
                      fontSize="small"
                      sx={{ fontSize: "20px" }}
                    />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: "#a2c8ed",
                  textTransform: "capitalize",
                  borderRadius: "0px",
                  boxShadow: "none",
                }}
                color="inherit"
              >
                {" "}
                Not Exists Buyer Broker{" "}
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classes.peopleSectionItem}>
          <InputLabel className={classes.peopleSectionItemLabel}>
            Name of Listing Broker/Salesperson
          </InputLabel>
          <Box className={classes.SectionFields}>
            {docDetail?.data?.seller_brokers?.length ? (
              docDetail?.data?.seller_brokers?.map((buyer, index) => (
                <Box key={index} className={classes.SectionFieldsItem}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenDetail(true);
                      setEditDetail({
                        type: "Seller Broker",
                        action: "update",
                        preDetail: { ...buyer },
                      });
                    }}
                  >
                    {buyer?.first_name} {buyer?.last_name}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setOpenDetail(true);
                      setEditDetail({
                        type: "Seller Broker",
                        action: "update",
                        preDetail: { ...buyer },
                        isEditable: true,
                      });
                    }}
                    size="small"
                  >
                    <ModeEditOutlinedIcon
                      fontSize="small"
                      sx={{ fontSize: "20px" }}
                    />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: "#a2c8ed",
                  textTransform: "capitalize",
                  borderRadius: "0px",
                  boxShadow: "none",
                }}
                color="inherit"
              >
                {" "}
                Not Exits Seller Broker{" "}
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classes.buttonSection}>
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: "capitalize" }}
            onClick={() => navigate(-1)}
          >
            Back to Document
          </Button>
        </Box>
      </Box>
      <AddUserOnTransaction
        open={openAdd}
        setOpen={setOpenAdd}
        editDetail={editDetail}
        {...props}
      />
      <ViewUserDetail
        open={openDetail}
        setOpen={setOpenDetail}
        editDetail={editDetail}
        {...props}
      />
    </Box>
  );
}

export default Peoples;
