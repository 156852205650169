import React from "react";
import { Routes, Route } from "react-router-dom";
import DocsListing from "./docsListing";
import DocsDetails from "./docDetails";
import EditDocument from "./editDocument";
const Docs = () => {
  return (
    <Routes>
      <Route path="/" element={<DocsListing />} />
      <Route path="/:id" element={<DocsDetails />} />
      <Route path="/:id/*" element={<EditDocument />} />
    </Routes>
  );
};

export default Docs;
