import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { DataGridPro, GridRow } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../../../components/loader/tableLoader.jsx";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import moment from "moment";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { NumberFormat } from "../../../../../utils/numberFormat";
import UpdateInvoice from "./createInvoice.jsx";
import OutsideClickHandler from "react-outside-click-handler";
import {
  editInvoice,
  deleteInvoice,
  sendInvoiceAlert,
  voidInvoice,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../../../components/responseAlert";
import DeleteDraft from "./deleteDraft.jsx";
import VoidInvoice from "./voidInvoice.jsx";
import ConfirmModal from "../../../../../components/globalModal/ConfirmModal";
import { CustomTooltip } from "../../../../../components/shared/tooltip";
import { styled } from "@mui/material/styles";
import { useStyles } from "./style";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ViewInvoice from "./viewInvoice.jsx";
import { TablePagination } from "@mui/material";
import { getPayments } from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .css-1pqpq69-MuiButtonGroup-root": {
    boxShadow: "none !important",
  },
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minHeight: "70vh",
}));

const InvoicesTable = (props) => {
  const { classes, filters, setFilters, id } = props;
  const [expandedRows, setExpandedRows] = useState({});
  const [invoiceMenu, setInvoiceMenu] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.agentRoster.AgentTabs.invoices);
  const updateInvoice = useSelector(
    (state) => state.agentRoster.AgentTabs.updateInvoice
  );
  const delInvoice = useSelector(
    (state) => state.agentRoster.AgentTabs.delInvoice
  );
  const invoiceAlert = useSelector(
    (state) => state.agentRoster.AgentTabs.invoiceAlert
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [isViewInvoiceOpen, setIsViewInvoiceOpen] = useState(false);
  const [selectedInvoicePdf, setSelectedInvoicePdf] = useState(null);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [allPayments, setAllPayments] = useState([]);
  const [isLoadingAllPayments, setIsLoadingAllPayments] = useState(false);
  const [totalPaymentsAmount, setTotalPaymentsAmount] = useState(0);
  const [hasFetchedPayments, setHasFetchedPayments] = useState(false);
  const [isTotalLoading, setIsTotalLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Add a ref to store the total
  const totalRef = useRef(null);

  // Add a ref to track the last fetch time
  const lastFetchTimeRef = useRef(null);

  // Get payments data at component level
  const payments = useSelector((state) => state.agentRoster.AgentTabs.payments);
  // Get the payments data from Redux
  const paymentsData = useSelector(
    (state) => state.agentRoster.AgentTabs.payments.data || []
  );

  // Create a lookup map for quick access to payment amounts by ID
  const paymentAmountsById = useMemo(() => {
    const map = {};
    if (payments?.data) {
      payments.data.forEach((payment) => {
        map[payment.id] = payment.amount;
      });
    }
    return map;
  }, [payments]);

  const handleToggleExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const handleViewInvoice = async (invoice) => {
    console.log("handleViewInvoice called with invoice:", invoice);
    try {
      // Show loading state
      setIsLoading(true);

      // Call the API to generate the invoice PDF
      const url = `${process.env.REACT_APP_BASE_URL}api/users/v2/tabs/generate-invoice/${invoice.id}`;

      console.log("Generating invoice PDF from:", url);

      // Add a timeout to the axios request
      const resp = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.token,
          Accept: "application/pdf",
        },
        timeout: 60000, // 60 seconds timeout
      });

      console.log("API response received:", {
        status: resp.status,
        statusText: resp.statusText,
        contentType: resp.headers["content-type"],
        dataSize: resp.data.size,
      });

      // Create a URL for the PDF blob
      const pdfUrl = URL.createObjectURL(resp.data);

      // Open the PDF in a new tab
      window.open(pdfUrl, "_blank");

      // Hide loading state
      setIsLoading(false);
    } catch (error) {
      console.error("Error viewing invoice:", error);

      // Log more detailed error information
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
        console.error("Request timed out or no response received");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }

      setErrorAlert({
        errorMsg: error.response
          ? `Failed to generate invoice PDF: ${error.response.status} ${error.response.statusText}`
          : `Failed to generate invoice PDF: ${
              error.message || "Request timed out"
            }`,
        errorType: "error",
        isOpen: true,
      });

      setIsLoading(false);
    }
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              background:
                row.status === "outstanding"
                  ? "#FFECEC"
                  : row.status === "draft"
                  ? "black"
                  : row.status === "paid"
                  ? "#CDF9D8"
                  : "#d8d8d8",
              color:
                row.status === "outstanding"
                  ? "#CC0000"
                  : row.status === "draft"
                  ? "white"
                  : row.status === "paid"
                  ? "#005214"
                  : "",
              padding: "12px 16px",
              lineHeight: "1.7",
              height: "auto",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
              "& span": {
                transform: "translateY(1px)",
              },
            }}
            className={classes.statusChip}
          >
            <span>{row.status}</span>
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "invoice_date",
      headerName: "Date",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.invoice_date).format("L")}
          </Typography>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "invoice",
      headerName: "INVOICE #",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
              alignItems: "center ",
              color: "#696969",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              {row?.invoice_number}
            </Typography>
            <IconButton
              onClick={() => {
                setSelectedInvoicePdf(row.pdf_path || row.pdf_url);
                setSelectedInvoiceNumber(row.invoice_number);
                setIsViewInvoiceOpen(true);
              }}
              size="small"
            >
              {" "}
              <DescriptionRoundedIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "category",
      headerName: "Category",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
            {row?.category}
          </Typography>
        );
      },
      width: 180,
      editable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>{row?.description}</Typography>
        );
      },
      minWidth: 250,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "duedate",
      headerName: "Due Date",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.due_date).format("L")}
          </Typography>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "amount_due",
      headerName: "AMT DUE",
      renderCell: ({ row }) => {
        // Calculate total payments using only valid, non-deleted payments
        const totalPayments =
          row.payments?.reduce((sum, paymentRow) => {
            if (paymentRow?.payment && !paymentRow?.payment?.deleted_at) {
              return sum + Number(paymentRow?.amount_paid || 0);
            }
            return sum;
          }, 0) || 0;

        // Calculate amount due
        const amountDue = Number(row?.amount_due || 0) - totalPayments;

        // Format the amount, ensuring zero is displayed as $0.00
        const formattedAmount =
          amountDue === 0
            ? "$0.00"
            : NumberFormat({
                number: amountDue,
                maximumFractionDigits: 2,
                currency: "USD",
              });

        return <Box>{formattedAmount}</Box>;
      },
      minWidth: 100,
      editable: false,
      sortable: true,
    },
    {
      field: "invoice_amount",
      headerName: "INV AMT",
      renderCell: ({ row }) => {
        // Calculate total amount from charges
        const totalAmount =
          row.charges?.reduce(
            (sum, charge) => sum + Number(charge?.amount || 0),
            0
          ) || 0;

        return (
          <Box>
            {NumberFormat({
              number: totalAmount,
              maximumFractionDigits: 2,
              currency: "USD",
            })}
          </Box>
        );
      },
      minWidth: 100,
      editable: false,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 100,
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
            position: "relative",
            display: "flex",
            minWidth: 0,
            "& .MuiIconButton-root": {
              padding: "4px",
            },
          }}
        >
          {expandedRows[params.row.id] ? (
            <IconButton
              onClick={() => handleToggleExpand(params.row.id)}
              size="small"
            >
              <ExpandLessRoundedIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => handleToggleExpand(params.row.id)}
              size="small"
            >
              <ExpandMoreRoundedIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: "menuActions",
      headerName: "",
      width: 10,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setInvoiceData(params.row);
            setMenuPosition({ top: e.clientY, left: e.clientX });
            setAnchorEl(e.currentTarget);
          }}
          disabled={
            params.row.status === "Paid" ||
            params.row.status === "paid" ||
            params.row.status === "voided"
          }
          size="small"
          sx={{
            padding: "2px",
            ml: -2.5,
            minWidth: "12px",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  const handleSuccess = () => {
    setOpenConfirm(false);
    setErrorAlert({
      errorMsg: "You have successfully updated the invoice",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleConfirmVoid = ({ reason, sendAlert }) => {
    dispatch(
      voidInvoice({
        invoice_id: invoiceData?.id,
        schema: {
          status: "voided",
          user_id: id,
          reason_for_voiding: reason,
          email_alert: sendAlert,
        },
        handleSuccess: () => {
          setOpenConfirm(false);
          setErrorAlert({
            errorMsg: "Invoice voided successfully",
            errorType: "success",
            isOpen: true,
          });
        },
        handleError: (error) => {
          setErrorAlert({
            errorMsg: error,
            errorType: "error",
            isOpen: true,
          });
        },
      })
    );
  };

  const handleSuccessDel = () => {
    setDeleteConfirm(false);
    setErrorAlert({
      errorMsg: "You have successfully deleted the invoice",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteInvoice({
        invoice_id: invoiceData?.id,
        handleSuccess: handleSuccessDel,
        handleError,
      })
    );
  };

  const handleSuccessAlert = () => {
    setOpenAlert(false);
    setErrorAlert({
      errorMsg: "Alert sent successfully!",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleConfirm = () => {
    dispatch(
      sendInvoiceAlert({
        invoice_id: invoiceData?.id,
        handleSuccess: handleSuccessAlert,
        handleError,
      })
    );
  };

  const getExpandedContentHeight = (charges, payments) => {
    const chargesLength = charges?.length || 0;
    const paymentsLength =
      payments?.filter((p) => p?.payment && !p?.payment?.deleted_at)?.length ||
      0;

    const rowHeight = 40; // Match the CSS maxHeight/minHeight
    const headerHeight = 56;
    const containerPadding = 80; // Increased from 64 to 80
    const rowSpacing = 4; // Account for the margin in CSS (2px top + 2px bottom)

    const maxRows = Math.max(chargesLength, paymentsLength);
    const rowsHeight = maxRows * rowHeight + (maxRows - 1) * rowSpacing;

    return rowsHeight + headerHeight + containerPadding;
  };

  const expandedRowStyles = {
    border: "1px solid rgba(0, 0, 0, 0.08) !important",
    borderRadius: "20px !important",
    marginTop: "2px !important",
    marginBottom: "2px !important",
    background: "white !important",
    minHeight: "40px !important",
    maxHeight: "40px !important",
    width: "100% !important",
    boxShadow: "none !important",
  };

  const expandedCellStyles = {
    maxWidth: "fit-content !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
  };

  const chargesColumns = [
    {
      field: "quantity",
      headerName: "Qty",
      flex: 0.08,
      minWidth: 60,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 0.25,
      minWidth: 140,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.47,
      minWidth: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.2,
      minWidth: 100,
      renderCell: (params) => (
        <Box sx={{ width: "100%", pl: 3, color: "#D32F2F" }}>
          {params.value ? `$${Number(params.value).toFixed(2)}` : "$0.00"}
        </Box>
      ),
      renderHeader: (params) => (
        <Box sx={{ pl: 3, fontWeight: 500 }}>Amount</Box>
      ),
    },
  ];

  const paymentsColumns = [
    {
      field: "date",
      headerName: "Date",
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.payment?.payment_date).format("L")}
          </Typography>
        );
      },
      editable: false,
      sortable: true,
    },
    {
      field: "category",
      headerName: "Method",
      width: 170,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {row?.payment?.type}
          </Typography>
        );
      },
      editable: false,
      sortable: true,
    },
    {
      field: "Description",
      headerName: "Description",
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        return (
          <CustomTooltip title={row?.payment?.description} placement="top">
            <Typography sx={{ fontSize: "14px" }}>
              {row?.payment?.description}
            </Typography>
          </CustomTooltip>
        );
      },
      editable: false,
      sortable: true,
    },
    {
      field: "Amount",
      headerName: "AMOUNT",
      renderCell: ({ row }) => {
        // Only show payments that have a valid payment record
        if (!row?.payment || !row?.amount_paid) return null;

        // We need to fetch the status from the Redux store
        // Get the payment ID
        const paymentId = row.payment.id;

        // Find the payment with the matching ID
        const paymentWithStatus = paymentsData.find((p) => p.id === paymentId);

        // Check if we found the payment and if it's posted
        const isPosted = paymentWithStatus?.status === "Posted";

        // Set color based on status
        const textColor = isPosted ? "#4CAF50" : "#696969";

        // Format the amount
        const formattedAmount = `$${Number(row.amount_paid).toFixed(2)}`;

        // Return with tooltip for pending payments
        return (
          <Box align="center">
            {isPosted ? (
              <Typography sx={{ fontSize: "14px", color: textColor }}>
                {formattedAmount}
              </Typography>
            ) : (
              <CustomTooltip title="Pending" placement="top">
                <Typography sx={{ fontSize: "14px", color: textColor }}>
                  {formattedAmount}
                </Typography>
              </CustomTooltip>
            )}
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
  ];

  // Keep the deduplication logic but remove the console logs
  const filteredData = useMemo(() => {
    if (!invoices?.data?.length) {
      return [];
    }

    // First, deduplicate the data by ID
    const uniqueInvoicesMap = new Map();
    invoices.data.forEach((invoice) => {
      if (!uniqueInvoicesMap.has(invoice.id)) {
        uniqueInvoicesMap.set(invoice.id, invoice);
      }
    });

    const uniqueInvoices = Array.from(uniqueInvoicesMap.values());

    // Then apply search filtering if needed
    if (!filters.search) {
      return uniqueInvoices;
    }

    const searchTerm = filters.search.toLowerCase().trim();

    return uniqueInvoices.filter((invoice) => {
      // Search by invoice number
      if (invoice.invoice_number?.toLowerCase().includes(searchTerm)) {
        return true;
      }

      // Search by date
      if (
        moment(invoice.invoice_date)
          .format("L")
          .toLowerCase()
          .includes(searchTerm)
      ) {
        return true;
      }

      // Search by description
      if (invoice.description?.toLowerCase().includes(searchTerm)) {
        return true;
      }

      // Search by amount
      const amountStr = invoice.amount_due?.toString().toLowerCase() || "";
      if (amountStr.includes(searchTerm)) {
        return true;
      }

      return false;
    });
  }, [filters.search, invoices?.data]);

  // First, let's modify the filteredData to respect pagination
  const paginatedData = useMemo(() => {
    const startIndex = paginationModel.page * paginationModel.pageSize;
    const endIndex = startIndex + paginationModel.pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, paginationModel.page, paginationModel.pageSize]);

  // Update the fetchAllPayments function to use the ref
  const fetchAllPayments = useCallback(
    async (force = false) => {
      // Skip if we've already fetched and not forcing
      if (hasFetchedPayments && !force && totalRef.current !== null) {
        // If we already have a total, use it
        setTotalPaymentsAmount(totalRef.current);
        return;
      }

      try {
        // Set loading states
        setIsLoadingAllPayments(true);
        setIsTotalLoading(true);

        // Only set total to null if we don't have a cached value
        if (totalRef.current === null) {
          setTotalPaymentsAmount(null);
        }

        // Function to fetch a page of payments
        const fetchPage = async (pageNum, accumulated = []) => {
          const action = await dispatch(
            getPayments({
              user_id: id,
              pageNumber: pageNum,
              pageSize: 100,
            })
          );

          const response = action.payload;
          if (!response || !response.data) {
            console.error("Invalid response format:", response);
            return accumulated;
          }

          const newItems = response.data || [];
          const combined = [...accumulated, ...newItems];

          // If we got a full page, fetch the next page
          if (newItems.length === 100) {
            return fetchPage(pageNum + 1, combined);
          }

          return combined;
        };

        // Start fetching from page 1
        const allPayments = await fetchPage(1);

        // Update state with all payments
        setAllPayments(allPayments);

        // Calculate total (only posted payments)
        const total = allPayments.reduce((sum, payment) => {
          if (payment.status === "Posted") {
            return sum + parseFloat(payment.amount || 0);
          }
          return sum;
        }, 0);

        // Store the total in the ref
        totalRef.current = total;

        // Update the total only after we have the correct data
        setTotalPaymentsAmount(total);
        setHasFetchedPayments(true);
        setIsLoadingAllPayments(false);
        setIsTotalLoading(false);

        console.log(`Fetched ${allPayments.length} payments, total: ${total}`);

        return allPayments;
      } catch (error) {
        console.error("Error fetching payments:", error);
        setIsLoadingAllPayments(false);
        setIsTotalLoading(false);
        return [];
      }
    },
    [dispatch, id, hasFetchedPayments]
  );

  // Modify the useEffect to avoid unnecessary fetches
  useEffect(() => {
    if (id) {
      // Only reset and force fetch if we don't have a cached total
      if (totalRef.current === null) {
        setHasFetchedPayments(false);
        fetchAllPayments(true);
      } else {
        // If we have a cached total, use it
        setTotalPaymentsAmount(totalRef.current);
      }
    }
  }, [id, fetchAllPayments]);

  // Modify the visibility change handler
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && id) {
        // Only force fetch if it's been more than 5 minutes since the last fetch
        const shouldFetch =
          !hasFetchedPayments ||
          (lastFetchTimeRef.current &&
            Date.now() - lastFetchTimeRef.current > 5 * 60 * 1000);

        if (shouldFetch) {
          fetchAllPayments(true);
        } else if (totalRef.current !== null) {
          // If we have a cached total, use it
          setTotalPaymentsAmount(totalRef.current);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [fetchAllPayments, id, hasFetchedPayments]);

  // Add a function to refresh the data
  const refreshData = useCallback(() => {
    fetchAllPayments();
  }, [fetchAllPayments]);

  return (
    <StyledBox
      sx={{
        borderRadius: "18px",
        overflow: "visible",
        position: "relative",
      }}
    >
      <Box
        className={classes.gridViewSection}
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "calc(100vh - 270px)",
          borderRadius: "18px !important",
          backgroundColor: "#FFFFFF !important",
          background: "#FFFFFF !important",
          position: "relative !important",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            "& .MuiDataGrid-root": {
              border: "none !important",
              flex: 1,
            },
            "& .MuiDataGrid-main": {
              flex: "1 1 auto",
              display: "flex",
              flexDirection: "column",
            },
            "& .MuiDataGrid-virtualScroller": {
              flex: "1 1 auto",
            },
          }}
        >
          <DataGridPro
            rows={paginatedData}
            loading={invoices.isLoading}
            onRowClick={(data) => {}}
            onRowsScrollEnd={(e) => {
              if (invoices?.count > e.visibleRowsCount && !invoices.isLoading) {
                setFilters({
                  ...filters,
                  pageNumber: Number(e.visibleRowsCount / 25) + 1,
                  pageSize: 25,
                });
              }
            }}
            columns={columns}
            disableSelectionOnClick
            rowHeight={56}
            getRowHeight={(params) => {
              if (expandedRows[params.id] && params.row) {
                return getExpandedContentHeight(
                  params.row.charges || [],
                  params.row.payments || []
                );
              }
              return 56;
            }}
            sx={{
              border: "none !important",
              overflow: "visible !important",
              padding: "0 !important",
              "& .MuiDataGrid-main": {
                padding: "0 !important",
                overflow: "visible !important",
              },
              "& .MuiDataGrid-virtualScroller": {
                overflow: "visible !important",
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                overflow: "visible !important",
                paddingTop: "12px !important",
              },
              "& .expanded-content .MuiDataGrid-root": {
                "& .MuiDataGrid-virtualScrollerContent": {
                  paddingTop: "6px !important",
                  overflow: "visible !important",
                },
                "& .MuiDataGrid-columnHeadersInner": {
                  height: "40px !important",
                  background: "#EFEFEF !important",
                  marginTop: "16px !important",
                  borderRadius: "30px !important",
                },
                "& .MuiDataGrid-row": {
                  boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.06) !important",
                  borderRadius: "20px !important",
                  marginTop: "2px !important",
                  marginBottom: "2px !important",
                  background: "white !important",
                  minHeight: "40px !important",
                  maxHeight: "40px !important",
                },
              },
              "& .MuiDataGrid-columnHeaders": {
                background: "none !important",
                '& .MuiDataGrid-columnHeader[data-field="description"]': {
                  "& .MuiDataGrid-columnHeaderTitle": {
                    paddingRight: "34px !important",
                  },
                },
                '& .MuiDataGrid-columnHeader[data-field="amount"]': {
                  "& .MuiDataGrid-columnHeaderTitle": {
                    paddingLeft: "24px !important",
                  },
                },
              },
              "& .MuiDataGrid-columnHeadersInner": {
                height: "40px !important",
                background: "#EFEFEF !important",
                marginTop: "16px !important",
                borderRadius: "30px !important",
              },
              "& .MuiDataGrid-row": {
                position: "relative !important",
                background: "white !important",
                zIndex: "1 !important",
                overflow: "visible !important",
                boxShadow: "none !important",
                marginTop: "2px !important",
                marginBottom: "2px !important",
                "&.expanded-row": {
                  boxShadow: "none !important",
                },
                "&.expanded-row .MuiBox-root.css-199kaup": {
                  boxShadow: "none !important",
                },
              },
              "& .MuiDataGrid-footerContainer": {
                position: "static",
                bottom: 0,
                backgroundColor: "white",
                zIndex: 10,
              },
              "& .MuiDataGrid-columnSeparator": {
                display: "none !important",
              },
              "& .MuiDataGrid-columnHeader:first-of-type": {
                paddingLeft: "20px !important",
              },
              "& .MuiDataGrid-cell:first-of-type": {
                paddingLeft: "20px !important",
              },
              '& .MuiDataGrid-columnHeader[data-field="due_date"], & .MuiDataGrid-columnHeader[data-field="amt_due"], & .MuiDataGrid-columnHeader[data-field="inv_amt"], & .MuiDataGrid-columnHeader[data-field="actions"]':
                {
                  paddingLeft: "56px !important",
                },
              '& .MuiDataGrid-cell[data-field="due_date"], & .MuiDataGrid-cell[data-field="amt_due"], & .MuiDataGrid-cell[data-field="inv_amt"], & .MuiDataGrid-cell[data-field="actions"]':
                {
                  paddingLeft: "56px !important",
                },
              '& .MuiDataGrid-columnHeader:not(:first-of-type):not([data-field="due_date"]):not([data-field="amt_due"]):not([data-field="inv_amt"]):not([data-field="actions"])':
                {
                  paddingLeft: "24px !important",
                },
              '& .MuiDataGrid-cell:not(:first-of-type):not([data-field="due_date"]):not([data-field="amt_due"]):not([data-field="inv_amt"]):not([data-field="actions"])':
                {
                  paddingLeft: "24px !important",
                },
              "& .expanded-content .MuiDataGrid-root .MuiDataGrid-row": {
                boxShadow: "none !important",
                border: "1px solid rgba(0, 0, 0, 0.08) !important",
                borderRadius: "20px !important",
                margin: "2px 0 !important",
                background: "white !important",
                minHeight: "40px !important",
                maxHeight: "40px !important",
              },
              "& .description-cell, & .description-header": {
                paddingRight: "24px !important",
              },
              "& .amount-cell, & .amount-header": {
                paddingLeft: "24px !important",
              },
              '& .MuiDataGrid-columnHeader[data-field="description"]': {
                paddingRight: "24px !important",
              },
              '& .MuiDataGrid-columnHeader[data-field="amount"]': {
                paddingLeft: "24px !important",
              },
              "& .MuiDataGrid-cellWithRenderer:first-of-type": {
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  right: "8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "24px",
                  height: "24px",
                  backgroundImage:
                    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'/%3E%3C/svg%3E\")",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                  opacity: (theme) =>
                    theme.palette.mode === "light" ? 0.54 : 0.7,
                  pointerEvents: "auto",
                },
              },
            }}
            getRowClassName={(params) =>
              expandedRows[params.id] ? "expanded-row" : ""
            }
            components={{
              LoadingOverlay: TableSkeleton,
              Row: (props) => (
                <Box sx={{ margin: "5px 0 !important" }}>
                  <Box
                    className={`css-1ix50fd`}
                    sx={{
                      position: "relative",
                      overflow: "visible",
                      width: "100%",
                      margin: "0",
                      background: "white !important",
                      boxShadow: expandedRows[props.row.id]
                        ? "0px -10px 50px -10px rgba(0, 0, 0, 0.04) !important"
                        : "0px 10px 50px 0px rgba(0, 0, 0, 0.04) !important",
                      borderRadius: expandedRows[props.row.id]
                        ? "20px 20px 0 0 !important"
                        : "20px !important",
                      ...(expandedRows[props.row.id] && {
                        zIndex: 1,
                      }),
                    }}
                  >
                    <GridRow {...props} />
                    {expandedRows[props.row.id] && (
                      <>
                        <Box
                          sx={{
                            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                            margin: "0 20px",
                            position: "relative",
                            zIndex: 1,
                          }}
                        />
                        <Box
                          className="expanded-content"
                          sx={{
                            padding: "20px",
                            paddingBottom: "20px !important",
                            background: "white !important",
                            boxShadow:
                              "0px 10px 50px 0px rgba(0, 0, 0, 0.04) !important",
                            borderRadius: "0 0 20px 20px !important",
                            marginTop: "-20px !important",
                            paddingTop: "20px !important",
                            position: "relative",
                            zIndex: 0,
                            height:
                              props?.row?.charges || props?.row?.payments
                                ? `${getExpandedContentHeight(
                                    props?.row?.charges,
                                    props?.row?.payments
                                  )}px`
                                : "auto",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Typography className={classes.subHeading}>
                                Charges
                              </Typography>
                              <Box
                                sx={{
                                  width: "100%",
                                  maxWidth: "95%",
                                  width: "95%",
                                  "& .MuiDataGrid-root": {
                                    width: "100%",
                                    "& .MuiDataGrid-row": {
                                      border:
                                        "1px solid rgba(0, 0, 0, 0.08) !important",
                                      borderRadius: "20px !important",
                                      margin: "2px 0 !important",
                                      background: "white !important",
                                      minHeight: "40px !important",
                                      maxHeight: "40px !important",
                                      boxShadow: "none !important",
                                      "& .MuiDataGrid-cell": {
                                        boxShadow: "none !important",
                                        minHeight: "40px !important",
                                        maxHeight: "40px !important",
                                        lineHeight: "40px !important",
                                      },
                                      "& .MuiBox-root": {
                                        boxShadow: "none !important",
                                      },
                                    },
                                    "& .css-1ix50fd": {
                                      boxShadow: "none !important",
                                    },
                                    "& .css-199kaup": {
                                      boxShadow: "none !important",
                                    },
                                  },
                                }}
                              >
                                <DataGridPro
                                  rows={
                                    props?.row?.charges?.length
                                      ? props?.row?.charges
                                      : []
                                  }
                                  columns={chargesColumns}
                                  hideFooter={true}
                                  autoHeight
                                  rowHeight={40}
                                  disableColumnMenu
                                  components={{
                                    NoRowsOverlay: () => null,
                                  }}
                                  sx={{
                                    border: "none !important",
                                    "& .expanded-content .MuiDataGrid-root .MuiDataGrid-row":
                                      {
                                        boxShadow: "none !important",
                                        border:
                                          "1px solid rgba(0, 0, 0, 0.08) !important",
                                        borderRadius: "20px !important",
                                        margin: "2px 0 !important",
                                        background: "white !important",
                                        minHeight: "40px !important",
                                        maxHeight: "40px !important",
                                      },
                                    '& .MuiDataGrid-cell[data-field="description"]':
                                      {
                                        paddingRight: "24px !important",
                                      },
                                    '& .MuiDataGrid-cell[data-field="amount"]':
                                      {
                                        paddingLeft: "24px !important",
                                      },
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.subHeading}>
                                Payments
                              </Typography>
                              <Box
                                sx={{
                                  width: "100%",
                                  "& .MuiDataGrid-root": {
                                    width: "100%",
                                    "& .MuiDataGrid-row": {
                                      border:
                                        "1px solid rgba(0, 0, 0, 0.08) !important",
                                      borderRadius: "20px !important",
                                      margin: "2px 0 !important",
                                      background: "white !important",
                                      minHeight: "40px !important",
                                      maxHeight: "40px !important",
                                      boxShadow: "none !important",
                                      "& .MuiDataGrid-cell": {
                                        boxShadow: "none !important",
                                        minHeight: "40px !important",
                                        maxHeight: "40px !important",
                                        lineHeight: "40px !important",
                                      },
                                      "& .MuiBox-root": {
                                        boxShadow: "none !important",
                                      },
                                    },
                                    "& .css-1ix50fd": {
                                      boxShadow: "none !important",
                                    },
                                    "& .css-199kaup": {
                                      boxShadow: "none !important",
                                    },
                                  },
                                }}
                              >
                                <DataGridPro
                                  rows={
                                    props?.row?.payments?.filter(
                                      (pi) =>
                                        // Only show payments that still exist
                                        pi?.payment && !pi?.payment?.deleted_at
                                    ) || []
                                  }
                                  columns={paymentsColumns}
                                  hideFooter={true}
                                  autoHeight
                                  rowHeight={52}
                                  disableColumnMenu
                                  components={{
                                    NoRowsOverlay: () => null,
                                  }}
                                  sx={{
                                    border: "none !important",
                                    "& .MuiDataGrid-row": {
                                      boxShadow: "none !important",
                                    },
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              ),
              ColumnSeparator: () => null,
              DetailPanelToggleCell: (props) => {
                // Get the row data
                const row = props.row;

                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* This preserves the original expand button */}
                    <div>
                      {React.createElement(
                        props.components.DetailPanelToggleCell,
                        props
                      )}
                    </div>

                    {/* Add our menu button */}
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setInvoiceData(row);
                        setMenuPosition({ top: e.clientY, left: e.clientX });
                        setAnchorEl(e.currentTarget);
                      }}
                      disabled={row.status === "Paid" || row.status === "paid"}
                      size="small"
                      sx={{ padding: 0, ml: 1 }}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </div>
                );
              },
            }}
            disableColumnResize={true}
            pagination={false}
            hideFooter={true}
            onCellClick={(params, event) => {
              // Only handle clicks in the first column (detail panel column)
              if (params.field === "__detail" || params.field === "detail") {
                // Check if the click was on the right side of the cell (where our menu icon is)
                const cellRect = event.currentTarget.getBoundingClientRect();
                const clickX = event.clientX;

                // If click is within 32px of the right edge of the cell
                if (clickX > cellRect.right - 32) {
                  event.stopPropagation(); // Prevent row expansion
                  setInvoiceData(params.row);
                  setMenuPosition({ top: event.clientY, left: event.clientX });
                  setAnchorEl(event.currentTarget);
                }
              }
            }}
          />
        </Box>
        <Box
          sx={{
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            padding: "8px 16px",
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <TablePagination
            component="div"
            count={filteredData.length}
            page={paginationModel.page}
            onPageChange={(e, newPage) =>
              setPaginationModel({ ...paginationModel, page: newPage })
            }
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={(e) =>
              setPaginationModel({
                page: 0,
                pageSize: parseInt(e.target.value, 25),
              })
            }
            sx={{
              ".MuiTablePagination-toolbar": {
                minHeight: "40px",
                paddingLeft: "8px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  margin: 0,
                },
            }}
          />
        </Box>
      </Box>
      <UpdateInvoice
        open={openUpdate}
        isUpdate={true}
        setOpen={setOpenUpdate}
        invoiceData={invoiceData}
        id={id}
      />
      <VoidInvoice
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleConfirm={handleConfirmVoid}
        title={`Void Invoice #${invoiceData?.invoice_number}`}
        loading={updateInvoice.isLoading}
      />
      <DeleteDraft
        open={deleteConfirm}
        setOpen={setDeleteConfirm}
        handleConfirm={handleConfirmDelete}
        title={`Delete Invoice #${invoiceData?.invoice_number}`}
        loading={delInvoice.isLoading}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <ConfirmModal
        open={openAlert}
        setOpen={setOpenAlert}
        title="Send Confirmation"
        content="Are you sure you want to send the alert against this invoice?"
        handleConfirm={handleConfirm}
        loading={invoiceAlert.isLoading}
      />
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        PaperProps={{
          sx: {
            mt: 3,
            ml: -5,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
          },
        }}
      >
        {invoiceData?.status === "draft" ? (
          <>
            <MenuItem
              onClick={() => {
                setOpenUpdate(true);
                setAnchorEl(null);
              }}
            >
              Edit Draft
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDeleteConfirm(true);
                setAnchorEl(null);
              }}
            >
              Delete Draft
            </MenuItem>
          </>
        ) : (
          invoiceData?.status === "outstanding" && (
            <>
              <MenuItem
                onClick={() => {
                  setOpenConfirm(true);
                  setAnchorEl(null);
                }}
              >
                Void Invoice
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenUpdate(true);
                  setAnchorEl(null);
                }}
              >
                Edit Invoice
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenAlert(true);
                  setAnchorEl(null);
                }}
              >
                Send New Invoice Alert
              </MenuItem>
            </>
          )
        )}
      </Menu>
      <ViewInvoice
        open={isViewInvoiceOpen}
        setOpen={setIsViewInvoiceOpen}
        pdfUrl={selectedInvoicePdf}
        invoiceNumber={selectedInvoiceNumber}
        classes={classes}
      />
    </StyledBox>
  );
};

export default InvoicesTable;
