import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  ListItemButton,
  Collapse,
  ListItemIcon,
  CardContent,
  Skeleton,
  Card,
  CardHeader,
} from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SubdirectoryArrowRightRoundedIcon from "@mui/icons-material/SubdirectoryArrowRightRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
function History(props) {
  const { classes, formData } = props;
  const [open, setOpen] = useState([]);
  const formHistory = useSelector((item) => item.Docs.formHistory);
  const users = useSelector((item) => item.users?.storedListOfUsers?.list);
  const handleOpenSubmitView = (id) => {
    if (!open?.includes(id)) {
      setOpen((preOpen) => [...preOpen, id]);
    } else {
      setOpen((preOpen) => [...preOpen]?.filter((it) => it !== id));
    }
  };
  console.log(users, "users");
  return (
    <Box className={classes.historySection}>
      {" "}
      <Box className={classes.pageHeading}>
        <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
          {" "}
          {formData?.forms_data?.name}
        </Typography>
      </Box>
      <Box className={classes.historyArea}>
        {formHistory?.isLoading ? (
          <Box>
            {" "}
            {Array.from(Array(2).keys())?.map((_, i) => (
              <Card key={i} sx={{ mb: "20px" }}>
                <Skeleton
                  sx={{ height: 36 }}
                  animation="wave"
                  variant="rectangular"
                />
                <CardHeader
                  avatar={
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  }
                  title={
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                  subheader={
                    <Skeleton animation="wave" height={10} width="40%" />
                  }
                />{" "}
                <CardContent>
                  <React.Fragment>
                    <Skeleton
                      animation="wave"
                      height={10}
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton animation="wave" height={10} width="80%" />
                  </React.Fragment>
                </CardContent>
                <Skeleton
                  sx={{ height: 80 }}
                  animation="wave"
                  variant="rectangular"
                />
                <CardContent>
                  <React.Fragment>
                    <Skeleton
                      animation="wave"
                      height={10}
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton animation="wave" height={10} width="80%" />
                  </React.Fragment>
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : formHistory?.data?.length ? (
          formHistory?.data?.map((item, index) => {
            let userData = users?.find((it) => it.id === item.created_by);
            return (
              <Box key={index} className={classes.historyAreaItem}>
                <Box className={classes.historyHeader}>
                  <Typography className={classes.historyHeaderContent}>
                    Smart Form - {item?.action}
                  </Typography>
                </Box>
                <Typography className={classes.dateContent}>
                  {moment(item?.created_on).format("LLL")}
                </Typography>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={userData?.profile_images?.profile_img} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${userData?.first_name} ${userData?.last_name}`}
                    secondary="Owner"
                  />
                </ListItem>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItemButton
                    onClick={() => handleOpenSubmitView(item?.id)}
                  >
                    <ListItemIcon sx={{ minWidth: "36px" }}>
                      <ListAltRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Entities" />
                    {open?.includes(item?.id) ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={open?.includes(item?.id) ? true : false}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item?.entities?.map((subItem, index) => (
                        <ListItem
                          disableGutters
                          dense
                          key={index}
                          sx={{ pl: 2 }}
                        >
                          <ListItemIcon sx={{ minWidth: "36px" }}>
                            <SubdirectoryArrowRightRoundedIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={subItem?.title}
                            secondary={`Action:${subItem?.action}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </List>
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <center>
                {" "}
                <ErrorOutlineRoundedIcon sx={{ fontSize: "70px" }} />
              </center>
              <br />
              Not exists any history
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default History;
