import React, { useEffect, useState, useMemo, useCallback } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import {
  Box,
  Container,
  Grid,
  Card,
  Typography,
  Skeleton,
  IconButton,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import PaymentsTable from "./paymentTable";
import { useDispatch, useSelector } from "react-redux";
import { NumberFormat } from "../../utils/numberFormat";
import PostPayment from "./modal/postPayment";
import { getPayments } from "../../redux/payments/getPayments";
import SearchIcon from "@mui/icons-material/Search";

function Payments(props) {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [showPendingOnly, setShowPendingOnly] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Add our new state variables for calculations
  const [pendingCount, setPendingCount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [postedAmount, setPostedAmount] = useState(0);
  const [isCalculating, setIsCalculating] = useState(false);

  const dispatch = useDispatch();
  const payments = useSelector((state) => state.Payments.payments);

  // Add a function to fetch payments and recalculate totals
  const fetchPaymentsAndCalculate = useCallback(() => {
    console.log("Fetching payments and recalculating totals");
    dispatch(getPayments({}));
  }, [dispatch]);

  // Initial data fetch
  useEffect(() => {
    fetchPaymentsAndCalculate();
  }, [fetchPaymentsAndCalculate]);

  // Add our calculation function
  const calculateTotals = useCallback((paymentsData) => {
    if (!paymentsData || !paymentsData.length) {
      console.log("No payments to calculate totals from");
      setPendingCount(0);
      setPendingAmount(0);
      setPostedAmount(0);
      return;
    }

    let tempPendingCount = 0;
    let tempPendingAmount = 0;
    let tempPostedAmount = 0;

    paymentsData.forEach((payment) => {
      const status = payment.status?.toLowerCase();
      const amount = parseFloat(payment.amount || 0);

      if (isNaN(amount)) {
        return; // Skip invalid amounts
      }

      if (!status || status === "pending") {
        tempPendingCount++;
        tempPendingAmount += amount;
      } else if (status === "posted") {
        tempPostedAmount += amount;
      }
    });

    console.log("Payment calculations:", {
      pendingCount: tempPendingCount,
      pendingAmount: tempPendingAmount,
      postedAmount: tempPostedAmount,
    });

    setPendingCount(tempPendingCount);
    setPendingAmount(tempPendingAmount);
    setPostedAmount(tempPostedAmount);
  }, []);

  // Calculate totals when payments data changes
  useEffect(() => {
    if (payments?.data) {
      setIsCalculating(true);
      calculateTotals(payments.data);
      setIsCalculating(false);
    }
  }, [payments.data, calculateTotals]);

  const handlePendingFilterClick = () => {
    setShowPendingOnly(!showPendingOnly);
  };

  const filteredPayments = useMemo(() => {
    if (!payments?.data) return [];

    let filtered = payments.data;

    // Add console.log to see the data structure
    console.log("First payment record:", filtered[0]);

    // Apply pending filter if active
    if (showPendingOnly) {
      filtered = filtered.filter(
        (row) => row.status?.toLowerCase() === "pending"
      );
    }

    // Apply search filter if there's a query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (row) =>
          row.type?.toLowerCase().includes(query) ||
          row.users_v2?.first_name?.toLowerCase().includes(query) ||
          row.users_v2?.last_name?.toLowerCase().includes(query) ||
          `${row.users_v2?.first_name || ""} ${row.users_v2?.last_name || ""}`
            .toLowerCase()
            .includes(query) ||
          row.description?.toLowerCase().includes(query) ||
          row.amount?.toString().startsWith(query.replace(/\$/g, "")) // Remove $ if present in search
      );
    }

    return filtered;
  }, [payments.data, showPendingOnly, searchQuery]);

  return (
    <Box className={classes.pageRoot}>
      <Container>
        {" "}
        <Grid container spacing={2} sx={{ mb: "16px" }}>
          <Grid item xs={12} md={3}>
            <Card
              className={classes.statCard}
              onClick={handlePendingFilterClick}
              sx={{
                cursor: "pointer",
                bgcolor: showPendingOnly ? "rgba(0, 0, 0, 0.04)" : "#FFFFFF",
                "&:hover": {
                  bgcolor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <Box className={classes.topStatCard}>
                <Typography className={classes.statCardTitle}>
                  Pending Payments
                </Typography>
                <IconButton
                  sx={{
                    mt: "-5px",
                    mr: "-5px",
                    color: showPendingOnly ? "primary.main" : "#c2c2c2",
                  }}
                  size="small"
                >
                  <FilterAltRoundedIcon />
                </IconButton>
              </Box>
              <Typography className={classes.statCardValue}>
                {payments.isLoading || isCalculating ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1.5rem",
                      display: "inline-block",
                      width: "150px",
                    }}
                  />
                ) : (
                  pendingCount.toString() // Use our calculated value
                )}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={classes.statCard}>
              <Box className={classes.topStatCard}>
                <Typography className={classes.statCardTitle}>
                  Pending Amount
                </Typography>
              </Box>
              <Typography className={classes.statCardValue}>
                {" "}
                {payments.isLoading || isCalculating ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1.5rem",
                      display: "inline-block",
                      width: "150px",
                    }}
                  />
                ) : (
                  NumberFormat({
                    number: pendingAmount, // Use our calculated value
                    maximumFractionDigits: 2,
                    currency: "USD",
                  })
                )}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={classes.statCard}>
              <Box className={classes.topStatCard}>
                <Typography className={classes.statCardTitle}>
                  Posted Total (YTD)
                </Typography>
              </Box>
              <Typography className={classes.statCardValue}>
                {payments.isLoading || isCalculating ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1.5rem",
                      display: "inline-block",
                      width: "150px",
                    }}
                  />
                ) : (
                  NumberFormat({
                    number: postedAmount, // Use our calculated value
                    maximumFractionDigits: 2,
                    currency: "USD",
                  })
                )}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box className={classes.manageButtons}>
              <Button
                color="inherit"
                variant="contained"
                className={classes.postPaymentButton}
                onClick={() => setOpen(true)}
              >
                Post Payment
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by type, agent name, description or amount..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              sx: { bgcolor: "white" },
            }}
          />
        </Box>
        <PaymentsTable
          {...props}
          setSelectedPayments={setSelectedPayments}
          selectedPayments={selectedPayments}
          filteredPayments={filteredPayments}
          onPaymentPosted={fetchPaymentsAndCalculate} // Pass our function to refresh data and recalculate
        />
      </Container>
      <PostPayment
        open={open}
        setOpen={setOpen}
        setSelectedPayments={setSelectedPayments}
        selectedPayments={selectedPayments}
        onPaymentPosted={fetchPaymentsAndCalculate} // Also pass to PostPayment component
        {...props}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "PaymentsStyle" })(Payments);
