import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";

function VoidInvoice(props) {
  const { open, classes, setOpen, loading, handleConfirm, title } = props;
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});
  const [sendAlert, setSendAlert] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleVoid = () => {
    if (reason) {
      handleConfirm({ reason, sendAlert });
    } else {
      setErrors({ reason: "Please enter the reason" });
    }
  };
  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" } }}
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="create-invoice-dialog-title"
      aria-describedby="create-invoice-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.modalHeader}>
        <Typography className={classes.modalHeading}>{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: "24px", mb: "20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                checked={sendAlert}
                onChange={(e) => setSendAlert(e.target.checked)}
              />
            }
            label="Send Voided Invoice Alert"
            color="inherit"
          />
        </Box>
        <InputLabel className={classes.InputLabel}>
          Reason for Voiding
        </InputLabel>
        <TextField
          fullWidth
          size="small"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          error={errors.reason}
          helperText={errors.reason}
        />
      </DialogContent>
      <DialogActions>
        {" "}
        <Box className={classes.buttonSection}>
          <LoadingButton
            variant="contained"
            color="inherit"
            className={classes.createButton}
            loadingPosition="start"
            loading={loading}
            onClick={handleVoid}
            disabled={loading}
          >
            Void invoice
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
export default withStyles(styleSheet, { name: "VoidInvoiceStyle" })(
  VoidInvoice
);
