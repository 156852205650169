import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getLeadsListAPI } from "./getLeadsListAPI";
import { updateLeadProfileAPI } from "./updateLeadProfileAPI";
import { getLeadsSmartList } from "./getAllSmartLists";
import {
  addLeadSmartList,
  updateLeadSmartList,
} from "./addUpdateLeadSmartList";

const initialState = {
  smartList: [],
  smartListLoading: true,
  sorting: {},
  filters: [],
  allFilteredLeadsIds: [],
  leadIdsLoading: false,
  columnsVisibility: {},
  columnsOrder: [
    "name",
    "phone",
    "emails",
    "stage",
    "assignedTo",
    "activity",
    "created",
  ],
  list: {
    data: [],
    metadata: {
      total: 0,
      page: 1,
      limit: 25
    },
    status: {
      pending: false,
      success: false,
      error: false,
    },
  },
  modalActions: {
    loading: false,
    success: false,
    errMsg: null,
  },
  deleteList: {
    loading: false,
    success: null,
    errMsg: null,
  },
};

export const getLeadsIdsAfterFilter = createAsyncThunk(
  "leads/getLeadsIdsAfterFilter",
  async (data, thunkAPI) => {
    const param = `?pageSize=${data?.pageSize || ""}&pageNumber=${
      data?.pageNumber || ""
    }&sortBy=${data?.sortBy || ""}&sortOrder=${data?.sortOrder || ""}&search=${
      data?.search || ""
    }&filters=${data?.filters || ""}`;
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/leads/people/leads-all-ids-filtered${param}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );

      return { ...resp.data, resetList: data?.resetList };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteLeadsSmartList = createAsyncThunk(
  "leads/deleteLeadsSmartList",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/smartlists/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );

      return { id: data.id, smartList: data.smartList, ...resp.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
const leadList = createSlice({
  name: "leadList",
  initialState,
  reducers: {
    modalSubmitSuccess: (state, action) => {
      state.modalActions = { ...initialState.modalActions };
      state.deleteList = { ...initialState.deleteList };
    },
    setPeopleColumnsOrder: (state, action) => {
      state.columnsOrder = action.payload;
    },
    setPeopleColumnsVisibility: (state, action) => {
      state.columnsVisibility = action.payload;
    },
    setPeopleFilter: (state, action) => {
      state.filters = action.payload || [];
    },
    setPeopleSort: (state, action) => {
      state.sorting = action.payload;
    },
  },
  extraReducers: {
    [getLeadsListAPI.pending]: (state) => {
      state.list.status.pending = true;
    },
    [getLeadsListAPI.fulfilled]: (state, action) => {
      console.log('Reducer received:', {
        currentDataLength: state.list.data.length,
        newDataLength: action.payload?.people?.length,
        resetList: action.payload?.resetList,
        metadata: action.payload?._metadata
      });

      if (action?.payload) {
        if (action.payload.resetList) {
          state.list.data = action.payload.people;
        } else {
          const newData = [...state.list.data];
          action.payload.people.forEach(person => {
            if (!newData.find(p => p.id === person.id)) {
              newData.push(person);
            }
          });
          state.list.data = newData;
        }
        
        state.list.metadata = action.payload._metadata;
      }

      console.log('Reducer result:', {
        finalDataLength: state.list.data.length,
        metadata: state.list.metadata
      });

      state.list.status.pending = false;
    },
    [getLeadsListAPI.rejected]: (state, action) => {
      state.list.status.pending = false;
      state.list.status.error = true;
    },

    [updateLeadProfileAPI.pending]: (state) => {
      state.list.status.pending = true;
    },
    [updateLeadProfileAPI.fulfilled]: (state, action) => {
      const objIndex = state.list.data.findIndex(
        (obj) => obj.id == action.payload.id
      );
      if (action?.payload) {
        state.list.data[objIndex] = action.payload;
      }
      state.list.status.pending = false;
    },
    [updateLeadProfileAPI.rejected]: (state, action) => {
      state.list.status.pending = false;
      state.list.status.error = true;
    },
    [getLeadsSmartList.pending]: (state) => {
      state.smartListLoading = true;
    },
    [getLeadsSmartList.fulfilled]: (state, action) => {
      state.smartListLoading = false;
      state.smartList = action.payload;
    },
    [getLeadsSmartList.rejected]: (state, action) => {
      state.smartListLoading = false;
      state.errMsg = action.payload;
    },
    [getLeadsIdsAfterFilter.pending]: (state) => {
      state.leadIdsLoading = true;
    },
    [getLeadsIdsAfterFilter.fulfilled]: (state, action) => {
      state.allFilteredLeadsIds = action.payload.ids;
      state.leadIdsLoading = false;
    },
    [addLeadSmartList.pending]: (state, action) => {
      state.modalActions.loading = true;
    },
    [addLeadSmartList.fulfilled]: (state, action) => {
      state.modalActions.loading = false;
      state.modalActions.success = true;
      state.smartList = [...state.smartList, action.payload.smartlist];
      state.errMsg = null;
    },
    [addLeadSmartList.rejected]: (state, action) => {
      state.modalActions.errMsg = action.payload;
    },
    [updateLeadSmartList.pending]: (state, action) => {
      state.modalActions.loading = true;
    },
    [updateLeadSmartList.fulfilled]: (state, action) => {
      state.modalActions.loading = false;
      state.modalActions.success = true;
      state.smartList = state.smartList.map((r) => {
        if (r.id == action.payload.id) {
          return action.payload.smartList;
        }
        return r;
      });
      state.errMsg = null;
    },
    [updateLeadSmartList.rejected]: (state, action) => {
      state.modalActions.errMsg = action.payload;
    },
    [deleteLeadsSmartList.pending]: (state) => {
      state.deleteList.loading = true;
    },
    [deleteLeadsSmartList.fulfilled]: (state, action) => {
      state.deleteList.loading = false;
      state.deleteList.success = true;
      state.smartList = action.payload.smartList.filter(
        (item) => item.id !== action.payload.id
      );
    },
    [deleteLeadsSmartList.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
    },
  },
});
export const {
  setPeopleColumnsOrder,
  setPeopleSort,
  setPeopleColumnsVisibility,
  setPeopleFilter,
  modalSubmitSuccess,
} = leadList.actions;
export default leadList.reducer;
