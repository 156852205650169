import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Checkbox,
  InputLabel,
  FormControlLabel,
} from "@mui/material";
function UpdateNameInfo(props) {
  const { classes, open, setOpen, updateDetail, handleConfirm } = props;
  const [isLegalEntity, setIsLegalEntity] = useState(false);
  const [detail, setDetail] = useState({ ...updateDetail });
  const handleClose = () => {
    setOpen(false);
    setIsLegalEntity(false);
  };
  useEffect(() => {
    if (open) {
      setDetail({ ...updateDetail });
    }
  }, [open]);
  const handleUpdateDetail = (field, value, index) => {
    let preData = [...detail.data];
    preData[index][field] = value;
    setDetail((pre) => ({ ...pre, data: preData }));
  };
  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" } }}
      open={open}
      onClose={handleClose}
      aria-labelledby="add-new-folder-model"
      aria-describedby="add-new-folder-model-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        {detail?.data?.map((item, index) => (
          <Grid key={item.first_name || index} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel2}>
                {" "}
                First Name
              </InputLabel>
              <TextField
                value={item.first_name}
                onChange={(e) =>
                  handleUpdateDetail("first_name", e.target.value, index)
                }
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel2}>
                {" "}
                Last Name
              </InputLabel>
              <TextField
                value={item.last_name}
                onChange={(e) =>
                  handleUpdateDetail("last_name", e.target.value, index)
                }
                size="small"
                fullWidth
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControlLabel
                sx={{
                  "& .MuiTypography-root": { fontSize: "14px", color: "back" },
                }}
                control={
                  <Checkbox
                    checked={isLegalEntity}
                    onChange={(e) => setIsLegalEntity(e.target.checked)}
                  />
                }
                label="Legal Entity Name"
              />
            </Grid>
            {isLegalEntity ? (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel2}>
                  Legal Entity Name
                </InputLabel>
                <TextField
                  value={item.legal_entity_name}
                  onChange={(e) =>
                    handleUpdateDetail(
                      "legal_entity_name",
                      e.target.value,
                      index
                    )
                  }
                  size="small"
                  fullWidth
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel2}>Email</InputLabel>
              <TextField
                value={item.email}
                onChange={(e) =>
                  handleUpdateDetail("email", e.target.value, index)
                }
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button
          className={classes.cancelButton}
          color="inherit"
          variant="outlined"
          size="small"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.updateButton}
          variant="contained"
          size="small"
          onClick={() => {
            handleConfirm(detail);
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateNameInfo;
