// Prevent ResizeObserver loop limit exceeded error
const reportError = console.error;

console.error = (...args) => {
  if (args[0]?.includes?.('ResizeObserver loop completed with undelivered notifications.')) {
    return;
  }
  reportError(...args);
};

// Optional: Add ResizeObserver polyfill if needed
if (!window.ResizeObserver) {
  window.ResizeObserver = class ResizeObserver {
    constructor(callback) {
      this.callback = callback;
    }
    observe() {}
    unobserve() {}
    disconnect() {}
  };
} 