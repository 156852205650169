export const styleSheet = () => ({
  pageRoot: {
    marginTop: "40px",
  },
  docDetailPage: {
    minHeight: "100vh",
  },
  doclist: {
    marginTop: "40px !important",
  },
  mainconatin: {
    maxWidth: "100% !important",
    background: "red",
  },
  dataTable: {
    margin: "50px 40px 0 40px",
    "& svg": {
      marginRight: "10px",
      display: "block",
      "& path": {
        fill: "rgba(0, 0, 0, 0.54)",
      },
    },

    "& img": {
      borderRadius: "5px",
    },
  },
  tableAlignment: {
    marginTop: "20px !important",
  },

  status: {
    border: "1px solid #ccc",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    padding: "5px 15px",
    justifyContent: "center",
    width: "auto !important",
    "& svg": {
      marginRight: "5px",
    },
  },
  mAlign: {
    alignItems: "center",
  },
});
