import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const voidInvoice = createAsyncThunk(
  "agentRoster/voidInvoice",
  async (
    { invoice_id, schema, handleSuccess, handleError },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/users/v2/tabs/void-invoice/${invoice_id}`,
        schema,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );
      handleSuccess();
      return response.data;
    } catch (error) {
      handleError(
        error?.response?.data?.errorMessage || "Error voiding invoice"
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);
