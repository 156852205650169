import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  Typography,
  Skeleton,
  Box,
  Grid,
  Button,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { styleSheet } from "../style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getInvoices,
  getPayments,
  getMarketingBudget,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import ButtonGroups from "../../../../../components/uiElements/buttonGroup";
import GenerateInvoice from "./generateInvoice.jsx";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import ApplyPayment from "./applyPayment.jsx";
import CreateInvoice from "./createInvoice.jsx";
import InvoicesTable from "./invoicesTable.jsx";
import { NumberFormat } from "../../../../../utils/numberFormat";
import PaymentsView from "./payments.jsx";
import MarketingBudget from "./marketingBudget.jsx";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const AgentRosterTabs = (props) => {
  const { id } = useParams();
  const { classes } = props;
  const dispatch = useDispatch();
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [openCreateInvoice, setOpenCreateInvoice] = useState(false);
  const [openPayments, setOpenPayments] = useState(false);
  const [openApplyPayment, setOpenApplyPayment] = useState(false);
  const [openAwardBudget, setOpenAwardBudget] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
    search: "",
  });
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [totalPaymentsAmount, setTotalPaymentsAmount] = useState(0);

  const payments = useSelector((state) => state.agentRoster.AgentTabs.payments);
  const invoices = useSelector((state) => state.agentRoster.AgentTabs.invoices);

  const isSmallScreen = useMediaQuery("(max-width:1580px)");
  const isVerySmallScreen = useMediaQuery("(max-width:1380px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:1324px)");
  console.log("isSmallScreen:", isSmallScreen);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (filters.search !== undefined) {
        dispatch(getInvoices({ user_id: id, ...filters }));
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [filters.search, dispatch, id, filters]);

  // Function to fetch all payments with pagination
  const fetchAllPayments = useCallback(async () => {
    try {
      setIsLoadingPayments(true);

      // Function to fetch a page of payments
      const fetchPage = async (pageNum, accumulated = []) => {
        const action = await dispatch(
          getPayments({
            user_id: id,
            pageNumber: pageNum,
            pageSize: 100,
          })
        );

        const response = action.payload;
        if (!response || !response.data) {
          return accumulated;
        }

        const newItems = response.data || [];
        const combined = [...accumulated, ...newItems];

        // If we got a full page, there might be more
        if (newItems.length === 100) {
          return fetchPage(pageNum + 1, combined);
        }

        return combined;
      };

      // Start fetching from page 1
      const allPayments = await fetchPage(1);

      // Calculate total from payments that aren't deleted
      const total = allPayments.reduce((sum, payment) => {
        if (
          payment.status !== "Deleted" &&
          payment.type !== "Marketing Budget"
        ) {
          return sum + parseFloat(payment.amount || 0);
        }
        return sum;
      }, 0);

      // Update the total
      setTotalPaymentsAmount(total);
      setIsLoadingPayments(false);
    } catch (error) {
      setIsLoadingPayments(false);
    }
  }, [dispatch, id]);

  // Fetch payments when the component mounts
  useEffect(() => {
    fetchAllPayments();
    dispatch(getMarketingBudget({ user_id: id }));
  }, [fetchAllPayments, dispatch, id]);

  // Only refresh payments when tab becomes visible if it's been more than 30 seconds
  const lastFetchTimeRef = useRef(Date.now());

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const now = Date.now();
        // Only refresh if it's been more than 30 seconds since the last fetch
        if (now - lastFetchTimeRef.current > 30000) {
          fetchAllPayments();
          lastFetchTimeRef.current = now;
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [fetchAllPayments]);

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mb: "16px" }}>
        <Grid item xs={12} md={3}>
          <Card className={classes.statCard}>
            <Box className={classes.topStatCard}>
              <Typography className={classes.statCardTitle}>
                Unpaid Total
              </Typography>
              {/* <IconButton sx={{ mt: "-5px", mr: "-5px" }} size="small">
                {" "}
                <FilterAltRoundedIcon sx={{ color: "#c2c2c2" }} />
              </IconButton> */}
            </Box>
            <Typography className={classes.statCardValue}>
              {invoices.isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1.5rem",
                    display: "inline-block",
                    width: "150px",
                  }}
                />
              ) : invoices?.unpaidAmount ? (
                NumberFormat({
                  number: invoices?.unpaidAmount,
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              ) : (
                "$0.00"
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            onClick={() => setOpenAwardBudget(true)}
            className={classes.statCard}
            sx={{ cursor: "pointer" }}
          >
            <Box className={classes.topStatCard}>
              <Typography className={classes.statCardTitle}>
                Marketing Budget
              </Typography>
              <CropFreeRoundedIcon sx={{ color: "#c2c2c2" }} />
            </Box>
            <Typography className={classes.statCardValue}>
              {" "}
              {invoices.isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1.5rem",
                    display: "inline-block",
                    width: "150px",
                  }}
                />
              ) : invoices?.marketingBudgetTotal ? (
                NumberFormat({
                  number: invoices?.marketingBudgetTotal,
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              ) : (
                "$0.00"
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            className={classes.statCard}
            onClick={() => setOpenPayments(true)}
            sx={{ cursor: "pointer" }}
          >
            <Box className={classes.topStatCard}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography className={classes.statCardTitle}>
                  Total Payment
                </Typography>
                <Tooltip
                  title="Excludes Marketing Budget Payments"
                  placement="top"
                >
                  <HelpOutlineIcon
                    sx={{
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.38)",
                      cursor: "help",
                      mt: "-1px",
                    }}
                  />
                </Tooltip>
              </Box>
              <CropFreeRoundedIcon sx={{ color: "#c2c2c2" }} />
            </Box>
            <Typography className={classes.statCardValue}>
              {isLoadingPayments ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem", width: "150px" }}
                />
              ) : (
                NumberFormat({
                  number: totalPaymentsAmount,
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              height: "100%",
            }}
          >
            <Card
              className={classes.statCard}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "8px 16px",
                gap: "8px",
                height: "58px !important",
                minHeight: "58px !important",
                maxHeight: "58px !important",
                "& .MuiCardContent-root": {
                  padding: "0 !important",
                },
              }}
            >
              <Button
                color="inherit"
                variant="outlined"
                className={classes.createInvoiceBTN}
                onClick={() => setOpenCreateInvoice(true)}
                sx={{
                  height: "30px",
                  width: "25%",
                  color: "#666",
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  "&:hover": {
                    borderColor: "#666 !important",
                    backgroundColor: "#EFEFEF !important",
                  },
                }}
              >
                {isExtraSmallScreen ? "Invoice" : "Create Invoice"}
              </Button>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => setOpenGenerateModal(true)}
                sx={{
                  height: "30px",
                  width: "65%",
                  color: "white",
                  backgroundColor: "#0B0909",
                  borderRadius: "20px",
                  fontSize: "12px",
                  textTransform: "uppercase",
                  "&:hover": {
                    backgroundColor: "#333333",
                  },
                }}
              >
                {isVerySmallScreen
                  ? "Statement"
                  : isSmallScreen
                  ? "Gen Statement"
                  : "Generate Statement"}
              </Button>
            </Card>

            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <TextField
                placeholder="Search by #, date, desc, or amount"
                variant="outlined"
                fullWidth
                size="small"
                value={filters.search || ""}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                    pageNumber: 1,
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <Box
                      component="span"
                      sx={{
                        color: "#666",
                        mr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SearchIcon fontSize="small" />
                    </Box>
                  ),
                  sx: {
                    borderRadius: "20px",
                    height: "38px",
                    padding: "0 12px",
                    color: "#666",
                    borderColor: "#E0E0E0",
                    backgroundColor: "#FFFFFF",
                    "&:hover": {
                      borderColor: "#666",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E0E0E0",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#666",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#666",
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <InvoicesTable
        filters={filters}
        setFilters={setFilters}
        id={id}
        {...props}
      />

      <GenerateInvoice
        open={openGenerateModal}
        setOpen={setOpenGenerateModal}
        id={id}
      />
      <CreateInvoice
        open={openCreateInvoice}
        setOpen={setOpenCreateInvoice}
        id={id}
      />
      <ApplyPayment
        open={openApplyPayment}
        setOpen={setOpenApplyPayment}
        id={id}
      />
      <PaymentsView
        {...props}
        open={openPayments}
        setOpen={setOpenPayments}
        setOpenApplyPayment={setOpenApplyPayment}
        id={id}
      />
      <MarketingBudget
        open={openAwardBudget}
        setOpen={setOpenAwardBudget}
        id={id}
        {...props}
      />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "AgentRosterTabsStyle",
})(AgentRosterTabs);
