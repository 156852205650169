import React, { useState, useEffect, Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  IconButton,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UsersSelection from "../docsListing/addFolder/usersSelection";
import {
  addBuyerToTransaction,
  addSellerToTransaction,
} from "../../../redux/docs";
import { useDispatch } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
function AddUserOnTransaction(props) {
  const { classes, open, setOpen, editDetail, id } = props;
  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateDetail, setUpdateDetail] = useState({});
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [detail, setDetail] = useState({ ...updateDetail });

  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setUsers([]);
  };

  const handleSuccess = () => {
    handleClose();
    setErrorAlert({
      errorMsg: "You have successfully added the buyer",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleAdd = () => {
    if (
      editDetail?.type === "Buyer" ||
      (editDetail?.type === "add" && users?.length)
    ) {
      dispatch(
        addBuyerToTransaction({
          id,
          schema: users[0],
          handleSuccess,
        })
      );
    }
    if (
      editDetail?.type === "Seller" ||
      (editDetail?.type === "add" && users?.length)
    ) {
      dispatch(
        addSellerToTransaction({
          id,
          schema: users[0],
          handleSuccess,
        })
      );
    }
  };

  console.log(users, "users");
  return (
    <Fragment>
      {" "}
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="add-new-folder-model"
        aria-describedby="add-new-folder-model-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            {editDetail?.action} {editDetail?.type}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {editDetail?.type === "Buyer" || editDetail?.type === "Seller" ? (
            <UsersSelection
              type={`${editDetail?.type}`}
              label={`${editDetail?.type} Name`}
              preOptions={[]}
              handleSelect={(value) => {
                setUsers(value);
                setErrors((preErrors) => ({
                  ...preErrors,
                  useGridRowSelection: "",
                }));
              }}
              value={users}
              errors={errors}
              disabled={false}
              setOpenUpdate={setOpenUpdate}
              setUpdateDetail={setUpdateDetail}
              labelType={editDetail?.type}
              multiple={true}
              {...props}
            />
          ) : null}
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            className={classes.cancelButton}
            color="inherit"
            variant="outlined"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.updateButton}
            variant="contained"
            size="small"
            onClick={handleAdd}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}

export default AddUserOnTransaction;
