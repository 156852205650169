import React, { useState, useEffect, Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import {
  updateBuyer,
  updateSeller,
  updateBuyerBroker,
  updateSellerBroker,
} from "../../../redux/docs";
import LoadingButton from "@mui/lab/LoadingButton";

function ViewUserDetail(props) {
  const { classes, open, setOpen, editDetail, id } = props;
  const [editable, setEditAble] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();

  const updateBuy = useSelector((item) => item.Docs.updateBuyer);
  const updateSel = useSelector((item) => item.Docs.updateSeller);
  const updateBuyerBro = useSelector((item) => item.Docs.updateBuyerBroker);
  const updateSellerBro = useSelector((item) => item.Docs.updateSellerBroker);
  const handleClose = () => {
    setOpen(false);
    setEditAble(false);
  };

  const handleUpdateDetail = (field, value) => {
    setUserDetail((preDetail) => ({
      ...preDetail,
      [field]: value,
    }));
  };
  const handleSuccess = () => {
    handleClose();
    setErrorAlert({
      errorMsg: "Updated Successfully",
      errorType: "success",
      isOpen: true,
    });
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };
  const handleUpdate = () => {
    if (editDetail?.type === "Buyer") {
      // update broker
      dispatch(
        updateBuyer({
          buyerId: editDetail?.preDetail?.id,
          id,
          schema: {
            ...userDetail,
            lead_id: `${editDetail?.preDetail?.lead_id}`,
          },
          handleSuccess,
          handleError,
        })
      );
    }
    if (editDetail?.type === "Seller") {
      // update Seller
      dispatch(
        updateSeller({
          sellerId: editDetail?.preDetail?.id,
          id,
          schema: {
            ...userDetail,
            lead_id: `${editDetail?.preDetail?.lead_id}`,
          },
          handleSuccess,
          handleError,
        })
      );
    }
    if (editDetail?.type === "Buyer Broker") {
      // update Buyer Broker
      dispatch(
        updateBuyerBroker({
          buyerBrokerId: editDetail?.preDetail?.id,
          id,
          schema: {
            ...userDetail,
            lead_id: editDetail?.preDetail?.lead_id
              ? `${editDetail?.preDetail?.lead_id}`
              : undefined,
          },
          handleSuccess,
          handleError,
        })
      );
    }
    if (editDetail?.type === "Seller Broker") {
      // update Buyer Broker
      dispatch(
        updateSellerBroker({
          sellerBrokerId: editDetail?.preDetail?.id,
          id,
          schema: {
            ...userDetail,
            lead_id: editDetail?.preDetail?.lead_id
              ? `${editDetail?.preDetail?.lead_id}`
              : undefined,
          },
          handleSuccess,
          handleError,
        })
      );
    }
  };

  useEffect(() => {
    if (open && editDetail?.preDetail?.id) {
      let newDetail = { ...editDetail?.preDetail };
      delete newDetail.id;
      delete newDetail.folder_id;
      delete newDetail.lead_id;
      setUserDetail({
        ...newDetail,
      });
      if (editDetail?.isEditable) {
        setEditAble(true);
      }
    }
  }, [open]);

  return (
    <Fragment>
      {" "}
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="add-new-folder-model"
        aria-describedby="add-new-folder-model-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            {editable ? editDetail?.action : null} {editDetail?.type} Detail
          </Typography>
          <Box>
            {editable ? (
              <IconButton
                title="View Detail"
                onClick={() => setEditAble(false)}
              >
                <BadgeOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton title="Edit Detail" onClick={() => setEditAble(true)}>
                <ModeEditOutlineOutlinedIcon />
              </IconButton>
            )}

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {Object.entries(userDetail)?.length
              ? Object.entries(userDetail)?.map(([key, value], index) => (
                  <Grid item xs={6}>
                    <InputLabel className={classes.inputLabel}>
                      {key?.replaceAll("_", " ")}
                    </InputLabel>
                    {editable ? (
                      <TextField
                        fullWidth
                        value={value}
                        onChange={(e) =>
                          handleUpdateDetail(key, e.target.value)
                        }
                        size="small"
                      />
                    ) : (
                      <Typography sx={{ fontSize: "14px" }}>
                        {value || "..."}
                      </Typography>
                    )}
                  </Grid>
                ))
              : null}
          </Grid>
        </DialogContent>
        {editable ? (
          <DialogActions sx={{ padding: "16px" }}>
            <Button
              className={classes.cancelButton}
              color="inherit"
              variant="outlined"
              size="small"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              className={classes.updateButton}
              variant="contained"
              size="small"
              onClick={handleUpdate}
              loading={
                updateBuy?.isLoading ||
                updateSel?.isLoading ||
                updateSellerBro?.isLoading ||
                updateBuyerBro?.isLoading
              }
              loadingPosition="start"
            >
              Save info
            </LoadingButton>
          </DialogActions>
        ) : null}
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}

export default ViewUserDetail;
