import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Grid, Container, Box, Stack, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDocsList } from "../../../redux/docs";
import HeaderDocs from "./headerDocs";
import { Folder, Done } from "@mui/icons-material";
import Avatar from "../../../components/uiElements/avatar/index";
import moment from "moment";
import ResponseAlert from "../../../components/responseAlert";
import { deleteFolder } from "../../../redux/docs/addfolderapi";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../components/loader/tableLoader";
import AddFolder from "./addFolder";

const DocsListing = ({ classes }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [docaArray, setDocsArr] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [doclistForSearch, setDoclistForSearch] = useState([]);

  const [openerror, setOpenerror] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMesage] = useState();
  const [searchValue, setSearchValue] = useState("");
  const docs = useSelector((item) => item.Docs.docs);
  const agents = useSelector((state) =>
    state.users.storedListOfUsers.list.filter(
      (user) => user.is_agent === true && user.status === "active"
    )
  );

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <>
            <Grid
              container
              onClick={() => redirectDetails(params.row.id)}
              className={classes.mAlign}
            >
              <Folder sx={{ color: "rgba(0, 0, 0, 0.54)", mr: "15px" }} />
              {params.row.name}
            </Grid>
          </>
        );
      },
      width: 400,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",

      renderCell: (params) => {
        return (
          <>
            <Grid
              container
              onClick={() => redirectDetails(params.row.id)}
              className={classes.status}
            >
              <Done
                sx={{
                  color: "rgba(0, 0, 0, 0.54)",
                  fontSize: "19px",
                  mr: "6px",
                }}
              />
              {params.row.status}
            </Grid>
          </>
        );
      },
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "Agent Name",
      renderCell: (params) => {
        let agent = agents?.find((item) => item.id === params.row.agent_id);
        return agent ? (
          <Stack
            onClick={() => redirectDetails(agent.id)}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              src={agent?.profile_images?.profile_img}
              sx={{
                width: 30,
                height: 28,
                mr: "10px",
                borderRadius: "6px",
                textTransform: "uppercase",
                fontWeight: "400",
              }}
              variant="square"
            >
              {agent?.first_name?.slice(0, 1)}
            </Avatar>
            <ListItemText
              primary={agent?.first_name}
              secondary={agent?.last_name}
            />
          </Stack>
        ) : (
          ""
        );
      },
      sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
      width: 250,
      editable: false,
      sortable: true,
    },
    {
      type: "dateTime",
      field: "dateModified",
      headerName: "Date Modified",
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid container onClick={() => redirectDetails(params.row.id)}>
              {moment(params.row.last_updated_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </Grid>
          </>
        );
      },
      width: 300,
      editable: false,
      sortable: true,
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    dispatch(getDocsList());
  }, []);

  const redirectDetails = (id) => {
    navigate(`/docs/${id}`);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
  };

  const archiveFunction = () => {
    if (selectedCheckboxIds?.length > 0) {
      Promise.all(
        selectedCheckboxIds?.length &&
          selectedCheckboxIds.map(async (forlderId) => {
            await dispatch(deleteFolder(forlderId));
          })
      ).then(() => {
        setDocsArr([]);
        setOpenerror(true);
        setAlertType("success");
        setAlertMesage("You have successfully Archive the folder");
      });
    } else {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please select folders or folder");
    }
  };
  return (
    <div className={classes.pageRoot}>
      <HeaderDocs
        addFolderModal={handleOpen}
        selectedCheckboxIds={selectedCheckboxIds}
        archiveFunction={archiveFunction}
        handleSearchInputChange={handleSearchInputChange}
      />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGridPro
                loading={docs.isLoading}
                components={{
                  LoadingOverlay: TableSkeleton,
                }}
                rows={
                  docs?.data?.length && searchValue
                    ? docs.data.filter((row) =>
                        row.name
                          .toLowerCase()
                          .startsWith(searchValue.toLowerCase())
                      )
                    : docs?.data?.length
                    ? [...docs?.data]
                    : []
                }
                columns={columns}
                pageSize={pageSize}
                checkboxSelection={true}
                rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                onRowSelectionModelChange={(itm) => setSelectedCheckboxIds(itm)}
                handlePageSizeChange={handlePageSizeChange}
              />
              {/* <AddFolderModal open={open} handleClose={handleClose} modalWidht="auto" agentData={agentData.loading ? [] : agentData.allAgent} /> */}
              <AddFolder open={open} setOpen={setOpen} />

              <ResponseAlert
                setOpen={setOpenerror}
                open={openerror}
                alertType={alertType}
                alertMessage={alertMessage}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styleSheet, { name: "DocsStyle" })(DocsListing);
