import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Grid, Container, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../components/shared/loading";
import FormDisplay from "./FormDisplay.js";
import { getDocDetails, getHistoryDetail } from "../../../redux/docs";
import SubHeader from "./subHeader.jsx";
import ThumbnailBar from "./thumbnailBar.jsx";
import { useLocation, useParams } from "react-router-dom";
import Peoples from "./peoples.jsx";
import Fields from "./fields.jsx";
import History from "./history.jsx";
export const returnPageImage = (file, index) => {
  return `${file?.split("/docs-pdfs")?.[0]}/pg_${index + 1}/docs-pdfs${file
    ?.split("/docs-pdfs")?.[1]
    ?.replace("pdf", "png")}`;
};

const EditDocument = (props) => {
  let { classes } = props;
  const { state } = useLocation();
  const { formId, fromDataOld } = state;
  const [formData, setFormData] = useState(fromDataOld);
  const [activePage, setActivePage] = useState(0);
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const docDetail = useSelector((item) => item.Docs.docDetail);

  useEffect(() => {
    if (id) {
      dispatch(getDocDetails({ id }));
      dispatch(getHistoryDetail({ id, formId: formData?.id }));
    }
  }, [id]);

  console.log(docDetail, "docDetail");

  return (
    <div className={classes.pageRoot}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ pt: "0 !important" }}>
          <Box sx={{ height: "auto", width: "100%" }}>
            {docDetail.isLoading ? (
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <Box
                  sx={{
                    p: 8,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loading />
                </Box>
              </div>
            ) : (
              <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
                <SubHeader
                  tab={tab}
                  setTab={setTab}
                  docDetail={docDetail}
                  id={id}
                  {...props}
                />
                <Grid container spacing={2}>
                  <Grid
                    sx={{
                      position: "sticky",
                      top: 50, // Adjust based on your layout
                      height: "100vh", // Ensures it stays full height
                      alignSelf: "flex-start",
                      overflow: "auto",
                    }}
                    item
                    xs={2}
                  >
                    <ThumbnailBar
                      docDetail={docDetail}
                      formData={formData}
                      setFormData={setFormData}
                      activePage={activePage}
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    {" "}
                    {tab === 0 ? (
                      <FormDisplay
                        {...props}
                        formData={formData}
                        setFormData={setFormData}
                        setActivePage={setActivePage}
                      />
                    ) : tab === 1 ? (
                      <Peoples docDetail={docDetail} id={id} {...props} />
                    ) : tab === 2 ? (
                      <Fields formData={formData} id={id} {...props} />
                    ) : (
                      <History formData={formData} {...props} />
                    )}
                  </Grid>
                </Grid>
              </Container>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

// export default EditDocument;
export default withStyles(styleSheet, { name: "EditDocumentStyle" })(
  EditDocument
);
