import axios from "../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");

export const addFolder = createAsyncThunk(
  "docsAddFolder/addFolder",
  async (data, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/docs/folder`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "post",
        data: data,
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            console.log("res", res);
            resolve(res);
            return res;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteFolder = createAsyncThunk(
  "docsAddFolder/addFolder",
  async (data, thunkAPI) => {
    try {
      const url = `api/docs/folder/${data}`;
      let options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
        method: "delete",
        data: {},
        url,
      };

      return new Promise((resolve, reject) => {
        axios(options)
          .then((res) => {
            console.log("res", res);
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            console.log("err", err);
            reject(err);
            return err;
          });
      });
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteForm = createAsyncThunk(
  "Docs/deleteForm",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/docs/folders/${data?.folderId}/forms/${data?.formId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return { ...resp.data, formId: data?.formId };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
