import React from "react";
import {
  Box,
  Typography,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { serverCall } from "../../../redux/calls/serverCall";
function Fields(props) {
  const { classes, formData, id } = props;

  const handleReturnPagesFields = (data) => {
    const grouped = data.reduce((acc, item) => {
      const pageNumber = item.field.page_number;
      let group = acc.find((g) => g.page_number === pageNumber);

      if (!group) {
        group = { page_number: pageNumber, fields: [] };
        acc.push(group);
      }

      group.fields.push(item);
      return acc;
    }, []);

    return grouped;
  };

  const handleInputChange = async (e) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folders/${id}/forms/${formData?.id}/field/`;
    if (e.target.value !== "") {
      const payload = { value: e.target.value };
      await serverCall("put", url + e.target.id, payload);
    }
  };

  const handleDateChange = async (date, id) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folders/${id}/forms/${formData?.id}/field/`;
    if (date) {
      const payload = { value: date };
      await serverCall("put", url + id, payload);
    }
  };

  const handleCheckboxChange = async (e, preId) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folders/${id}/forms/${formData?.id}/field/`;
    const payload = { value: `${e.target.checked}` };
    await serverCall("put", url + preId, payload);
  };

  const handleRadioChange = async (e, preId) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folders/${id}/forms/${formData?.id}/field/`;
    const payload = { value: `${e.target.value}` };
    await serverCall("put", url + preId, payload);
  };
  return (
    <Box className={classes.fieldSection}>
      {formData?.form_fields?.length
        ? handleReturnPagesFields(formData?.form_fields)?.map((page, index) => (
            <Box key={index} className={classes.fieldSectionPage}>
              <Box className={classes.pageHeading}>
                <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
                  {" "}
                  Page {Number(page?.page_number) + 1} -{" "}
                  {formData?.forms_data?.name}
                </Typography>
              </Box>
              <Box className={classes.fieldsSection}>
                {page?.fields?.map((fieldData, i) => {
                  if (
                    fieldData?.field?.type === "string" ||
                    fieldData?.field?.type === "people" ||
                    fieldData?.field?.type === "location" ||
                    fieldData?.field?.type === "telephone"
                  ) {
                    return (
                      <Box className={classes.fieldItem}>
                        <InputLabel className={classes.fieldItemLabel}>
                          {fieldData?.field?.caption}
                        </InputLabel>
                        <TextField
                          sx={{ backgroundColor: "#eeeeed !important" }}
                          fullWidth
                          size="small"
                          title={fieldData?.field?.instructions}
                          placeholder={
                            fieldData?.field?.type === "people" ||
                            fieldData?.field?.type === "location" ||
                            fieldData?.field?.type === "telephone"
                              ? fieldData?.field?.instructions
                              : ""
                          }
                          id={fieldData?.id}
                          onBlur={handleInputChange}
                        />
                      </Box>
                    );
                  } else if (fieldData?.field?.type === "number") {
                    return (
                      <Box className={classes.fieldItem}>
                        <InputLabel className={classes.fieldItemLabel}>
                          {fieldData?.field?.caption}
                        </InputLabel>
                        <TextField
                          sx={{ backgroundColor: "#eeeeed !important" }}
                          fullWidth
                          size="small"
                          type="number"
                          id={fieldData?.id}
                          onBlur={handleInputChange}
                        />
                      </Box>
                    );
                  } else if (fieldData?.field?.type === "clause") {
                    return (
                      <Box className={classes.fieldItem}>
                        <InputLabel className={classes.fieldItemLabel}>
                          {fieldData?.field?.caption}
                        </InputLabel>
                        <TextField
                          sx={{ backgroundColor: "#eeeeed !important" }}
                          fullWidth
                          size="small"
                          rows={5}
                          multiline
                          type="number"
                          id={fieldData?.id}
                          onBlur={handleInputChange}
                        />
                      </Box>
                    );
                  } else if (fieldData?.field?.type === "date") {
                    return (
                      <Box className={classes.fieldItem}>
                        <InputLabel className={classes.fieldItemLabel}>
                          {fieldData?.field?.caption}
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          {" "}
                          <DatePicker
                            fullWidth
                            value={fieldData?.value}
                            onChange={(newValue) =>
                              handleDateChange(newValue, fieldData?.id)
                            }
                            renderInput={(params) => (
                              <TextField
                                sx={{ backgroundColor: "#eeeeed !important" }}
                                fullWidth
                                size="small"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    );
                  } else if (fieldData?.field?.type === "checkbox-list") {
                    return (
                      <Box className={classes.fieldItem}>
                        <InputLabel
                          className={classes.fieldItemLabel}
                        ></InputLabel>
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={fieldData?.value}
                              onChange={(e) =>
                                handleCheckboxChange(e, fieldData?.id)
                              }
                            />
                          }
                          label={fieldData?.field?.caption}
                        />
                      </Box>
                    );
                  } else if (fieldData?.field?.type === "radio") {
                    return (
                      <Box className={classes.fieldItem}>
                        <InputLabel className={classes.fieldItemLabel}>
                          {fieldData?.field?.caption}
                        </InputLabel>
                        <RadioGroup
                          aria-labelledby={`${fieldData.id}-radio-buttons-group-label`}
                          name={`radio_group_${fieldData?.field?.id}`}
                          defaultValue={fieldData?.value}
                          onChange={(e) => handleRadioChange(e, fieldData?.id)}
                          sx={{ "& .MuiTypography-root": { fontSize: "14px" } }}
                        >
                          {fieldData?.field?.options?.map((option, i) => (
                            <FormControlLabel
                              key={i}
                              value={option.value}
                              control={<Radio />}
                              label={option?.caption}
                            />
                          ))}
                        </RadioGroup>
                      </Box>
                    );
                  }
                })}
              </Box>
            </Box>
          ))
        : null}
    </Box>
  );
}

export default Fields;
