import "./style.css";
import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import NameHeaderCard from "./cards/NameHeaderCard";
import { useDispatch, useSelector } from "react-redux";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Route, Routes, useParams } from "react-router-dom";
import AgentMainSkelton from "./skeltons";
import AgentRosterDetailsTab from "./tabsContent/AgentRosterDetailsTab";
import { getAgentRosterProfile } from "../../../redux/agents/agentRoster/getAgentRosterProfile";
import AgentRosterOverviewTab from "./tabsContent/agentRosterOverviewTab";
import AgentRosterPaymentTab from "./tabsContent/agentRosterPaymentTab";
import AgentRosterTabs from "./tabsContent/tabs";
import AgentTrainingTab from "./tabsContent/agentTrainingTab";
import AgentInventoryTab from "./tabsContent/inventoryTab";
import AgentRosterPasswords from "./tabsContent/passwords";
import UserPoints from "./tabsContent/points";
import PointsLeaderBoards from "./tabsContent/leaderBoards";
import { getMarkets } from "../../../redux/agents/addRecruitAgent";
function AgentRosterDetails({ classes }) {
  const { id } = useParams();
  const data = useSelector((item) => item.agentRoster.agentRosterProfile);
  const markets = useSelector((state) => state.agentList.markets);
  const { loading, profile } = data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgentRosterProfile({ id: id, agent_only: true }));
  }, []);

  useEffect(() => {
    if (!markets || markets.length === 0) {
      dispatch(getMarkets());
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <div className={classes.pageRoot}>
        {loading ? (
          <AgentMainSkelton />
        ) : (
          <Box>
            <NameHeaderCard {...profile} id={id} />
            <Routes>
              <Route path="/details" element={<AgentRosterDetailsTab />} />
              <Route path="/overview" element={<AgentRosterOverviewTab />} />
              <Route path="/payments" element={<AgentRosterPaymentTab />} />
              <Route path="/tab" element={<AgentRosterTabs />} />
              <Route path="/training" element={<AgentTrainingTab />} />
              <Route path="/inventory" element={<AgentInventoryTab />} />
              <Route path="/passwords" element={<AgentRosterPasswords />} />
              <Route path="/points" element={<UserPoints />} />
              <Route path="/leaderboard" element={<PointsLeaderBoards />} />
            </Routes>
          </Box>
        )}
      </div>
    </Container>
  );
}
export default withStyles(styleSheet, { name: "agentRosterProfileStyle" })(
  AgentRosterDetails
);
