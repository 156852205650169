import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Helper function to clean schema data
const cleanSchema = (schema) => {
  if (!schema) return {};

  const cleanedSchema = {};

  // Only include properties that have actual values
  Object.entries(schema).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== "") {
      cleanedSchema[key] = value;
    }
  });

  return cleanedSchema;
};

// Create a reusable function for API calls
const makeApiRequest = async (endpoint, data, options = {}) => {
  const { handleSuccess, handleError } = options;

  try {
    // Clean the schema before sending
    const cleanedSchema = cleanSchema(data.schema);

    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}${endpoint}`,
      cleanedSchema,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (handleSuccess) {
      handleSuccess();
    }

    return response.data;
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.errorMessage || error.response.data.message
      : error.message;

    if (handleError) {
      handleError(errorMessage);
    }

    throw errorMessage;
  }
};

// Create a factory function for generating async thunks
const createEntityUpdateThunk = (name, getEndpoint) => {
  return createAsyncThunk(`Docs/update${name}`, async (data, thunkAPI) => {
    try {
      return await makeApiRequest(getEndpoint(data), data, {
        handleSuccess: data?.handleSuccess,
        handleError: data?.handleError,
      });
    } catch (errorMessage) {
      return thunkAPI.rejectWithValue(errorMessage);
    }
  });
};

// Define the entity update thunks
export const updateBuyer = createEntityUpdateThunk(
  "Buyer",
  (data) => `api/docs/folders/${data?.id}/buyers/${data?.buyerId}`
);

export const updateSeller = createEntityUpdateThunk(
  "Seller",
  (data) => `api/docs/folders/${data?.id}/sellers/${data?.sellerId}`
);

export const updateBuyerBroker = createEntityUpdateThunk(
  "BuyerBroker",
  (data) => `api/docs/folders/${data?.id}/buyer-brokers/${data?.buyerBrokerId}`
);

export const updateSellerBroker = createEntityUpdateThunk(
  "SellerBroker",
  (data) =>
    `api/docs/folders/${data?.id}/sellers-brokers/${data?.sellerBrokerId}`
);
