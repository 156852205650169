import React, { useState, Fragment } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  IconButton,
  TextField,
  InputLabel,
  Grid,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { sendFormForSigning } from "../../../redux/docs";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";

function SendForSigning(props) {
  const { classes, open, setOpen, docDetail, id } = props;

  const [sendingDetail, setSendingDetail] = useState({
    subject: "",
    message: "",
    form: "",
  });
  const [errors, setErrors] = useState({});

  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();

  const sendForm = useSelector((item) => item.Docs.sendForm);

  const handleClose = () => {
    setOpen(false);
    setErrors({});
    setSendingDetail({ subject: "", message: "", form: "" });
  };

  const handleSuccess = () => {
    handleClose();
    setErrorAlert({
      errorMsg: "Sent Successfully",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!sendingDetail.subject) {
      isFormValid = false;
      errorMsg["subject"] = "Please enter the subject";
      setErrorAlert({
        errorMsg: "Please enter the subject",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!sendingDetail.message) {
      isFormValid = false;
      errorMsg["message"] = "Please enter the message";
      setErrorAlert({
        errorMsg: "Please enter the message",
        errorType: "warning",
        isOpen: true,
      });
    } else if (!sendingDetail.form) {
      isFormValid = false;
      errorMsg["form"] = "Please select the form";
      setErrorAlert({
        errorMsg: "Please select the form",
        errorType: "warning",
        isOpen: true,
      });
    }

    setErrors(errorMsg);
    return isFormValid;
  };

  const handleUpdateDetail = (field, value) => {
    setSendingDetail((preDetail) => ({
      ...preDetail,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors((err) => ({ ...err, [field]: "" }));
    }
  };

  const handleSend = () => {
    if (handleValidate()) {
      let recipients = [];
      if (docDetail?.data?.representation_side === "buying") {
        if (docDetail?.data?.buyers?.length) {
          recipients?.push({
            id: docDetail?.data?.buyers?.[0]?.id,
            type: "buyer",
            people: "agent",
            order: 1,
          });
        }
        if (docDetail?.data?.buyer_brokers?.length) {
          recipients?.push({
            id: docDetail?.data?.buyer_brokers?.[0]?.id,
            type: "buyer",
            people: "client",
            order: 2,
          });
        }
      }
      if (
        docDetail?.data?.representation_side === "listing" &&
        docDetail?.data?.buyer_brokers?.length
      ) {
        if (docDetail?.data?.sellers?.length) {
          recipients?.push({
            id: docDetail?.data?.sellers?.[0]?.id,
            type: "seller",
            people: "agent",
            order: 1,
          });
        }
        if (docDetail?.data?.seller_brokers?.length) {
          recipients?.push({
            id: docDetail?.data?.seller_brokers?.[0]?.id,
            type: "seller",
            people: "client",
            order: 2,
          });
        }
      }

      dispatch(
        sendFormForSigning({
          id,
          schema: {
            subject: sendingDetail?.subject,
            message: sendingDetail?.message,
            forms: [sendingDetail?.form],
            recipients,
          },
          handleSuccess,
          handleError,
        })
      );
    }
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="send-for-signing-model"
        aria-describedby="send-for-signing-model-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            Send For Signing
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Subject</InputLabel>
              <TextField
                fullWidth
                value={sendingDetail?.subject}
                onChange={(e) => handleUpdateDetail("subject", e.target.value)}
                size="small"
                error={errors?.subject}
                helperText={errors?.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Message</InputLabel>
              <TextField
                fullWidth
                multiline
                rows={3}
                size="small"
                value={sendingDetail?.message}
                onChange={(e) => handleUpdateDetail("message", e.target.value)}
                error={errors?.message}
                helperText={errors?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Forms</InputLabel>
              <TextField
                value={sendingDetail?.form}
                onChange={(e) => handleUpdateDetail("form", e.target.value)}
                fullWidth
                select
                size="small"
              >
                {docDetail?.data?.forms?.length ? (
                  docDetail?.data?.forms?.map((item, index) => (
                    <MenuItem value={item?.id}>
                      {item?.forms_data?.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value={""}>
                    Not Exists
                  </MenuItem>
                )}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            className={classes.cancelButton}
            color="inherit"
            variant="outlined"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            className={classes.updateButton}
            variant="contained"
            size="small"
            onClick={handleSend}
            loading={sendForm?.isLoading}
            loadingPosition="start"
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}

export default SendForSigning;
