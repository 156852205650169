export const styleSheet = () => ({
  pageRoot: {
    marginTop: "70px !important",
    minHeight: "100vh !important",
  },
  statCard: {
    width: "100% !important",
    borderRadius: "18px !important",
    padding: "16px !important",
    boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05) !important",
    height: "110px !important",
  },
  topStatCard: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  statCardValue: {
    fontWeight: "400 !important",
    fontSize: "30px !important",
    lineHeight: "150% !important",
    color: "black !important",
    marginTop: "10px !important",
  },
  statCardTitle: {
    fontWeight: "300 !important",
    fontSize: "16px !important",
    lineHeight: "150% !important",
  },
  manageButtons: {
    display: "flex !important",
    justifyContent: "center !important",
    flexDirection: "column !important",
    alignItems: "center !important",
    gap: "16px !important",
    marginTop: "35px !important",
  },
  postPaymentButton: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    color: "white !important",
    width: "180px !important",
    background: "#000 !important",
    borderRadius: "20px !important",
  },
  tableRoot: {
    height: "100vh",
    width: "100%",
    marginTop: "30px",
    backgroundColor: "white !important",
    "& .MuiDataGrid-root": {
      borderColor: "white !important",
    },
    marginBottom: "50px",
    padding: "0px 20px  !important",
    boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.05) !important",
    borderRadius: "25px !important",
  },
  statusChip: {
    borderRadius: "5px !important",
    padding: "1px 8px !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  modalHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    gap: "20px",
    background: "#ECECEC !important",
    padding: "12px 16px !important",
  },
  modalHeading: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    textTransform: "uppercase !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    paddingLeft: "6px !important",
  },
  buttonSection: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    gap: "12px !important",
    width: "100% !important",
    padding: "16px !important",
  },
  createButton: {
    borderRadius: "16px !important",
    fontSize: "12px !important",
    background: "black !important",
    color: "white !important",
  },
});
