import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Button, Box, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDocDetails } from "../../../redux/docs/getDocDetails.js";
import DosPageSkelton from "../skeltons/dosPageSketon";
import { serverCall } from "../../../redux/calls/serverCall";
import { returnPageImage } from "./index";
import { useParams } from "react-router-dom";

const FormDisplay = ({
  classes,
  getFormData,
  formData,
  setFormData,
  setActivePage,
}) => {
  const { id } = useParams();
  const documentID = id;
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState([]);
  const [bindFieldsCount, setbindFieldsCount] = useState(0);
  const isRendered = useRef(false);
  const docDetail = useSelector((item) => item.Docs.docDetail);

  const handleInputChange = async (e) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folders/${id}/forms/${formData?.id}/field/`;
    if (e.target.value !== "") {
      const payload = { value: e.target.value };
      await serverCall("put", url + e.target.id, payload);
    }
  };

  const handleCheckboxChange = async (e) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folders/${id}/forms/${formData?.id}/field/`;
    const payload = { value: `${e.target.checked}` };
    await serverCall("put", url + e.target.id, payload);
  };

  const handleRadioChange = async (e) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folders/${id}/forms/${formData?.id}/field/`;
    const payload = { value: `${e.target.value}` };
    await serverCall("put", url + e.target.fieldId, payload);
  };

  const addFields = (data, index) => {
    var countField = 0;
    if (formData?.forms_data?.file) {
      const pdfDoc = returnPageImage(formData?.forms_data?.file, index);
      var canvas = document.getElementById(`myCanvas${index}`);
      canvas.style.width = "100%";
      var ctx = canvas.getContext("2d");
      loadImage(pdfDoc, canvas, ctx, index, data).then((count) => {
        setbindFieldsCount(count);
      });
    }
    return countField;
  };

  const loadImage = (src, canvas, ctx, index, data) =>
    new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        if (data?.form_fields?.length) {
          Promise.all(
            data.form_fields.map((field) => {
              addInput(
                field,
                canvas.offsetLeft,
                canvas.offsetTop + canvas.height,
                index
              );
            })
          ).then(() => {
            resolve(data.form_fields.length);
          });
        }
      };
      img.onerror = reject;
      img.src = src;
    });

  const addInput = (field, offsetWidth, offsetHeight, index) => {
    let fieldVal = field?.field;
    let element;
    let xCo = parseFloat(fieldVal?.x_pos_per);
    let yCo = parseFloat(fieldVal?.y_pos_per);
    let width = parseFloat((fieldVal?.width / 612) * 100);
    let height = parseFloat((fieldVal?.height / 792) * 100);

    // Determine the element type based on field type
    switch (fieldVal?.type) {
      case "date":
        element = document.createElement("input");
        element.type = "date";
        element.value = field?.value || "";
        break;

      case "checkbox-list":
        element = document.createElement("input");
        element.type = "checkbox";
        element.checked = field?.checked || false;
        element.style.transform = "scale(1.2)";
        break;

      case "radio":
        // For radio type, we handle it differently
        // Skip creating the main container here, as we'll create individual positioned options

        // Add radio options from the options array
        if (fieldVal?.options && Array.isArray(fieldVal.options)) {
          const radioGroupName = `radio_group_${field?.id}`;
          const fieldWrapper = document.getElementById(`fieldWrap${index}`);

          // First, add the group label if exists (positioned at the top of all radio options)
          if (fieldVal?.label) {
            const headingElement = document.createElement("div");
            headingElement.textContent = fieldVal.label;
            headingElement.style.fontWeight = "bold";
            headingElement.style.fontSize = "1.1vw";
            headingElement.style.position = "absolute";

            // If there's at least one option with positioning, use it for the header
            if (
              fieldVal.options[0]?.x_pos_per &&
              fieldVal.options[0]?.y_pos_per
            ) {
              const xCoHeader = parseFloat(fieldVal.options[0].x_pos_per);
              // Position the header slightly above the first option
              const yCoHeader = parseFloat(fieldVal.options[0].y_pos_per) + 5;
              headingElement.style.left = xCoHeader + "%";
              headingElement.style.bottom = yCoHeader + "%";
            } else {
              // Fallback if no positioning in options
              headingElement.style.left = xCo + "%";
              headingElement.style.bottom = yCo + 5 + "%";
            }

            headingElement.style.backgroundColor = "#ccd7fd";
            headingElement.style.padding = "2px 8px";
            headingElement.style.borderRadius = "4px";
            fieldWrapper?.appendChild(headingElement);
          }

          // Then add each radio option with its own positioning
          fieldVal.options.forEach((option, optionIndex) => {
            const radioContainer = document.createElement("div");
            radioContainer.style.display = "flex";
            radioContainer.style.alignItems = "center";

            // Get position for this specific option
            const xCoOption = parseFloat(
              option.x_pos_per || fieldVal?.x_pos_per
            );
            const yCoOption = parseFloat(
              option.y_pos_per || fieldVal?.y_pos_per
            );

            // Position the option container absolutely
            radioContainer.style.position = "absolute";
            radioContainer.style.left = xCoOption + "%";
            radioContainer.style.bottom = yCoOption + "%";
            radioContainer.style.backgroundColor = "#ccd7fd";
            radioContainer.style.padding = "4px";
            radioContainer.style.borderRadius = "4px";
            radioContainer.title = option.caption;

            const radioInput = document.createElement("input");
            radioInput.type = "radio";
            radioInput.name = radioGroupName;
            radioInput.id = `${field?.id}_option_${optionIndex}`;
            radioInput.fieldId = `${field?.id}`;
            radioInput.value = option.value || option;
            radioInput.style.marginRight = "5px";
            radioInput.style.transform = "scale(1.2)";
            radioInput.title = option.caption;
            // Check if this option is selected
            if (field?.value === option.value) {
              radioInput.checked = true;
            }

            // const radioLabel = document.createElement("label");
            // radioLabel.htmlFor = `${field?.id}_option_${optionIndex}`;
            // radioLabel.textContent = option.caption || option;
            // radioLabel.style.fontSize = "0.9vw";
            // radioLabel.style.marginLeft = "5px";

            radioContainer.appendChild(radioInput);
            // radioContainer.appendChild(radioLabel);
            fieldWrapper?.appendChild(radioContainer);
          });

          // Since we directly added elements to the fieldWrapper, return to avoid appending anything else
          return;
        } else {
          // Fallback for when options aren't provided
          element = document.createElement("input");
          element.type = "radio";
          element.checked = field?.checked || false;
          element.style.transform = "scale(1.2)";
        }
        break;

      case "string":
        element = document.createElement("input");
        element.type = "text";
        element.value = field?.value || "";
        break;

      case "number":
        element = document.createElement("input");
        element.type = "number";
        element.value = field?.value || "";
        break;

      case "clause":
        element = document.createElement("textarea");
        element.value = field?.value || "";
        break;

      case "people":
        element = document.createElement("input");
        element.type = "text";
        element.placeholder = "Enter name";
        element.value = field?.value || "";
        break;

      case "location":
        element = document.createElement("input");
        element.type = "text";
        element.placeholder = "Enter location";
        element.value = field?.value || "";
        break;

      case "telephone":
        element = document.createElement("input");
        element.type = "tel";
        element.placeholder = "Enter phone number";
        element.value = field?.value || "";
        break;

      case "signature":
        element = document.createElement("div");
        element.innerHTML = field?.value
          ? `<img src="${field.value}" alt="Signature" style="max-width: 100%; max-height: 100%;">`
          : '<div style="display: flex; align-items: center; justify-content: center; height: 100%; border: 1px dashed #999;">Sign here</div>';
        break;

      case "logo":
        element = document.createElement("div");
        element.innerHTML =
          '<div style="display: flex; align-items: center; justify-content: center; height: 100%; border: 1px dashed #999;">Logo here</div>';
        break;

      default:
        element = document.createElement("input");
        element.type = "text";
        break;
    }

    // Only continue with common element properties if we didn't return early (for radio type)
    if (element) {
      // Set common attributes
      element.name = fieldVal?.name;
      element.title = fieldVal?.instructions;
      element.id = field?.id;

      // Position the main element absolutely
      element.style.position = "absolute";
      element.style.left = xCo + "%";
      element.style.bottom = yCo + "%";
      element.style.fontSize = 1 + "vw";
      element.style.backgroundColor = "#ccd7fd";
      element.style.border = "0";

      // Set width and height for applicable elements
      if (fieldVal?.type !== "checkbox-list" && fieldVal?.type !== "radio") {
        element.style.width = width + "%";
        element.style.height = height + "%";
      } else {
        // For checkbox-list types, set container dimensions
        element.style.width = width + "%";
        element.style.height = "auto";
        element.style.maxHeight = height + "%";
        element.style.overflow = "auto";
        element.style.padding = "8px";
        element.style.boxSizing = "border-box";
      }

      // Additional styling for specific types
      if (fieldVal?.type === "clause") {
        element.style.resize = "none";
        element.style.overflow = "auto";
      }

      // Append the element to the field wrapper
      document.getElementById(`fieldWrap${index}`)?.appendChild(element);

      // Additional setup for signature field if needed
      if (fieldVal?.type === "signature" && !field?.value) {
        // You might want to initialize a signature pad library here
        // This is just a placeholder for where you would add that code
      }
    }
  };

  useEffect(() => {
    if (formData?.forms_data?.total_pages && !isRendered?.current) {
      isRendered.current = true;
      Array.from(Array(formData?.forms_data?.total_pages || 0).keys())?.map(
        (item, index) => {
          addFields(
            {
              ...formData,
              form_fields: formData?.form_fields?.filter(
                (it) => it.field?.page_number === index
              ),
            },
            index
          );
        }
      );
    }
  }, [formData?.id]);

  useEffect(() => {
    if (bindFieldsCount !== 0) {
      document.querySelectorAll("input").forEach((el) => {
        if (
          el.type === "text" ||
          el.type === "date" ||
          el.type === "number" ||
          el.type === "tel"
        ) {
          el.addEventListener("blur", handleInputChange);
        } else if (el.type === "checkbox") {
          el.addEventListener("change", handleCheckboxChange);
        } else if (el.type === "radio") {
          el.addEventListener("change", handleRadioChange);
        }
      });

      return () => {
        document.querySelectorAll("input").forEach((el) => {
          if (
            el.type === "text" ||
            el.type === "date" ||
            el.type === "number" ||
            el.type === "tel"
          ) {
            el.removeEventListener("blur", handleInputChange);
          } else if (el.type === "checkbox") {
            el.removeEventListener("change", handleCheckboxChange);
          } else if (el.type === "radio") {
            el.removeEventListener("change", handleRadioChange);
          }
        });
      };
    }
  }, [bindFieldsCount, formValues]);

  const handlePreviousDocument = (id) => {
    let forms = [...docDetail?.data?.forms];
    let currentIndex = forms?.findIndex((item) => item.id === formData.id);
    if (forms?.length > 1 && currentIndex !== 0 && forms[currentIndex - 1]) {
      Array.from(Array(formData?.form_fields?.length).keys()).map((item) => {
        const myNode = document.getElementById(`fieldWrap${item}`);
        if (myNode) {
          myNode.innerHTML = "";
        }
      });
      isRendered.current = false;
      setFormData(forms[currentIndex - 1]);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleNextDocument = () => {
    let forms = [...docDetail?.data?.forms];
    let currentIndex = forms?.findIndex((item) => item.id === formData.id);
    if (
      forms?.length > 1 &&
      currentIndex !== forms?.length - 1 &&
      forms[currentIndex + 1]
    ) {
      Array.from(Array(formData?.form_fields?.length).keys()).map((item) => {
        const myNode = document.getElementById(`fieldWrap${item}`);
        if (myNode) {
          myNode.innerHTML = "";
        }
      });
      isRendered.current = false;
      setFormData(forms[currentIndex + 1]);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      // Only proceed if we have pages
      if (!formData?.forms_data?.total_pages) return;

      // Find which page is most visible in the viewport
      let maxVisiblePage = 0;
      let maxVisibleArea = 0;

      for (let i = 0; i < formData?.forms_data?.total_pages; i++) {
        const pageElement = document.getElementById(`myCanvas${i}`);
        if (!pageElement) continue;

        const rect = pageElement.getBoundingClientRect();
        // Calculate how much of the element is visible in the viewport
        const visibleHeight =
          Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);

        if (visibleHeight > maxVisibleArea) {
          maxVisibleArea = visibleHeight;
          maxVisiblePage = i;
        }
      }

      setActivePage(maxVisiblePage);
    };
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Initial check
    handleScroll();

    // Clean up
    return () => window.removeEventListener("scroll", handleScroll);
  }, [formData?.forms_data?.total_pages]);

  const isDisabled = (type) => {
    if (docDetail?.data?.forms?.length) {
      let forms = [...docDetail?.data?.forms];
      let currentIndex = forms?.findIndex((item) => item.id === formData.id);

      if (type === "next") {
        if (
          docDetail?.data?.forms?.length < 2 ||
          currentIndex === forms?.length - 1 ||
          !forms[currentIndex + 1]
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          docDetail?.data?.forms?.length < 2 ||
          currentIndex === 0 ||
          !forms[currentIndex - 1]
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <Box className={classes.transactionDetailSection} sx={{ mt: "30px" }}>
        <Typography className={classes.transactionDetailHead}>
          I am working with:{" "}
          <span>
            {docDetail?.data?.representation_side === "buying"
              ? "Buyers"
              : "Sellers"}{" "}
          </span>{" "}
          for
          <span> {docDetail?.data?.name}</span> <a> Edit People</a>
        </Typography>
        <Grid container spacing={2} sx={{ mt: "10px" }}>
          <Grid item xs={4}>
            <Typography className={classes.transactionContTop}>
              Full Legal Names of All buyer(s)
            </Typography>
            <Typography className={classes.transactionContBottom}>
              {docDetail?.data?.buyers
                ?.map((item) => `${item.first_name} ${item?.last_name}`)
                ?.join(", ")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.transactionContTop}>
              Full Legal Names of All seller(s)
            </Typography>
            <Typography className={classes.transactionContBottom}>
              {docDetail?.data?.sellers
                ?.map((item) => `${item.first_name} ${item?.last_name}`)
                ?.join(", ")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.transactionContTop}>
              Name of Buying Broker/Salesperson
            </Typography>
            <Typography className={classes.transactionContBottom}>
              {docDetail?.data?.buyer_brokers
                ?.map((item) => `${item.first_name} ${item?.last_name}`)
                ?.join(", ")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.transactionContTop}>
              Name of Listing Broker/Salesperson
            </Typography>
            <Typography className={classes.transactionContBottom}>
              {docDetail?.data?.seller_brokers
                ?.map((item) => `${item.first_name} ${item?.last_name}`)
                ?.join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <div className={classes.mainStyle}>
        <Button
          className={classes.mButtonStyle}
          disabled={isDisabled("back")}
          onClick={() => handlePreviousDocument()}
        >
          Previous Document
        </Button>
      </div>
      {Array.from(Array(formData?.forms_data?.total_pages).keys())?.length ? (
        Array.from(Array(formData?.forms_data?.total_pages).keys())?.map(
          (item, index) => (
            <div className={classes.mPosition}>
              <canvas
                id={`myCanvas${index}`}
                className={classes.mCanvas}
              ></canvas>
              <div id={`fieldWrap${index}`} className={classes.mColor}></div>
            </div>
          )
        )
      ) : (
        <DosPageSkelton />
      )}
      <div className={classes.mainStyle}>
        <Button
          className={classes.mButtonStyle}
          disabled={isDisabled("next")}
          onClick={handleNextDocument}
        >
          Next Document
        </Button>
      </div>
    </>
  );
};

// export default FormDisplay;
export default withStyles(styleSheet, { name: "FormDisplayStyle" })(
  FormDisplay
);
