export const styleSheet = () => ({
  modalHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  modalHeading: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: "black !important",
  },
  submitButton: {
    backgroundColor: "black !important",
    color: "white !important",
    textTransform: "capitalize !important",
    padding: "6px 24px !important",
  },
});
