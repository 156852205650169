import React, { useState, Fragment } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import { updatePaymentStatus } from "../../../redux/payments";
import { useSelector, useDispatch } from "react-redux";
import ResponseAlert from "../../../components/responseAlert";

function PostPayment(props) {
  const { open, classes, setOpen, selectedPayments, setSelectedPayments } =
    props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();
  const updatePayment = useSelector((state) => state.Payments.updatePayment);

  const handleClose = () => {
    setOpen(false);
    setSelectedPayments([]);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated status of payment",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handlePostPayment = () => {
    if (selectedPayments?.length) {
      dispatch(
        updatePaymentStatus({
          schema: {
            status: "Posted",
            paymentIds: selectedPayments,
          },
          handleSuccess,
          handleError,
        })
      );
    }
  };

  return (
    <Fragment>
      {" "}
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            Post Payments
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <br />
          <Typography sx={{ textAlign: "center" }}>
            Please confirm that you would like to post the selected payments.
          </Typography>
          {!selectedPayments?.length ? (
            <Typography sx={{ color: "red", textAlign: "center" }}>
              Please select a payment to proceed.
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          {" "}
          <Box className={classes.buttonSection}>
            <LoadingButton
              variant="contained"
              color="inherit"
              className={classes.createButton}
              loadingPosition="start"
              loading={updatePayment?.isLoading}
              disabled={
                selectedPayments?.length || updatePayment?.isLoading
                  ? false
                  : true
              }
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "#b0bec5 !important",
                  color: "#ffffff !important",
                  cursor: "not-allowed !important",
                },
              }}
              onClick={handlePostPayment}
            >
              Confirm
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Fragment>
  );
}
export default PostPayment;
