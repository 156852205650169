import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { returnPageImage } from "./index";
function ThumbnailBar(props) {
  const { classes, docDetail, formsData, activePreview, activePage } = props;
  const [expandedTab, setExpandedTab] = useState([0]);
  return (
    <Box sx={{ position: "relative" }}>
      <Box className={classes.tabBarArea}>
        {docDetail?.data?.forms?.map((value, index) => (
          <Accordion
            expanded={expandedTab.includes(index)}
            className={classes.mAccordianColor}
            square
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header p-0"
              onClick={() => {
                let expandedTab_new = [...expandedTab];
                let checkIndex = expandedTab_new.indexOf(index);
                if (checkIndex > -1) {
                  expandedTab_new.splice(checkIndex, 1);
                  setExpandedTab(expandedTab_new);
                } else {
                  expandedTab_new.push(index);
                  setExpandedTab(expandedTab_new);
                }
                // handlePerticularAttachment(value);
              }}
            >
              <Box
                title={value?.forms_data?.name}
                className={classes.sideBarDocName}
              >
                {value?.forms_data?.name}
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.mAccordianDetails}>
              {Array.from(Array(value?.forms_data?.total_pages).keys())?.map(
                (attachment, index1) => (
                  <div className={classes.mAccordianTxt} key={index1}>
                    <img
                      src={returnPageImage(value?.forms_data?.file, index1)}
                      alt=""
                      className={`${classes.mAccordianImage} ${
                        index1 === activePage ? classes.mActive : ""
                      }`}
                      onClick={() => {
                        document
                          .getElementById(`myCanvas${index1}`)
                          ?.scrollIntoView({ behavior: "smooth" });
                        if (
                          value?.forms_data?.form_number !==
                          formsData?.forms_data.form_number
                        ) {
                          //   handleChangePreview(index);
                          //   setFormAttachments(value.forms_data);
                        }
                      }}
                    />
                    <div>{index1 + 1}</div>
                  </div>
                )
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}

export default ThumbnailBar;
