import React, { useState, useEffect, useRef } from "react";
import { Box, Paper, Button, Typography, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  getPendingChecks,
  processChecks,
  updateChecksStore,
} from "../../../redux/finance/getPendingChecks";
import { getSignedUrl } from "../../../redux/common/getSignedUrl";
import ResponseAlert from "../../../components/responseAlert";
import { getPendingPayments } from "../../../redux/finance/getPendingPayments";
import LoadingButton from "@mui/lab/LoadingButton";

function ChecksToPrint(props) {
  let { classes } = props;
  const dispatch = useDispatch();
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [checkNumber, setCheckNumber] = React.useState("");
  const isFirstRender = useRef(false);
  const {
    loading,
    allChecks: data,
    processLoading,
  } = useSelector((item) => item.finance.checksData);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  useEffect(() => {
    dispatch(getPendingChecks()).then((res) => {
      setSelectionModel(res?.payload?.data.map((r, i) => r.id));
    });
  }, []);

  const printCheck = () => {
    const formData = {
      check_start: parseInt(checkNumber),
      ids: data.filter((e) => selectionModel.includes(e.id)).map((s) => s.id),
    };
    dispatch(processChecks(formData)).then((res) => {
      if (res.payload.status === 200) {
        dispatch(getSignedUrl(res.payload.data)).then((result) => {
          if (result.payload.status === 200) {
            dispatch(getPendingChecks()).then((res) => {
              setSelectionModel(res?.payload?.data.map((r, i) => r.id));
            });
            dispatch(getPendingPayments());

            dispatch(getPendingPayments());
            setCheckNumber("");
            setErrorAlert({
              errorMsg: `${selectionModel.length} Checks have successfully processed`,
              errorType: "success",
              isOpen: true,
            });
            window.open(result.payload.data);
          } else {
            setErrorAlert({
              errorMsg: result.payload.message,
              errorType: "error",
              isOpen: true,
            });
          }
        });
      } else {
        dispatch(getPendingChecks()).then((res) => {
          setSelectionModel(res?.payload?.data.map((r, i) => r.id));
        });
        dispatch(getPendingPayments());
        setErrorAlert({
          errorMsg: res.payload.message,
          errorType: "error",
          isOpen: true,
        });
      }
    });
  };

  const CustomFooter = (props) => {
    return (
      <Box className={classes.dataGridButtonsCheck}>
        <Box>
          <Box className={classes.selectedChecksBox}>
            <Typography variant="p">
              {selectionModel.length +
                " Check(s) to be printed with starting check number"}
            </Typography>
            <TextField
              type="number"
              label="Check no"
              value={checkNumber}
              onChange={(e) => setCheckNumber(e.target.value)}
              autoFocus
            ></TextField>
          </Box>
          <LoadingButton
            variant="contained"
            sx={{
              backgroundColor: "black",
              "&:hover": { backgroundColor: "black", minWidth: "120px" },
            }}
            disabled={selectionModel.length === 0 || processLoading}
            onClick={(e) => {
              if (
                selectionModel.length &&
                parseInt(checkNumber) > 0 &&
                !processLoading
              ) {
                printCheck();
              }
            }}
            loadingPosition="end"
            loading={processLoading}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    );
  };

  const columns = [
    {
      field: "payee",
      headerName: "Payee",
      renderCell: (params) => {
        return params.row.payee_name;
      },
      sortComparator: (v1, v2) => v1.AssetName.localeCompare(v2.AssetName),
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      renderCell: (params) => {
        return `$${parseFloat(params.row.amount).toFixed(2)}`;
      },
      sortComparator: (v1, v2) =>
        v1.SerialNumber.localeCompare(v2.SerialNumber),
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "address",
      headerName: "Address",
      renderCell: (params) => {
        return params.row.vendor.street +
          params.row.vendor.state +
          params.row.vendor.postal_code ===
          0
          ? "N/A"
          : `${
              params.row.vendor.street ? params.row.vendor.street + ", " : ""
            } ${params.row.vendor.state} ${params.row.vendor.postal_code}`;
      },
      width: 280,
      editable: false,
      sortable: false,
    },
    {
      field: "closedProperty",
      headerName: "Closed Property",
      renderCell: (params) => {
        return params.row.property_address;
      },
      width: 280,
      editable: false,
      sortable: false,
    },

    {
      field: "renderCell",
      headerName: "Action",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            sx={{
              fontSize: "13px",
              backgroundColor: "black",
              "&:hover": { backgroundColor: "black" },
            }}
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              let model = [];
              if (selectionModel.includes(params.row.id)) {
                model = selectionModel.filter((f) => f !== params.row.id);
              } else {
                model = selectionModel.concat([params.row.id]);
              }
              setSelectionModel(model);
              dispatch(
                updateChecksStore(
                  data.map((r, i) => ({
                    ...r,
                    needToPrint: model.includes(r.id),
                  }))
                )
              );
            }}
          >
            {params.row.needToPrint ? "Don't print" : "Will not print"}
          </Button>
        );
      },
      width: 160,
    },
  ];

  return (
    <Box>
      {" "}
      <br />
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={data}
          columns={columns}
          components={{
            LoadingOverlay: LinearProgress,
            Footer: CustomFooter,
          }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(itm) => {
            if (!isFirstRender.current) {
              isFirstRender.current = true; // Mark first render as completed
              return;
            }
            setSelectionModel(itm);
            dispatch(
              updateChecksStore(
                data.map((r, i) => ({ ...r, needToPrint: itm.includes(r.id) }))
              )
            );
          }}
          loading={loading}
          rowHeight={56}
          disableColumnResize
          disableColumnMenu
          disableChildrenSorting
          checkboxSelection
          disableSelectionOnClick
        />
      </Paper>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}
export default ChecksToPrint;
