import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";

function DeleteDraft(props) {
  const { open, classes, setOpen, loading, handleConfirm, title } = props;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" } }}
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="create-invoice-dialog-title"
      aria-describedby="create-invoice-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className={classes.modalHeader}>
        <Typography className={classes.modalHeading}>{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={classes.invoiceForm}
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "12px !important",
        }}
      >
        <Box sx={{ width: "80%" }}>
          {" "}
          <Typography sx={{ textAlign: "center", fontSize: "18px" }}>
            Are you sure you want to delete this invoice draft?
          </Typography>
        </Box>{" "}
      </DialogContent>
      <DialogActions>
        {" "}
        <Box className={classes.buttonSection}>
          <LoadingButton
            variant="contained"
            color="inherit"
            className={classes.createButton}
            loadingPosition="start"
            loading={loading}
            onClick={handleConfirm}
            disabled={loading}
          >
            delete draft
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
export default withStyles(styleSheet, { name: "DeleteDraftStyle" })(
  DeleteDraft
);
