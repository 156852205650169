import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import AddForms from "../../docsListing/addFolder/addForms.jsx";
import { getFolders, getPackages, addForms } from "../../../../redux/docs";
import { toast } from "react-toastify";

function AddFormModal(props) {
  const { open, classes, preForms, id, setOpen } = props;
  const [forms, setForms] = useState([]);
  const [packages, setPackages] = useState([]);

  const dispatch = useDispatch();

  const add = useSelector((state) => state.Docs?.addForms);
  const folders = useSelector((state) => state.Docs?.folders);
  const packagesData = useSelector((state) => state.Docs?.packages);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    toast.success("Forms added successfully", {
      theme: "colored",
    });
    handleClose();
  };
  const handleError = (error) => {
    toast.error(JSON.stringify(error), {
      theme: "colored",
    });
  };
  const handleSubmit = () => {
    if (forms?.length) {
      let oldForms = preForms?.map((item) => item?.forms_data?.id);
      dispatch(
        addForms({
          id,
          forms: forms?.filter((it) => !oldForms?.includes(it)),
          handleSuccess,
          handleError,
        })
      );
    } else {
      toast.warn("Please select at least one form to proceed.", {
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    if (open && !folders?.isLoading && folders?.data?.length === 0) {
      dispatch(getFolders());
    }
  }, [open]);

  useEffect(() => {
    if (open && !packagesData?.isLoading && packagesData?.data?.length === 0) {
      dispatch(getPackages());
    }
  }, [open]);

  useEffect(() => {
    if (open && preForms?.length) {
      setForms(preForms?.map((item) => item?.forms_data?.id));
    }
  }, [open]);
  return (
    <Dialog
      disablePortal
      sx={{ "& .MuiDialog-container": { mt: "30px" } }}
      open={open}
      onClose={() => {}}
      aria-labelledby="add-new-folder-model"
      aria-describedby="add-new-folder-model-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.modalHeader}>
        <Typography className={classes.modalHeading}>Add Forms</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AddForms
          forms={forms}
          setForms={setForms}
          setPackages={setPackages}
          packages={packages}
          disableType={true}
          {...props}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: "24px",
          paddingTop: "6px",
        }}
      >
        <LoadingButton
          onClick={handleSubmit}
          className={classes.submitButton}
          variant="contained"
          loadingPosition="start"
          loading={add.isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default withStyles(styleSheet, { name: "AddFormModalStyle" })(
  AddFormModal
);
