// export const ValidateEmail = (mail) => {
//   const regex = new RegExp(/^[\w\.-]+(\+[\w-]*)?@[\w-]+\.\w{2,3}$/);
//   return regex.test(mail);
// };

// also support the email like allison@haywood.homes

export const ValidateEmail = (mail) => {
  const regex = new RegExp(/^[\w.-]+(\+[\w-]*)?@[\w.-]+\.\w{2,}$/);
  return regex.test(mail);
};
