import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemAvatar,
  Checkbox,
  ListItemText,
  FormGroup,
  Skeleton,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { getFolderForms, getPackageFiles } from "../../../../redux/docs";
import { useDispatch, useSelector } from "react-redux";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";

const AccordionDisplay = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummaryDisplay = styled((props) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetailsDisplay = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function AddForms({
  classes,
  transactionDetail,
  forms,
  setForms,
  packages,
  setPackages,
  disableType,
}) {
  const [activeTab, setActiveTab] = useState("forms");
  const [expanded, setExpanded] = useState("");
  const dispatch = useDispatch();

  const folders = useSelector((state) => state.Docs?.folders);
  const folderForms = useSelector((state) => state.Docs?.folderForms);
  const packagesData = useSelector((state) => state.Docs?.packages);
  const packageFiles = useSelector((state) => state.Docs?.packageFiles);

  // Define tab options
  const tabs = [
    { id: "checklists", label: "Checklists" },
    { id: "packages", label: "Packages" },
    { id: "forms", label: "Forms" },
    { id: "clone", label: "Clone from Existing" },
  ];

  const handleAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Function to return styles for tabs dynamically
  const getTabStyles = (tabId) => ({
    color: activeTab === tabId ? "black" : "#87878c",
    backgroundColor: activeTab === tabId ? "#f9f9f8" : "white",
    borderTop: activeTab === tabId ? "4px solid #225ccf" : "",
    borderBottom: activeTab === tabId ? "1px solid transparent" : "",
    paddingBottom: activeTab === tabId ? "3px" : "",
    borderTopLeftRadius: tabId === "checklists" ? "10px" : "",
    borderTopRightRadius: tabId === "clone" ? "10px" : "",
  });

  const handleCheckboxChange = (id) => {
    if (forms.includes(id)) {
      setForms((preForms) => [...preForms]?.filter((it) => it !== id));
    } else {
      setForms((preForms) => [...preForms, Number(id)]);
    }
  };

  const handleChangePackageFile = (id) => {
    if (packages.includes(id)) {
      setPackages((prePackages) => [...prePackages]?.filter((it) => it !== id));
    } else {
      setPackages((prePackages) => [...prePackages, Number(id)]);
    }
  };
  const handleGetFolderForm = (folder_id) => {
    dispatch(getFolderForms({ folder_id }));
  };
  const handleGetPackagesFiles = (folder_id) => {
    dispatch(getPackageFiles({ folder_id }));
  };
  console.log(forms, "forms", folderForms);
  return (
    <Box>
      <Box className={classes.formSection}>
        <Box className={classes.formTabs}>
          {tabs.map((tab) => (
            <Box
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              sx={getTabStyles(tab.id)}
              className={classes.forTabsItem}
            >
              {tab.label}
            </Box>
          ))}
          <Box className={classes.tabsRightSection}>
            <Button
              className={classes.uploadButton}
              size="small"
              variant="contained"
            >
              Upload
            </Button>
          </Box>
        </Box>
        <Box className={classes.formTabDetail}>
          {activeTab === "forms" ? (
            <Box>
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                }
                sx={{ bgcolor: "white", height: "30px", mb: "8px" }}
                size="small"
                placeholder="Search..."
                fullWidth
              />
              {disableType ? null : (
                <Grid container spacing={0}>
                  <Grid item xs={2.2}>
                    <Typography className={classes.filterForms}>
                      Filter Forms:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={9.8}>
                    {" "}
                    <RadioGroup
                      row
                      aria-labelledby="filter-forms-group-label"
                      name="filter-form-group"
                      className={classes.filterFormsRadios}
                      value={transactionDetail?.representation_side}
                    >
                      <FormControlLabel
                        value="both"
                        control={<Radio size="small" />}
                        label="All Forms"
                      />
                      <FormControlLabel
                        value="buying"
                        control={<Radio size="small" />}
                        label="Buying Siding"
                      />
                      <FormControlLabel
                        value="listing"
                        control={<Radio size="small" />}
                        label="Listing Side"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              )}
              <Box sx={{ height: "300px", overflow: "auto" }}>
                {folders?.data?.length > 0
                  ? folders?.data?.map((value, index) => (
                      <AccordionDisplay
                        expanded={expanded === `forms${index}`}
                        onChange={handleAccordian(`forms${index}`)}
                        className={classes.mAccordianBorder}
                      >
                        <AccordionSummaryDisplay
                          sx={{
                            padding: "0px",
                            backgroundColor: "transparent",
                          }}
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          onClick={() => handleGetFolderForm(value?.id)}
                        >
                          <Box className={classes.folderItem}>
                            <Box className={classes.folderItemBox}></Box>
                            <BallotOutlinedIcon fontSize="small" />
                            <Typography className={classes.folderItemContent}>
                              {" "}
                              {value.name}
                            </Typography>
                          </Box>
                        </AccordionSummaryDisplay>
                        <AccordionDetailsDisplay
                          sx={{
                            overflow: "auto",
                            paddingLeft: "32px",
                          }}
                          className={classes.mAccordianBorder}
                        >
                          {folderForms?.isLoading
                            ? Array.from(Array(10).keys()).map(() => (
                                <Box className={classes.skeltonRow}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", width: "17px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", width: "17px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", flex: 1 }}
                                  />
                                </Box>
                              ))
                            : folderForms?.data?.map((item, index) => (
                                <List disablePadding>
                                  <ListItem
                                    disablePadding
                                    sx={{ height: "36px" }}
                                  >
                                    <ListItemAvatar>
                                      {index === 0 && (
                                        <TurnLeftIcon
                                          className={classes.mTurnIconStyle}
                                          fontSize="small"
                                        />
                                      )}
                                    </ListItemAvatar>
                                    <FormGroup sx={{ width: "35px" }}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value={item.id}
                                            checked={forms.includes(item.id)}
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                Number(e.target.value)
                                              )
                                            }
                                          />
                                        }
                                      />
                                    </FormGroup>
                                    <ArticleOutlinedIcon
                                      sx={{ mr: "5px", fontSize: "23px" }}
                                      fontSize="small"
                                    />
                                    {/* <img src={docum} className={classes.mImgStyle} /> */}
                                    <ListItemText
                                      sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "14px",
                                        },
                                      }}
                                      primary={
                                        item.name.length < 42
                                          ? item.name || "Unknown name"
                                          : item.name?.slice(0, 42) + "..."
                                      }
                                    />
                                  </ListItem>
                                </List>
                              ))}
                        </AccordionDetailsDisplay>
                      </AccordionDisplay>
                    ))
                  : null}
              </Box>
            </Box>
          ) : activeTab === "packages" ? (
            <Box>
              {" "}
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                }
                sx={{ bgcolor: "white", height: "30px", mb: "8px" }}
                size="small"
                placeholder="Search..."
                fullWidth
              />
              <Box sx={{ height: "300px", overflow: "auto" }}>
                {packagesData?.data?.length > 0
                  ? packagesData?.data?.map((value, index) => (
                      <AccordionDisplay
                        expanded={expanded === `package${index}`}
                        onChange={handleAccordian(`package${index}`)}
                        className={classes.mAccordianBorder}
                      >
                        <AccordionSummaryDisplay
                          sx={{
                            padding: "0px",
                            backgroundColor: "transparent",
                          }}
                          aria-controls="panel1d-content"
                          id={`package${index}d-header`}
                          onClick={() => handleGetPackagesFiles(value?.id)}
                        >
                          <Box className={classes.folderItem}>
                            <Box className={classes.folderItemBox}></Box>
                            <BallotOutlinedIcon fontSize="small" />
                            <Typography className={classes.folderItemContent}>
                              {" "}
                              {value.name}
                            </Typography>
                          </Box>
                        </AccordionSummaryDisplay>
                        <AccordionDetailsDisplay
                          sx={{
                            overflow: "auto",
                            paddingLeft: "32px",
                          }}
                          className={classes.mAccordianBorder}
                        >
                          {packageFiles?.isLoading
                            ? Array.from(Array(10).keys()).map(() => (
                                <Box className={classes.skeltonRow}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", width: "17px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", width: "17px" }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", flex: 1 }}
                                  />
                                </Box>
                              ))
                            : packageFiles?.data?.map((item, index) => (
                                <List disablePadding>
                                  <ListItem
                                    disablePadding
                                    sx={{ height: "36px" }}
                                  >
                                    <ListItemAvatar>
                                      {index === 0 && (
                                        <TurnLeftIcon
                                          className={classes.mTurnIconStyle}
                                          fontSize="small"
                                        />
                                      )}
                                    </ListItemAvatar>
                                    <FormGroup sx={{ width: "35px" }}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value={item.id}
                                            checked={packages.includes(item.id)}
                                            onChange={(e) =>
                                              handleChangePackageFile(
                                                Number(e.target.value)
                                              )
                                            }
                                          />
                                        }
                                      />
                                    </FormGroup>
                                    <ArticleOutlinedIcon
                                      sx={{ mr: "5px", fontSize: "23px" }}
                                      fontSize="small"
                                    />
                                    {/* <img src={docum} className={classes.mImgStyle} /> */}
                                    <ListItemText
                                      sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "14px",
                                        },
                                      }}
                                      primary={
                                        item.name.length < 42
                                          ? item.name
                                          : item.name?.slice(0, 42) + "..."
                                      }
                                    />
                                  </ListItem>
                                </List>
                              ))}
                        </AccordionDetailsDisplay>
                      </AccordionDisplay>
                    ))
                  : null}
              </Box>
            </Box>
          ) : (
            activeTab
          )}
        </Box>
      </Box>
      {forms?.length > 0 ? (
        <Box className={classes.selectedFormsSection}>
          <Typography className={classes.selectedFormsHeading}>
            My Selections ({forms?.length}){" "}
          </Typography>
          <Box className={classes.selectedForms}>
            {forms?.map((item) => (
              <Box className={classes.selectedFormsItem}>
                <IconButton
                  onClick={() => handleCheckboxChange(item)}
                  size="small"
                >
                  {" "}
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
                <DescriptionOutlinedIcon fontSize="small" />
                <RemoveRedEyeIcon fontSize="small" />
                <Typography className={classes.selectedFormsItemTitle}>
                  {folderForms?.data?.find((it) => it.id === item)?.name ||
                    "Unknown name"}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

export default withStyles(styleSheet, { name: "AddFormsStyle" })(AddForms);
